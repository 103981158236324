export const cutString = (s: string, maxLength: number): string => {
  if (s?.length > 0 || s) {
    return s.length <= maxLength ? s : `${s.substring(0, maxLength)} ...`;
  }

  return "";
};

export const stringWithoutLeadingSpace = (s: string): string => {
  return s.replace(/^\s+/, "");
};

export const stringWithoutExtraSpaces = (s: string): string => {
  return s.replace(/\s+/g, " ");
};

export const stringWithoutSpaces = (s: string): string => {
  return s.replace(/\s+/g, "");
};
