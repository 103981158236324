// requestor and general config information
// eslint-disable-next-line no-unused-vars
import blah from "@babel/runtime-corejs2/helpers/esm/defineProperty";

// babel error workaround https://github.com/gatsbyjs/gatsby/issues/7668
import * as actionTypes from "../actions/actionTypes";

const initState = {
  resultFormDefinition: [],
  codebook: {},
};

const setFormValue = (state, action) => {
  if (
    (action.data.name?.includes("r_phone_country") &&
      action.data.value?.length > 4) ||
    (action.data.name?.includes("r_phone_number") &&
      action.data.value[0] === "+")
  ) {
    return state;
  }

  return {
    ...state,
    [action.data.name]: action.data.value,
  };
};

const removeFormValue = (state, action) => {
  const newObj = { ...state };
  delete newObj[action.data.name];
  return newObj;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_FORM_VALUE:
      return setFormValue(state, action);
    case actionTypes.REMOVE_FORM_VALUE:
      return removeFormValue(state, action);
    case actionTypes.FORM_FLIP_FORMBOX_STATE:
      return { ...state, [action.data.name]: !state[action.data.name] };
    case actionTypes.LOGOUT_USER:
    case actionTypes.INIT_FORM_STATE:
      return initState;
    default:
      return state;
  }
};

export default reducer;
