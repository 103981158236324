import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

const getGolRequest = (bookingData) => ({
  GolApi: {
    RequestDetail: {
      BookReservationsRequest_3: {
        ...bookingData,
      },
    },
  },
});

export default async (bookingData, alternativeCurrency) => {
  const golRequest = getGolRequest(bookingData);
  const response = await golApiCall(golRequest, {
    ...(alternativeCurrency ? { alternativeCurrency } : {}),
  });
  return createResponse(response);
};
