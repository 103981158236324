import * as actionTypes from "../actions/actionTypes";
import {
  MessageCenterInitState,
  MessageCenterReturnTypes,
  Messages,
} from "./types";

const initState: MessageCenterInitState = {
  devices: [],
  messages: [],
  messageCount: 0,
  unreadMessagesCount: 0,
  settings: {
    pushNotifications: [],
  },
};

export const setMessages = (
  state: MessageCenterInitState,
  action: { data: { messages: Messages }; type: string }
): MessageCenterReturnTypes => {
  const { messages } = action.data;

  return {
    ...state,
    messages,
  };
};

export const setUnreadMessages = (
  state: MessageCenterInitState,
  action: { data: { messages: Messages }; type: string }
): MessageCenterReturnTypes => {
  const { messages } = action.data;

  const unreadMessagesCount =
    messages?.filter((message) => message.Viewed === false).length ?? 0;

  return {
    ...state,
    unreadMessagesCount,
  };
};

const reducer = (
  state: MessageCenterInitState = initState,
  action
): MessageCenterReturnTypes => {
  switch (action.type) {
    case actionTypes.SET_MESSAGES:
      return setMessages(state, action);
    case actionTypes.SET_UNREAD_MESSAGES:
      return setUnreadMessages(state, action);
    default:
      return state;
  }
};

export default reducer;
