import React from "react";
import { connect } from "react-redux";

import { AppState } from "shared/data/reducers";

import Img from "@components/FileServer/Img";

import { changeLanguage } from "./SetLanguage";

const DEFAULT_LANGUAGE_ICON_SRC = "/static/images/ico-lang-na.svg";

type Props = {
  supportedLanguages: Array<{ Language: string }>;
  currentLanguage: string | null;
};

function SetLanguage({ supportedLanguages }: Props) {
  return supportedLanguages
    .map(({ Language }) => Language)
    .map((language) => (
      <a
        key={language}
        href="#"
        className="header-mobile-menu-link"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          changeLanguage(language);
        }}
      >
        <Img
          className="header-menu-currency-option-ico"
          src={`/static/images/ico-lang-${language.toLowerCase()}.svg`}
          onError={(e) => {
            e.target.src = DEFAULT_LANGUAGE_ICON_SRC;
          }}
        />
        {language.toUpperCase()}
      </a>
    ));
}

export default connect((state: AppState) => ({
  supportedLanguages: state.requestorConfig.supportedLanguages,
  currentLanguage: state.requestorConfig.currentLanguage,
}))(SetLanguage);
