import { useRouter } from "next/router";
import React, { ReactElement, useRef, useState } from "react";

import { ROUTES } from "shared/constants";
import { useCustomerName } from "shared/hooks/userHeader";

import Img from "@components/FileServer/Img";
import FormattedMessage from "@components/UI/FormattedMessage";

import { useOutsideClick } from "@lib/webFunctions";

type UserHeaderProps = {
  logout: () => void;
};

function UserHeader({ logout }: UserHeaderProps): ReactElement {
  const customerName = useCustomerName();
  const [isMenuOpen, toggleMenu] = useState(false);
  const router = useRouter();
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (isMenuOpen) toggleMenu(false);
  });

  return (
    <div
      className="header-button-text"
      onClick={() => toggleMenu(!isMenuOpen)}
      ref={ref}
      role="button"
      id="header-user-button"
    >
      <Img
        src="/static/images/ico-signin.svg"
        className="header-signin-image"
        onClick={() =>
          router.push({
            pathname: ROUTES.PROFILE,
            query: {
              ...(router.query.lang ? { lang: router.query.lang } : {}),
              ...(router.query?.typeSearch
                ? { typeSearch: router.query.typeSearch }
                : {}),
              ...(router.query.currency
                ? { currency: router.query.currency }
                : {}),
            },
          })
        }
        alt="sign in icon"
      />
      <span>{customerName}</span>
      <Img
        className="header-menu-languages-ico"
        src="/static/images/ico-arrow-down.svg"
        style={{
          transition: "all .4s ease",
          transform: isMenuOpen ? "rotate(180deg)" : null,
        }}
        alt="arrow down icon"
      />
      {isMenuOpen && (
        <div className="header-menu-tooltip">
          <div
            role="button"
            onClick={() =>
              router.push({
                pathname: ROUTES.PROFILE,
                query: {
                  ...(router.query.lang ? { lang: router.query.lang } : {}),
                  ...(router.query?.typeSearch
                    ? { typeSearch: router.query.typeSearch }
                    : {}),
                  ...(router.query.currency
                    ? { currency: router.query.currency }
                    : {}),
                },
              })
            }
            id="user-tooltip-profile"
          >
            <FormattedMessage id="Profile.myProfile" />
          </div>
          <div role="button" onClick={logout} id="user-tooltip-logout">
            <FormattedMessage id="Profile.logout" />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserHeader;
