import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import golApiCall from "./golApiCall";

const getGolRequestCancelCustomerMessageQuery = (messageId) => ({
  GolApi: {
    RequestDetail: {
      CancelCustomerMessageRequest_1: {
        CancelCustomerMessage: {
          Id: messageId,
        },
      },
    },
  },
});

export default async (messageId: string): Promise<ResponseObject> => {
  if (!messageId) {
    throw new Error("messageId is required");
  }

  const golRequest = getGolRequestCancelCustomerMessageQuery(messageId);
  const response = await golApiCall(golRequest);
  return createResponse(response);
};
