import classNames from "classnames";
import Tooltip from "rc-tooltip";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { maxTravellersCount } from "shared/cbt/api/travellers";
import { formattedMessageParams } from "shared/messages";

import FormattedMessage from "@components/UI/FormattedMessage";

const { pleaseSelect, pleaseType } = defineMessages({
  pleaseSelect: formattedMessageParams("General.pleaseSelect"),
  pleaseType: formattedMessageParams("General.pleaseType"),
});

export default function TravellersSelect({
  className,
  onChange,
  selectedTravellersIds,
  close,
  tooltip,
}) {
  const intl = useIntl();

  const removeSelectedTravellers = (xs) =>
    xs.filter(({ value }) => !selectedTravellersIds.has(value));
  const usersCache = useSelector((state) => state.cbt.users);
  const usersCacheList = usersCache ? Object.values(usersCache) : [];

  const [value, setValue] = useState("");
  const [selectedOptionIdx, setSelectedOptionIdx] = useState(0);

  const options = Object.values(usersCacheList);

  const filteredOptions = removeSelectedTravellers(options)?.filter((opt) =>
    opt.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );

  const formatedOptions = filteredOptions
    .sort((a, b) => {
      const textA = a.label.toLowerCase();
      const textB = b.label.toLowerCase();

      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    })
    .slice(0, maxTravellersCount);

  return (
    <div className={classNames("travelersSelect", className)}>
      <div className="travelersSelect__input-wrap">
        <input
          autoFocus
          className="travelersSelect__input"
          type="text"
          placeholder={
            formatedOptions.length < maxTravellersCount
              ? intl.formatMessage(pleaseSelect)
              : intl.formatMessage(pleaseType)
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            switch (e.key) {
              case "Enter":
                if (formatedOptions[selectedOptionIdx]) {
                  onChange(formatedOptions[selectedOptionIdx]);
                  setValue("");
                }
                break;
              case "ArrowUp":
                setSelectedOptionIdx(
                  (idx) => (idx - 1) % formatedOptions.length
                );
                break;
              case "ArrowDown":
                setSelectedOptionIdx(
                  (idx) => (idx + 1) % formatedOptions.length
                );
                break;
              case "Escape":
                close();
                break;
              default:
            }
          }}
        />
      </div>
      <ul className="travelersSelect__options">
        {((usersCacheList.length > maxTravellersCount && value.length !== 0) ||
        usersCacheList.length <= maxTravellersCount
          ? formatedOptions
          : []
        ).map((opt, idx) => (
          <li key={opt.value}>
            <Tooltip
              overlay={tooltip(opt)}
              mouseLeaveDelay={0}
              mouseEnterDelay={0.2}
            >
              <button
                type="button"
                className={classNames("travelersSelect__option", {
                  "travelersSelect__option--hover": selectedOptionIdx === idx,
                })}
                data-label={opt.label}
                onClick={() => {
                  setValue("");
                  onChange(opt);
                  if (formatedOptions.length <= 1) {
                    close();
                  }
                }}
                onMouseEnter={() => setSelectedOptionIdx(idx)}
              >
                {opt.label}
              </button>
            </Tooltip>
          </li>
        ))}
      </ul>
      <button className="travelersSelect__stop-btn" onClick={close}>
        <FormattedMessage id="General.stopAddingTraveler" />
      </button>
    </div>
  );
}
