import getCbtClient, { cbtResponse } from "../cbtClient";

export async function resolveHotelsPoliciesGOL({
  searchFlightsExtendedResp,
  userIds,
  guests,
  travelReasonId,
  costCenterId,
  tripType,
  cbtToken,
  departureDate,
  returnDate,
  customerUsername,
  cbtApiUrl,
}) {
  if (!cbtToken) {
    return;
  }

  const payload = {
    UserIds: userIds,
    Guests: guests,
    TravelReasonId: travelReasonId,
    CostCenterId: costCenterId,
    TripType: tripType,
    GolApiResponse: searchFlightsExtendedResp,
    DateFrom: departureDate,
    DateTo: returnDate,
  };

  return cbtResponse(
    getCbtClient({ cbtToken, customerUsername, cbtApiUrl }).post(
      `/policies/resolve-hotels-gol-api`,
      payload
    )
  );
}
