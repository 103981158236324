import * as actionTypes from "../actions/actionTypes";

const initState = {
  isInitSuccessful: true,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ERROR_INIT_CLIENT:
      return { ...state, isInitSuccessful: false };
    default:
      return state;
  }
};

export default reducer;
