const JSLogger = require("js-logger");
const Sentry = require("@sentry/browser");

// eslint-disable-next-line react-hooks/rules-of-hooks
JSLogger.useDefaults();

const debug = (...logs) => {
  JSLogger.debug(logs.join());
};

const log = (...logs) => {
  // alias to debug
  JSLogger.debug(logs.join());
};

const info = (...logs) => {
  JSLogger.info(logs.join());
};

const warn = (...logs) => {
  JSLogger.warn(logs.join());
};

const error = (...logs) => {
  const additionalInfo = {
    url:
      typeof window !== "undefined" && window.location
        ? window.location.href
        : undefined,
    cookies: typeof document !== "undefined" ? document.cookie : undefined,
    onServer: typeof window === "undefined",
  };

  const msg = Array.isArray(logs)
    ? [logs, additionalInfo].map((oneLog) => JSON.stringify(oneLog)).join()
    : JSON.stringify(logs);

  Sentry.withScope((scope) => {
    scope.setExtra("content", msg);
    Sentry.captureException(new Error(`Custom error: ${msg}`));
  });

  JSLogger.error(msg, ...logs, additionalInfo);
};

module.exports = {
  debug,
  log,
  info,
  warn,
  error,
};
