import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import { rootReducer } from "shared/data/reducers";

// eslint-disable-next-line import/prefer-default-export
export function initializeStore(initialState = {}) {
  if (process.browser) {
    // setClientId(initialState.requestorConfig.clientId);
  }

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunkMiddleware)
  );
}
