import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

type PriceReservation = { ReservationId: string; Price: string | number };

function getGolRequestReservationPaymentsQuery(
  PriceReservation: PriceReservation[],
  PaymentformOption,
  TicketplaceOption
) {
  return {
    GolApi: {
      RequestDetail: {
        PaymentPaymentRequest_1: {
          PaidReservations: {
            PriceReservation,
          },
          TicketingCombination: {
            TicketplaceOption,
            PaymentformOption,
          },
        },
      },
    },
  };
}

export default async (
  PriceReservation: PriceReservation[],
  PaymentformOption,
  TicketplaceOption
) => {
  const golRequest = getGolRequestReservationPaymentsQuery(
    PriceReservation,
    PaymentformOption,
    TicketplaceOption
  );

  const response = await golApiCall(golRequest);

  return createResponse(response);
};
