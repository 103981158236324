// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- literally any type
export const isFalsy = (value: any, checkZero = false): boolean => {
  if (
    typeof value === "undefined" ||
    value === null ||
    value === false ||
    (Array.isArray(value) && value.length === 0) ||
    (checkZero && value === 0) ||
    (typeof value === "string" && value.trim() === "")
  ) {
    return true;
  }

  return false;
};
