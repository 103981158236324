import golApiCall from "./golApiCall";

function getGolRequestExportOfCountriesQuery() {
  const golRequest = {
    GolApi: {
      RequestDetail: {
        ExportCountryRequest_1: {},
      },
    },
  };
  return golRequest;
}

export default async () => {
  const golRequest = getGolRequestExportOfCountriesQuery();
  const exportOfCountriesResponse = await golApiCall(golRequest);
  return exportOfCountriesResponse;
};
