import { ROUTES } from "shared/constants/routes";

const redirectAfterLogin = (router) => {
  if (
    router.query.redirectAfterLogin &&
    !router.query.redirectAfterLogin.includes(ROUTES.REGISTER) &&
    !router.query.redirectAfterLogin.includes(ROUTES.LOGIN)
  ) {
    const redirectAfterLoginParam = router.query?.redirectAfterLogin;
    const lang =
      router.query?.lang && !router.query.redirectAfterLogin.includes("lang")
        ? `?lang=${router.query.lang}`
        : "";
    window.location.href = `${redirectAfterLoginParam}${lang}`;
  } else {
    window.location.href = `/${
      router.query.lang ? `?lang=${router.query.lang}` : ""
    }`;
  }
};

export default redirectAfterLogin;
