/* eslint-disable global-require */
// primary file for connection with gol-api, transform all legacy connections here

export default {
  flightSearch: require("./flightSearch").default,
  paymentParameters: require("./paymentParameters").default,
  clientToken: require("./clientToken").default,
  reservationPayments: require("./reservationPayments").default,
  generationOfVariableSymbol: require("./generationOfVariableSymbol").default,
  listReservations: require("./listReservations").default,
  baggageDetails: require("./baggageDetails").default,
  exportOfCountries: require("./exportOfCountries").default,
  bookReservations: require("./bookReservations3").default,
  reservationDetails: require("./reservationDetails").default,
  cancelReservation: require("./cancelReservation").default,
  importReservation: require("./importReservation").default,
  destinationSearch: require("./destinationSearch").default,
  clientDetail: require("./clientDetail").default,
  detailCustomer2: require("./detailCustomer2").default,
  modifyCustomer2: require("./modifyCustomer2").default,
  hotelSearch: require("./hotelSearch").default,
  searchSpecialoffers: require("./searchSpecialoffers").default,
  listSpecialoffers: require("./listSpecialoffers").default,
  checkSpecialoffers: require("./checkSpecialoffers").default,
  listCustomerMessages: require("./listCustomerMessages").default,
  createCustomerDeviceRequest: require("./createCustomerDeviceRequest").default,
  viewCustomerMessage: require("./viewCustomerMessage").default,
  cancelCustomerMessage: require("./cancelCustomerMessage").default,
  reservationDestinationsRulesInfo: require("./reservationDestinationsRulesInfo")
    .default,
  confirmCustomerEmail: require("./confirmCustomerEmail").default,
  cancelCustomerToken: require("./cancelCustomerToken").default,
  ssoLink: require("./ssoLink").default,
};
