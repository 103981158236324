import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";

import { ROUTES } from "shared/constants";
import { AppState } from "shared/data/reducers";
import formatUrl from "shared/lib/formatUrl";

import Img from "@components/FileServer/Img";
import FormattedMessage from "@components/UI/FormattedMessage";
import Link from "@components/UI/Link";

function HeaderLogo({ canLinkToHomepage }) {
  const router = useRouter();
  const { logoUrl, logoHeight, homepageUrls, currentLanguage } = useSelector(
    (state: AppState) => ({
      logoUrl:
        state.storage.frontendSettings?.dealerFrontendSettings?.banner?.image
          ?.url?.default,
      logoHeight:
        state.storage.frontendSettings?.dealerFrontendSettings?.banner?.image
          ?.heightPx,
      homepageUrls:
        state.storage.frontendSettings?.dealerFrontendSettings?.homepageUrls,
      currentLanguage: state.requestorConfig.currentLanguage,
    })
  );
  const languageCode = currentLanguage === "cs" ? "cz" : currentLanguage;
  const homepageUrl =
    (homepageUrls && (homepageUrls[languageCode] || homepageUrls.default)) ||
    "/";

  const isCustomHomePageDefinedFromConfig =
    homepageUrls?.default !== "" && homepageUrls?.default !== undefined;

  const getHrefUrl = () => {
    if (isCustomHomePageDefinedFromConfig) {
      return formatUrl(homepageUrl);
    }

    if (!canLinkToHomepage) {
      return `${ROUTES.LOGIN}?${encodeURIComponent(
        JSON.stringify(router.query)
      )}`;
    }

    return ROUTES.HOME;
  };

  return (
    <div className="header-logo">
      <Link noParams={isCustomHomePageDefinedFromConfig} href={getHrefUrl()}>
        <a style={{ display: "flex", textDecoration: "none" }}>
          <Img
            src={logoUrl ?? "/static/images/logo.png"}
            className="header-logo-image"
            alt="Logo"
            style={logoHeight && { height: `${logoHeight}px` }}
          />
          <div className="header-logo-slogan">
            <FormattedMessage id="HeaderLogoStripe.sloganFirst" />
            <div>
              <FormattedMessage id="HeaderLogoStripe.sloganSecond" />{" "}
              <b>
                <FormattedMessage id="HeaderLogoStripe.sloganThird" />
              </b>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
}

export default HeaderLogo;
