import { getIndexPassengerFromBookingFormSection } from "../hooks/bookingFormSection";

const CODES_TO_HIDE_TITLE = ["passengerServices"];
const CUSTOM_SECTIONS_CODES = [
  "passengerPerson", // flights
  "passengerServices",
  "marketplace_service_insurance",
  "marketplace_service_goparking",
  "traveler_person", // hotels
  "contact",
  "company",
  "billing",
  "other",
];

export function getTitleFromBookingFormGroup({
  bookingFormGroup,
  bookingFormGroupAbove,
}) {
  if (!bookingFormGroupAbove) {
    return "";
  }

  if (CODES_TO_HIDE_TITLE.includes(bookingFormGroup.Code)) {
    return "";
  }

  const passengerAdditionalInfoType = bookingFormGroupAbove.AdditionalInfo?.Info?.filter(
    (additionalInfo) =>
      additionalInfo.Code === "passengerType" ||
      additionalInfo.Code === "traveler_type"
  )?.[0]?.$t;

  if (passengerAdditionalInfoType) {
    return passengerAdditionalInfoType
      ? `CODE-PASSENGER-${passengerAdditionalInfoType}`
      : "EMPTY";
  }

  if (
    bookingFormGroup.Code === "marketplace_service_goparking" ||
    bookingFormGroup.Code === "marketplace_service_insurance"
  ) {
    return bookingFormGroup?.Label?.$t || "";
  }
}

export function getDescriptionFromBookingFormGroup({ bookingFormGroup }) {
  if (!bookingFormGroup) {
    return "";
  }

  return (
    bookingFormGroup?.AdditionalInfo?.Info.filter(
      (info) => info.Code === "text"
    )?.[0]?.$t || ""
  );
}

export function getModalsFromBookingFormGroup({ bookingFormGroup }) {
  if (!bookingFormGroup) {
    return [];
  }

  return (
    bookingFormGroup?.BookingFormGroup?.filter(
      (formGroup) => formGroup.Code === "views"
    )?.[0]?.BookingFormView.filter((formView) => formView.Type === "modal") ||
    null
  );
}

export function isBookingFormGroupSection(bookingFormGroup) {
  if (!CUSTOM_SECTIONS_CODES.includes(bookingFormGroup.Code)) {
    return false;
  }

  if (!bookingFormGroup.BookingFormGroup) {
    return true;
  }
  if (Array.isArray(bookingFormGroup.BookingFormGroup)) {
    return true;
  }

  return false;
}

export function isBookingFormElementRequired({
  bookingFormElement,
  passengerIndex,
  cbt,
}) {
  if (bookingFormElement.Required === "true") {
    return true;
  }

  const isGuest =
    passengerIndex > -1 &&
    cbt &&
    cbt.passengersForm &&
    cbt?.passengersForm?.travellers &&
    cbt.passengersForm.guests > 0 &&
    (Number(passengerIndex) >= cbt.passengersForm.travellers.length ||
      Number(passengerIndex) >= cbt.passengersForm.travellers.length);

  // cbt guest email is always required
  if (
    isGuest &&
    (bookingFormElement.Name === "passenger_email" ||
      bookingFormElement.Name === "traveler_email") &&
    bookingFormElement.Required === "false"
  ) {
    return true;
  }

  return false;
}

export function getDefaultOptionValueFromBookingElement(bookingFormElement) {
  if (!bookingFormElement.DataSource) {
    return;
  }

  return bookingFormElement?.FirstValue?.$t || undefined;
}

export function getCbtGuestsInDynamicFormShowingSearchForm({ sections, cbt }) {
  if (!cbt?.passengersForm.guests) {
    return [];
  }

  return sections
    .map((section) => {
      if (!section?.title?.startsWith("CODE-")) {
        return false;
      }

      const indexPassenger = getIndexPassengerFromBookingFormSection(section);

      if (
        !cbt?.passengersForm?.travellers ||
        indexPassenger < cbt.passengersForm.travellers.length
      ) {
        return false;
      }

      return indexPassenger;
    })
    .filter((sectionIndex) => sectionIndex !== false);
}
