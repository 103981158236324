import { Environment, EnvironmentString } from "./types";

const TESTERS = ["simo.gol.in.cee-systems.com"];

export default (requestorClientId: string) => {
  if (
    requestorClientId.includes("support") ||
    TESTERS.includes(requestorClientId)
  ) {
    return {
      key: Environment.TEST,
      label: EnvironmentString[Environment.TEST],
    };
  }

  if (requestorClientId.includes(".gol.in.")) {
    return {
      key: Environment.DEV,
      label: EnvironmentString[Environment.DEV],
    };
  }

  if (
    requestorClientId.includes("tester") ||
    requestorClientId.includes("gitlab") ||
    requestorClientId.includes("golapi-test")
  ) {
    return {
      key: Environment.PIPELINE,
      label: EnvironmentString[Environment.PIPELINE],
    };
  }

  if (requestorClientId.includes(".golibe.com")) {
    return {
      key: Environment.PROD,
      label: EnvironmentString[Environment.PROD],
    };
  }

  return {
    key: Environment.UNKNOWN,
    label: EnvironmentString[Environment.UNKNOWN],
  };
};
