import React from "react";

import { isTelephone } from "shared/lib/formsFunctions";
import { isDateValid, isValidBirthDay } from "shared/lib/functions";

import FormattedMessage from "@components/UI/FormattedMessage";

export const checkRequired = (value) => {
  let isEmpty = true;

  if (typeof value === "object") {
    isEmpty = false;
  } else if (value && value.trim().length > 0) {
    isEmpty = false;
  }

  return !isEmpty ? undefined : (
    <span>
      <FormattedMessage id="Rules.required" />
    </span>
  );
};

export const requiredSelect = (value) =>
  value === undefined ? (
    <span>
      <FormattedMessage id="Rules.required" />
    </span>
  ) : undefined;

export const isEmail = (value) =>
  // eslint-disable-next-line security/detect-unsafe-regex -- no harm regex
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value.toLowerCase()
  ) ? undefined : (
    <FormattedMessage id="Rules.isEmail" />
  );

export const isNumeric = (value) =>
  /^\d+$/.test(value) ? undefined : <FormattedMessage id="Rules.isNumeric" />;

export const isPhoneNumber = (value) =>
  isTelephone(value) ? undefined : (
    <FormattedMessage id="Rules.isPhoneNumber" />
  );

export const isFullName = (value) =>
  value.split(/[^\s]+/).length - 1 > 1 ? undefined : (
    <FormattedMessage id="Rules.isFullName" />
  );

export const passwordLength = (value) =>
  value && value.length >= 8 ? undefined : (
    <FormattedMessage id="Rules.passwordLength" />
  );

export const isBirthday = (value, values, required = true, name) => {
  let prefix = null;
  if (!isNaN(parseInt(name, 10))) {
    // eslint-disable-next-line prefer-destructuring
    prefix = name[0];
  }
  const keys = Object.keys(values).filter((key) => {
    if (prefix !== null) {
      return key[0] === prefix && key.includes("birthDay");
    }
    return key.includes("birthDay");
  });

  const day = values[keys[0]] && values[keys[0]].value;
  const month = values[keys[1]] && values[keys[1]].value;
  const year = values[keys[2]] && values[keys[2]].value;
  if (
    required &&
    day === undefined &&
    month === undefined &&
    year === undefined
  ) {
    return <FormattedMessage id="Profile.wrongFilledDate" />;
  }

  if (
    !required &&
    ((day === undefined && month === undefined && year === undefined) ||
      day === undefined ||
      month === undefined ||
      year === undefined)
  ) {
    return undefined;
  }
  if (!isDateValid(day, month, year) || !isValidBirthDay(day, month, year)) {
    return <FormattedMessage id="Profile.wrongFilledDate" />;
  }
  return undefined;
};

// eslint-disable-next-line no-shadow
export const isExpirationDate = (value, values, required = true, name) => {
  let prefix = null;
  if (!isNaN(parseInt(name, 10))) {
    // eslint-disable-next-line prefer-destructuring
    prefix = name[0];
  }
  const keys = Object.keys(values).filter((key) => {
    if (prefix !== null) {
      return key[0] === prefix && key.includes("expirationDate");
    }
    return key.includes("expirationDate");
  });

  const day = values[keys[0]] && values[keys[0]].value;
  const month = values[keys[1]] && values[keys[1]].value;
  const year = values[keys[2]] && values[keys[2]].value;
  if (
    required &&
    day === undefined &&
    month === undefined &&
    year === undefined
  ) {
    return <FormattedMessage id="Rules.wrongFilledDate" />;
  }

  if (
    !required &&
    ((day === undefined && month === undefined && year === undefined) ||
      day === undefined ||
      month === undefined ||
      year === undefined)
  ) {
    return undefined;
  }

  if (!isDateValid(day, month, year, true)) {
    return <FormattedMessage id="Profile.wrongFilledDate" />;
  }
  return undefined;
};

export const isBirthdayDynamicForm = (values, required = true) => {
  const splittedVal = values.split("-");

  const day = splittedVal[0];
  const month = splittedVal[1];
  const year = splittedVal[2];
  if (
    required &&
    day === undefined &&
    month === undefined &&
    year === undefined
  ) {
    return <FormattedMessage id="Profile.wrongFilledDate" />;
  }

  if (
    !required &&
    ((day === undefined && month === undefined && year === undefined) ||
      day === undefined ||
      month === undefined ||
      year === undefined)
  ) {
    return undefined;
  }
  if (!isDateValid(day, month, year) || !isValidBirthDay(day, month, year)) {
    return <FormattedMessage id="Profile.wrongFilledDate" />;
  }
  return undefined;
};

export const matchingPassword = (value, values) => {
  if (values.password !== value) {
    return <FormattedMessage id="Rules.matchingPassword" />;
  }
  return undefined;
};
