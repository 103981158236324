export const IS_MOBILE =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

export function getPersistentStorage(preferableTypeWeb = "localStorage"): any {
  if (process.browser) {
    if (preferableTypeWeb === "sessionStorage") {
      return sessionStorage;
    }
    return localStorage;
  }
  return {};
}
