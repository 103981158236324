import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

function getGolRequestDestinationSearchQuery(searchDestinationPattern: string) {
  const golRequest = {
    GolApi: {
      RequestDetail: {
        SearchDestinationsRequest_1: {
          SearchPattern: { $t: searchDestinationPattern },
        },
      },
    },
  };
  return golRequest;
}

function mapSearchResultsForAutosuggest(searchDestinationResponse, codebook) {
  const airportSuggestions =
    searchDestinationResponse.SearchedAirports.SearchedAirport;

  return airportSuggestions.map((oSearchedAirport) => {
    const oAirportCodeBook = codebook.Airports.Airport.find(
      (oAirport) => oAirport.Code === oSearchedAirport.Destination
    );
    return {
      Code: oSearchedAirport.Destination,
      Parent: oSearchedAirport.Parent,
      Country: oAirportCodeBook.Country,
      State: oAirportCodeBook.State,
      $t: oAirportCodeBook.$t,
      Category: oAirportCodeBook.Category,
    };
  });
}

export default async (searchDestinationPattern: string) => {
  const golRequest = getGolRequestDestinationSearchQuery(
    searchDestinationPattern
  );

  const searchDestinationResponse = await golApiCall(golRequest);
  const response = createResponse(searchDestinationResponse);

  if (!response.success) {
    return [];
  }

  return mapSearchResultsForAutosuggest(response.data, response.codebook);
};
