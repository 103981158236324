import classNames from "classnames";
import dynamic from "next/dynamic";
import { connect, useSelector } from "react-redux";

import { AppState } from "@shared/data/reducers";
import { COUNTRY_CODES_CUSTOM } from "@shared/lib/countryCodes";

const ReactPhoneInput = dynamic(import("react-phone-input-2"), {
  ssr: false,
});

export function PhoneInput(props: {
  name: string;
  id: string;
  value: string;
  touched: boolean;
  error: boolean;
}) {
  const defaultCountry = useSelector(
    (state: AppState) => state.storage.country
  );
  if (defaultCountry.length === 0) {
    return null;
  }

  return (
    <ReactPhoneInput
      {...props}
      localization={COUNTRY_CODES_CUSTOM}
      enableLongNumbers
      country={defaultCountry.toLowerCase()}
      preferredCountries={["cz", "sk"]}
      inputProps={{ name: props.name, id: props.id, autoComplete: "nope" }}
      containerClass={classNames("react-tel-input", {
        "react-tel-input--filled": props.value?.length > 11,
        "react-tel-input-error-border": props.touched && props.error,
      })}
      inputClass="phone-input-form"
      enableAreaCodes={false}
      onChange={(changeValue, data, event, formattedValue) =>
        props.onChange(formattedValue)
      }
      specialLabel={null}
    />
  );
}

function RenderInputField({
  defaultCountry,
  defaultMessage,
  id,
  input,
  meta: { touched, error },
  required,
}: {
  input: any;
  required?: boolean;
  defaultMessage?: string;
  id: string;
  meta: { touched?: boolean; error?: any };
  defaultCountry?: string;
}) {
  if (defaultCountry.length === 0) {
    return null;
  }

  return (
    <div
      className="custom-input-container"
      style={{
        marginBottom: touched && error ? "2px" : "17px",
      }}
    >
      <div
        className={classNames("custom-input-wrapper", {
          "input-wrapper-required": required,
          "input-wrapper-error": touched && error,
        })}
      >
        <span className="input-label">{defaultMessage}</span>
        <PhoneInput id={id} touched={touched} error={error} {...input} />
      </div>
      {touched && error ? (
        <span className="custom-input-wrapper-error">{error}</span>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  defaultCountry: state.storage.country,
});

export default connect(mapStateToProps)(RenderInputField);
