// function moved from shared folder because it caused build erros
import { Router, SingletonRouter } from "next/router";
import { useEffect } from "react";

import { scrollTo } from "shared/lib/functions";

export const formatPassengerRecapitulation = (passengerPrices) => {
  const filteredPassengers = [];
  passengerPrices.forEach((passenger) => {
    if (filteredPassengers.length === 0) {
      filteredPassengers.push({
        code: passenger.Code,
        count: 1,
        price: passenger.FullPricePerPassenger,
        operatorFee: passenger.OperatorFeePerPassenger,
      });
    } else {
      const found = filteredPassengers.find((el) => el.code === passenger.Code);
      if (found) {
        found.count++;
      } else {
        filteredPassengers.push({
          code: passenger.Code,
          count: 1,
          price: passenger.FullPricePerPassenger,
          operatorFee: passenger.OperatorFeePerPassenger,
        });
      }
    }
  });
  return filteredPassengers;
};

export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export function checkIsHotelSearchForm({ query }: SingletonRouter) {
  return query?.typeSearch === "hotels";
}

export function checkIsFlightSearchForm({ query }: SingletonRouter) {
  return query?.typeSearch === "flights";
}

export function scrollToErrorDynamicForm() {
  const errorFields = document.querySelector(".booking-form-element-error");

  if (errorFields !== null) {
    return scrollTo(".booking-form-element-error");
  }

  const addNewGuestLinks = document.querySelector(
    ".form-text-input-search-guest-new-label"
  ) as HTMLSpanElement;

  if (addNewGuestLinks) {
    addNewGuestLinks.click();
    return setTimeout(() => {
      scrollTo(".booking-form-element-error");
    }, 500);
  }
}

export const routeChangeFallback = (cb: () => void) => {
  let routeChangeStarted = false;

  const routeChangeStartHandle = () => {
    routeChangeStarted = true;
  };

  Router.events.on("routeChangeStart", routeChangeStartHandle);

  setTimeout(() => {
    if (routeChangeStarted) {
      Router.events.off("routeChangeStart", routeChangeStartHandle);
      return true;
    }
    cb();
    Router.events.off("routeChangeStart", routeChangeStartHandle);
  }, 1000);
};

export function redirect(router, path = "/") {
  const params = router.query.lang ? `?lang=${router.query.lang}` : "";
  window.location.href = path + params;
}

export const errorCodesToRedirect = ["3054", "3092", "3001"];
