import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import golApiCall, { getApiHeader } from "./golApiCall";

const getGolRequestCreateCustomerDeviceQuery = async (
  deviceName: string,
  token: string
) => ({
  GolApi: {
    ...(await getApiHeader()),
    RequestDetail: {
      CreateCustomerDeviceRequest_1: {
        CustomerDevice: {
          DeviceName: {
            $t: deviceName,
          },
          DeviceToken: {
            $t: token,
          },
        },
      },
    },
  },
});

export default async (
  deviceName: string,
  token: string
): Promise<ResponseObject> => {
  if (!deviceName || !token) {
    throw new Error("deviceName and token are required");
  }
  const golRequest = await getGolRequestCreateCustomerDeviceQuery(
    deviceName,
    token
  );
  const response = await golApiCall(golRequest);
  return createResponse(response);
};
