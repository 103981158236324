import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";

export interface UsePageTitleProps {
  defaultTitle?: string;
  hideDefaultTitleIfCustomTitleExists?: boolean;
}

const usePageTitle = ({
  defaultTitle,
  hideDefaultTitleIfCustomTitleExists,
}: UsePageTitleProps): string => {
  const { feTitles, currentLanguage } = useSelector((state: AppState) => ({
    feTitles: state.storage?.frontendSettings?.dealerFrontendSettings?.feTitles,
    currentLanguage: state.requestorConfig.currentLanguage,
  }));

  if (!feTitles) {
    return defaultTitle;
  }

  if (feTitles[currentLanguage]) {
    return getPageTitle({
      titleInCorrectLanguage: feTitles[currentLanguage],
      defaultTitle,
      hideDefaultTitleIfCustomTitleExists,
    });
  }

  // czech lang exception because of gol lang names
  if (currentLanguage === "cs" && feTitles.cz) {
    return getPageTitle({
      titleInCorrectLanguage: feTitles.cz,
      defaultTitle,
      hideDefaultTitleIfCustomTitleExists,
    });
  }

  if (feTitles.default) {
    return getPageTitle({
      titleInCorrectLanguage: feTitles.default,
      defaultTitle,
      hideDefaultTitleIfCustomTitleExists,
    });
  }

  return defaultTitle;
};

function getPageTitle({
  titleInCorrectLanguage,
  defaultTitle,
  hideDefaultTitleIfCustomTitleExists,
}) {
  if (hideDefaultTitleIfCustomTitleExists) {
    return titleInCorrectLanguage;
  }

  return (defaultTitle ? `${defaultTitle} - ` : "") + titleInCorrectLanguage;
}

export default usePageTitle;
