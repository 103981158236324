import { defineMessages } from "react-intl";

import { formattedMessageParams } from "../../messages";

const {
  PropertiesSelectErrorNoPassenger,
  PropertiesSelectErrorOnlyChild,
  PropertiesSelectTooManyPassengerTypes,
  PropertiesSelectMoreInfantsThanAdults,
} = defineMessages({
  PropertiesSelectErrorNoPassenger: formattedMessageParams(
    "PropertiesSelect.ErrorNoPassenger"
  ),
  PropertiesSelectErrorOnlyChild: formattedMessageParams("Alert.onlyChild"),
  PropertiesSelectTooManyPassengerTypes: formattedMessageParams(
    "Alert.tooManyPassengerTypes"
  ),
  PropertiesSelectMoreInfantsThanAdults: formattedMessageParams(
    "Alert.moreInfantsThanAdults"
  ),
});

const errorTranslation = (intl) => [
  intl.formatMessage(PropertiesSelectErrorNoPassenger),
  intl.formatMessage(PropertiesSelectErrorOnlyChild),
  intl.formatMessage(PropertiesSelectTooManyPassengerTypes),
  intl.formatMessage(PropertiesSelectMoreInfantsThanAdults),
];

export default errorTranslation;
