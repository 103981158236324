import * as actionTypes from "../actions/actionTypes";

export interface User {
  token?: string;
  firstname: string;
  surname: string;
  telephone: string;
  prefix: string;
  email: string;
  userReservations: any[];
  cbtToken?: string;
}

const initState = {
  isLoggedIn: false,
  token: null,
  firstname: "",
  surname: "",
  telephone: "",
  prefix: "mr",
  email: "",
  userReservations: [],
  cbtToken: null,
};

function loadUserReservationsSuccess(state, action) {
  const userReservations =
    action.data.ResponseDetail.ListReservationsResponse_1.ListReservations
      .ReservationItem;

  if (userReservations.length === 0) {
    return state;
  }

  return { ...state, userReservations };
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_VALUE:
      return { ...state, [action.data.name]: action.data.value };
    case actionTypes.SET_USER_VALUES:
      return { ...state, ...action.data.values };
    case actionTypes.LOGOUT_USER: {
      return initState;
    }
    case actionTypes.LOAD_USER_RESERVATIONS_SUCCESS:
      return loadUserReservationsSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
