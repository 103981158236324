import golApi from "../../gol-api/index";
import { parseTransportCompanies } from "../../lib/functions";
import Logger from "../../services/Logger";
import * as actionTypes from "./actionTypes";
import { setTransportCompanies } from "./search";

export const setUserValue = (name, value) => {
  return {
    type: actionTypes.SET_USER_VALUE,
    data: { name, value },
  };
};

export const setUserValues = (values) => {
  return {
    type: actionTypes.SET_USER_VALUES,
    data: { values },
  };
};

export const logoutUser = () => {
  return {
    type: actionTypes.LOGOUT_USER,
  };
};

export const loadUserReservations = () => {
  return async (dispatch) => {
    const userReservations = await golApi.listReservations();
    dispatch(loadUserReservationsSuccess(userReservations));
    try {
      if (
        userReservations.ResponseDetail.ListReservationsResponse_1
          .ListReservations.ListReservations.ReservationItem.length > 0
      ) {
        dispatch(
          setTransportCompanies(
            parseTransportCompanies(userReservations.CodeBook)
          )
        );
      }
    } catch (e) {
      Logger.error(e);
    }
  };
};

export const loadUserReservationsSuccess = (userReservations) => {
  return {
    type: actionTypes.LOAD_USER_RESERVATIONS_SUCCESS,
    data: userReservations,
  };
};
