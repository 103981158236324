import classnames from "classnames";
import { SingletonRouter, withRouter } from "next/router";
import React, { Component } from "react";
import { IntlShape, defineMessages } from "react-intl";

import { countPassengers } from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import {
  isPassengersSelectError,
  setPassengersSelectErrorMessage,
} from "shared/lib/passengersSelect/isPassengersSelectError";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import PassengersSelect from "@components/SearchForm/PassengersSelect";

import {
  checkIsFlightSearchForm,
  checkIsHotelSearchForm,
} from "@lib/webFunctions";

const HEIGHT_FROM_TOP = 350;
const HEIGHT_FROM_BOTTOM = 340;
const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

const {
  PropertiesSelectOnePassenger,
  PropertiesSelectTwoThreePassengers,
  PropertiesSelectFourPlusPassengers,
  PropertiesSelectClassEconomy,
  PropertiesSelectClassPremiumEconomy,
  PropertiesSelectClassBusiness,
  PropertiesSelectClassFirstClass,
} = defineMessages({
  PropertiesSelectOnePassenger: formattedMessageParams(
    "PropertiesSelect.OnePassenger"
  ),
  PropertiesSelectTwoThreePassengers: formattedMessageParams(
    "PropertiesSelect.TwoThreePassengers"
  ),
  PropertiesSelectFourPlusPassengers: formattedMessageParams(
    "PropertiesSelect.FourPlusPassengers"
  ),
  PropertiesSelectClassEconomy: formattedMessageParams(
    "SearchForm.classEconomy"
  ),
  PropertiesSelectClassPremiumEconomy: formattedMessageParams(
    "SearchForm.classPremiumEconomy"
  ),
  PropertiesSelectClassBusiness: formattedMessageParams(
    "SearchForm.classBusiness"
  ),
  PropertiesSelectClassFirstClass: formattedMessageParams(
    "SearchForm.classFirstClass"
  ),
});

export function getFlightClasses(intl: IntlShape) {
  return [
    {
      value: "ECO",
      label: intl.formatMessage(PropertiesSelectClassEconomy).toUpperCase(),
    },
    {
      value: "PRE",
      label: intl
        .formatMessage(PropertiesSelectClassPremiumEconomy)
        .toUpperCase(),
    },
    {
      value: "BUS",
      label: intl.formatMessage(PropertiesSelectClassBusiness).toUpperCase(),
    },
    {
      value: "1ST",
      label: intl.formatMessage(PropertiesSelectClassFirstClass).toUpperCase(),
    },
  ];
}

interface PropertiesSelectProps {
  intl: IntlShape;
  passengers: PassengersCount;
  rooms?: number;
  flightClass?: string;
  onChangeFlightClass?: (flightClass: string) => void;
  defaultFlightClassName?: string;
  onAddCounterHandler: (type: string) => void;
  onRemoveCounterHandler: (type: string) => void;
  router: SingletonRouter;
  isBestOfferSelect?: boolean;
  selectComponentPrefix: string;
  maxNumPassengers: number;
  resetPassengers?: () => void;
}

interface PropertiesSelectState {
  showProperties: boolean;
  error: boolean;
  errorMessage: number;
  isOnTop: boolean;
  resetPassengers?: () => void;
}

class PropertiesSelect extends Component<
  PropertiesSelectProps,
  PropertiesSelectState
> {
  state = {
    showProperties: EXPORT_HTML_PACKAGE ?? false,
    error: false,
    errorMessage: null,
    isOnTop: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const currentIsHotelSearchForm =
      !this.props.isBestOfferSelect &&
      checkIsHotelSearchForm(this.props.router);
    const prevIsHotelSearchForm =
      !prevProps.isBestOfferSelect && checkIsHotelSearchForm(prevProps.router);

    const currentIsFlightSearchForm = checkIsFlightSearchForm(
      this.props.router
    );
    const prevIsFlightSearchForm = checkIsFlightSearchForm(prevProps.router);

    if (
      (currentIsHotelSearchForm && prevIsFlightSearchForm) ||
      (currentIsFlightSearchForm && prevIsHotelSearchForm)
    ) {
      this.props.resetPassengers();
    } else if (currentIsHotelSearchForm && !prevIsHotelSearchForm) {
      this.props.resetPassengers();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setErrorMessage = (messageNumber: number) => {
    this.setState({
      error: true,
      errorMessage: messageNumber,
    });
  };

  onAddCounterHandler = (type: string) => {
    if (!isPassengersSelectError(this.props.passengers)) {
      this.clearErrorMessage();
    }
    this.props.onAddCounterHandler(type);
  };

  onRemoveCounterHandler = (type: string) => {
    if (!isPassengersSelectError(this.props.passengers)) {
      this.clearErrorMessage();
    }
    this.props.onRemoveCounterHandler(type);
  };

  clearErrorMessage = () => {
    this.setState({
      error: false,
      errorMessage: null,
    });
  };

  handleClickOutside = (event) => {
    if (!isPassengersSelectError(this.props.passengers)) {
      this.clearErrorMessage();
    }

    if (event.target.className === "loader-wrapper-mini") {
      if (
        setPassengersSelectErrorMessage(
          this.props.passengers,
          this.setErrorMessage
        )
      ) {
        return;
      }
      this.setState({ showProperties: false });
    }
  };

  toggleShowProperties = () => {
    if (
      setPassengersSelectErrorMessage(
        this.props.passengers,
        this.setErrorMessage
      ) &&
      this.state.showProperties
    ) {
      return;
    }
    this.isVisible();
    this.setState({
      showProperties: !this.state.showProperties,
      error: false,
      errorMessage: null,
    });
  };

  isVisible = () => {
    const el = document.querySelector(
      `#${this.props.selectComponentPrefix}-div_toggle_properties`
    );

    // Get it's position in the viewport
    const bounding = el.getBoundingClientRect();
    if (
      bounding.top < HEIGHT_FROM_TOP ||
      bounding.bottom + HEIGHT_FROM_BOTTOM <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      this.setState({ isOnTop: false });
    } else {
      this.setState({ isOnTop: true });
    }
  };

  render() {
    const numPassengers = countPassengers(this.props.passengers);
    const { intl, router, selectComponentPrefix } = this.props;

    let wordPassengers = intl.formatMessage(PropertiesSelectTwoThreePassengers);
    if (numPassengers === 0 || numPassengers > 4) {
      wordPassengers = intl.formatMessage(PropertiesSelectFourPlusPassengers);
    } else if (numPassengers === 1) {
      wordPassengers = intl.formatMessage(PropertiesSelectOnePassenger);
    }

    const flightClasses = getFlightClasses(intl);
    const valueFlightClass = flightClasses.find(
      (oFlightClass) => oFlightClass.value === this.props.flightClass
    );

    const flightClassTranslation = {
      ECO: {
        label: intl.formatMessage(PropertiesSelectClassEconomy).toUpperCase(),
      },
      PRE: {
        label: intl
          .formatMessage(PropertiesSelectClassPremiumEconomy)
          .toUpperCase(),
      },
      BUS: {
        label: intl.formatMessage(PropertiesSelectClassBusiness).toUpperCase(),
      },
      "1ST": {
        label: intl
          .formatMessage(PropertiesSelectClassFirstClass)
          .toUpperCase(),
      },
    };

    const isHotelSearchForm =
      !this.props.isBestOfferSelect && checkIsHotelSearchForm(router);

    return (
      <>
        {this.state.showProperties && <div className="loader-wrapper-mini" />}
        <div>
          <div
            className="form-properties-wrapper"
            onClick={this.toggleShowProperties}
            role="button"
            id={`${selectComponentPrefix}-div_toggle_properties`}
          >
            <span
              className={`${selectComponentPrefix}-inner-field-value nowrap`}
              id={`${selectComponentPrefix}-properties-value`}
            >
              {`${numPassengers} ${wordPassengers}`}
            </span>

            {!isHotelSearchForm && (
              <span
                className={`${selectComponentPrefix}-inner-field-additional nowrap`}
              >
                {this.props.flightClass
                  ? flightClassTranslation[this.props.flightClass]?.label
                  : this.props.defaultFlightClassName.toUpperCase()}
              </span>
            )}

            <Img
              src="/static/images/ico-arrow-down.svg"
              id={`${selectComponentPrefix}-properties-arrow`}
              style={{
                padding: "2px",
                marginLeft: "5px",
                transition: "all .4s ease",
                transform: this.state.showProperties ? "rotate(180deg)" : null,
              }}
            />
          </div>
          {this.state.showProperties && (
            <div
              id={`${selectComponentPrefix}-properties`}
              className={classnames(
                `${selectComponentPrefix}-properties iframe`,
                {
                  [`${selectComponentPrefix}-properties-top`]: this.state
                    .isOnTop,
                }
              )}
            >
              <PassengersSelect
                isHotelSearchForm={isHotelSearchForm}
                onAddCounterHandler={this.onAddCounterHandler}
                onChangeFlightClass={this.props.onChangeFlightClass}
                onRemoveCounterHandler={this.onRemoveCounterHandler}
                passengers={this.props.passengers}
                flightClasses={flightClasses}
                valueFlightClass={valueFlightClass}
                displayShowToggleProperties
                toggleShowProperties={this.toggleShowProperties}
                error={this.state.error}
                errorMessage={this.state.errorMessage}
                selectComponentPrefix={this.props.selectComponentPrefix}
                allowClassSelection={!this.props.isBestOfferSelect}
                defaultFlightClassName={this.props.defaultFlightClassName}
                numPassengers={numPassengers}
                maxNumPassengers={this.props.maxNumPassengers}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(PropertiesSelect);
