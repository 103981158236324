import {
  getMessages as getPersistentStorageMessages,
  setMessages as setPersistentStorageMessages,
} from "../data/persistentStorage.native";
import { MessageFromResponse, MessagesResponse } from "../data/reducers/types";
import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import { isMobile } from "../lib/isMobile";
import golApiCall from "./golApiCall";

type Props = {
  isOnline?: boolean;
};

const getGolRequestListCustomerMessagesQuery = () => ({
  GolApi: {
    RequestDetail: {
      ListCustomerMessagesRequest_1: {},
    },
  },
});

export default async ({ isOnline }: Props): Promise<ResponseObject> => {
  if (!isOnline && isMobile()) {
    const persistentStorageMessages = await getPersistentStorageMessages();

    return {
      success: true,
      data: persistentStorageMessages,
    };
  }

  const golRequest = getGolRequestListCustomerMessagesQuery();

  const response = await golApiCall(golRequest);

  const listCustomerMessages =
    response?.ResponseDetail?.ListCustomerMessagesResponse_1?.CustomerMessages
      ?.CustomerMessage;

  const formattedResponse = listCustomerMessages
    ? getFormatedResponse(listCustomerMessages)
    : [];

  if (isMobile()) {
    await setPersistentStorageMessages(formattedResponse);
  }

  return {
    ...createResponse(response, formattedResponse),
    ...(formattedResponse && { data: formattedResponse }),
  };
};

const getFormatedResponse = (response: MessagesResponse) => {
  const formatedMessages = response?.map((message: MessageFromResponse) => {
    return {
      Viewed: message?.Viewed === "true",
      Deleted: message?.Deleted === "true",
      Subject: message?.Subject,
      Message: message?.Message,
      ReservationId: message?.ReservationId,
      Created: message?.Created,
      Id: message?.Id,
    };
  });

  return formatedMessages;
};
