import destinationSearch from "../../gol-api/destinationSearch";
import { AirportDestinations } from "../reducers/types/storage";
import * as actionTypes from "./actionTypes";

export const addAirportDestinations = (data) => {
  return {
    type: actionTypes.ADD_AIRPORT_DESTINATIONS,
    data,
  };
};

export const getDestinationByCode = (
  code: string,
  cachedDestinations: AirportDestinations
) => {
  return async (dispatch) => {
    if (!code) {
      return;
    }
    const cachedDestination = cachedDestinations.find(
      ({ Code }) => Code === code
    );
    if (cachedDestination) {
      return cachedDestination;
    }

    const loadedDestinations = await destinationSearch(code);
    dispatch(addAirportDestinations(loadedDestinations));
    return loadedDestinations.find(({ Code }) => Code === code);
  };
};
