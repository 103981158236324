import React from "react";
import { connect } from "react-redux";

import { AppState } from "shared/data/reducers";

import pageWithIntl from "@components/PageWithIntl";
import SearchForm from "@components/SearchForm/SearchForm";

import HeaderLogoStripe from "./HeaderLogoStripe";

type Props = {
  fileServerData: any;
  hideSearchForm?: boolean;
  showCondensedHeaderLogoStripe?: boolean;
};

function Header({ hideSearchForm, showCondensedHeaderLogoStripe }: Props) {
  return (
    <div className="header">
      <HeaderLogoStripe
        showCondensedHeaderLogoStripe={showCondensedHeaderLogoStripe}
      />
      {hideSearchForm !== true && (
        <div className="container">
          <SearchForm />
        </div>
      )}
    </div>
  );
}

export default connect((state: AppState) => ({
  fileServerData: state.requestorConfig.fileServerData,
}))(pageWithIntl(Header));
