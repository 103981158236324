// requestor and general config information
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import blah from "@babel/runtime-corejs2/helpers/esm/defineProperty";

// babel error workaround https://github.com/gatsbyjs/gatsby/issues/7668
import * as actionTypes from "../actions/actionTypes";

const initState = {
  defaultValue: "",
  "Result.contactTelephone": { value: "+420" },
  "Result.contactEmail": { value: "" },
  "Register.phone": { value: "+420" },
  "Register.email": { value: "" },
  "EditProfile.contactPhone": { value: "+420" },
};

const setMobileFormsValuesFromUserDetail = (state, action) => {
  if (!action.data) {
    return state;
  }

  const newa = {
    ...state,
    ...{
      // Result
      "Form.contactPerson": state["Form.contactPerson"] || {
        value: `${action.data.firstname} ${action.data.surname}`,
      },
      "Result.contactEmail":
        state["Result.contactEmail"].value !== ""
          ? state["Result.contactEmail"]
          : {
              value: action.data.email,
            },
      "Result.contactTelephone":
        state["Result.contactTelephone"] &&
        state["Result.contactTelephone"].value !== "+420"
          ? state["Result.contactTelephone"]
          : {
              value: action.data.telephone,
            },
      "Passenger[0].firstName": state["Passenger[0].firstName"] || {
        value: action.data.firstname,
      },
      "Passenger[0].surname": state["Passenger[0].surname"] || {
        value: action.data.surname,
      },
      "Passenger[0].prefix": state["Passenger[0].prefix"] || {
        value: action.data.namePrefix,
      },

      // Edit Profile
      "EditProfile.prefix": state["EditProfile.prefix"] || {
        value: action.data.namePrefix,
      },
      "EditProfile.firstName": state["EditProfile.firstName"]?.value
        ? state["EditProfile.firstName"]
        : {
            value: action.data.firstname,
          },
      "EditProfile.surname": state["EditProfile.surname"] || {
        value: action.data.surname || action.data.givenName,
      },
      "EditProfile.phone": state["EditProfile.phone"] || {
        value: action.data.telephone,
      },
      "EditProfile.email": state["EditProfile.email"] || {
        value: action.data.email,
      },
      "EditProfile.birthDate": state["EditProfile.birthDate"] || {
        value: action.data.birthDate,
      },
      "EditProfile.passportNumber": state["EditProfile.passportNumber"] || {
        value: action.data.passport?.[0]?.passportNumber
          ? action.data.passport[0].Number
          : "",
      },
      "EditProfile.validityDate": state["EditProfile.validityDate"] || {
        value: action.data.passport?.[0]?.ValidityDate || "",
      },
      "EditProfile.citizenship": state["EditProfile.citizenship"] || {
        value: action.data.passport?.[0]?.Citizenship || "",
      },
      "EditProfile.issued": state["EditProfile.issued"] || {
        value: action.data.passport?.[0]?.Issued || "",
      },
    },
  };

  return newa;
};

const removeMobileFormValue = (state, action) => {
  const newObj = { ...state };
  delete newObj[action.data.name];
  return newObj;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_MOBILE_FORMS_VALUE:
      return { ...state, [action.data.name]: action.data.value };
    case actionTypes.REMOVE_MOBILE_FORMS_VALUE:
      return removeMobileFormValue(state, action);
    case actionTypes.SET_MOBILE_FORMS_VALUES_FROM_USER_DETAIL:
      return setMobileFormsValuesFromUserDetail(state, action);
    case actionTypes.LOGOUT_USER:
      return initState;
    default:
      return state;
  }
};

export default reducer;
