import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";

import { AppState } from "shared/data/reducers";
import { dayMonthYearFormat } from "shared/lib/datetime";

import Calendar from "@components/SearchForm/Calendar/Calendar";
import Modal from "@components/UI/Modal";

interface Props {
  date: string;
  onChange: (value: string) => void;
  typeSearch: string;
  disabledTillDate: string;
  disabledAfterDate?: string;
  currentLanguage: string;
  defaultCountry: string;
  testId: string;
}

const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

function DatePicker(props: Props) {
  const [showCalendar, setShowCalendar] = useState(false);

  const {
    date,
    onChange,
    typeSearch,
    disabledTillDate,
    testId,
    disabledAfterDate,
  } = props;

  const dateNoon = moment(new Date(`${date} 12:00`));

  return (
    <div className="date-picker-wrapper" id={testId}>
      <span role="button" onClick={() => setShowCalendar(!showCalendar)}>
        <span className="header-search-form-inner-field-value">
          {dateNoon.locale(props.currentLanguage).format("dd").toUpperCase()}
        </span>{" "}
        <span>|</span>{" "}
        <span className="header-search-form-inner-field-additional">
          {moment(date).format(dayMonthYearFormat(props.defaultCountry))}
        </span>
      </span>
      <Modal isActive={showCalendar} onClose={() => setShowCalendar(false)}>
        <Calendar
          close={() => setShowCalendar(false)}
          date={date}
          onChange={onChange}
          typeSearch={typeSearch}
          disabledTillDate={disabledTillDate}
          disabledAfterDate={disabledAfterDate}
        />
      </Modal>
      {EXPORT_HTML_PACKAGE && (
        <Calendar
          close={() => setShowCalendar(false)}
          date={date}
          onChange={onChange}
          typeSearch={typeSearch}
          disabledTillDate={disabledTillDate}
        />
      )}
    </div>
  );
}

export default connect((state: AppState) => ({
  currentLanguage: state.requestorConfig.currentLanguage,
  defaultCountry:
    state.storage.frontendSettings?.dealerFrontendSettings?.defaultCountry ??
    "",
}))(DatePicker);
