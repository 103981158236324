import { FC } from "react";
import { connect } from "react-redux";

import { AppState } from "shared/data/reducers";

import pageWithIntl from "@components/PageWithIntl";

interface Props {
  route?: string;
  footerInnerHTML: string;
  nonce?: string;
}

const Footer: FC<Props> = ({ route, footerInnerHTML }) => {
  if (typeof route === "string" && route.includes("/iframe")) {
    return null;
  }
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-container">
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: footerInnerHTML,
        }}
        suppressHydrationWarning
      />
      <span className="footer-rights">
        © 2001-{currentYear} Developed by CEE Travel Systems
      </span>
    </div>
  );
};

export default connect((state: AppState) => ({
  fileServerData: state.requestorConfig.fileServerData,
  textStorage: state.requestorConfig.textStorage,
  footerInnerHTML: state.requestorConfig.footerInnerHTML,
}))(pageWithIntl(Footer));
