import { getPersistedCbtToken } from "../data/persistentStorage";

export async function getCbtToken(user, frontendSettings, customerUsername) {
  if (customerUsername === null) {
    return null;
  }

  return (
    frontendSettings?.dealerCorporateSettings?.enableCbt === "true" &&
    (user.cbtToken !== null ? user.cbtToken : getPersistedCbtToken())
  );
}
