import { createResponse } from "shared/lib/functions";

import { getPersistedCbtToken, setCookie } from "../data/persistentStorage";
import golApiCall from "./golApiCall";

function getDetailCustomerRequest() {
  return {
    GolApi: {
      RequestDetail: {
        DetailCustomerRequest_1: {},
      },
    },
  };
}

export default async () => {
  const golRequest = getDetailCustomerRequest();
  const clientDetailResponse = await golApiCall(golRequest);
  const response = createResponse(clientDetailResponse);
  if (!response.success) {
    return response;
  }

  const {
    data: {
      RequestorPrivateSettings,
      CustomerInfo,
      CustomerSettings,
      CustomerSettings: { Passport, Loyalties },
      CustomerPassengers,
    },
  } = response;

  const cbtToken = RequestorPrivateSettings?.TokenCBT?.$t;

  if (cbtToken && !getPersistedCbtToken()) setCookie("cbtToken", cbtToken);

  const normalizePerson = (person) => ({
    email: person.Email.$t,
    firstname: person.GivenName.$t, // for backward compatibility
    givenName: person.GivenName.$t,
    surname: person.Surname.$t,
    telephone: person.Telephone.PhoneNumber,
    namePrefix: person.NamePrefix,
    birthday: person.BirthDate, // for backward compatibility
    birthDate: person.BirthDate,
  });

  return {
    ...response,
    data: {
      username: CustomerInfo.Username,
      ...normalizePerson(CustomerInfo.Person),
      passport: {
        passportNumber: Passport.Number,
        ValidityDate: Passport.ValidityDate,
        Citizenship: Passport.Citizenship,
        Issued: Passport.Issued,
      },
      loyaltyProgram: [...Loyalties.LoyaltyProgram],
      passengers: CustomerPassengers?.CustomerPassenger?.map((passenger) => ({
        id: passenger.PassengerId,
        ...normalizePerson(passenger.Person),
      })),
      newsletter: CustomerSettings.Newsletter.Active === "true",
      specialOffers: CustomerSettings.SpecialOffers.Active === "true",
      cbtToken,
    },
  };
};
