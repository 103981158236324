import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import golApiCall from "./golApiCall";

const getGolRequestViewCustomerMessageQuery = (messageId) => ({
  GolApi: {
    RequestDetail: {
      ViewCustomerMessageRequest_1: {
        ViewCustomerMessage: {
          Id: messageId,
        },
      },
    },
  },
});

export default async (messageId: string): Promise<ResponseObject> => {
  if (!messageId) {
    throw new Error("messageId is required");
  }

  const golRequest = getGolRequestViewCustomerMessageQuery(messageId);
  const response = await golApiCall(golRequest);
  return createResponse(response);
};
