import getCbtClient, { cbtResponse } from "../cbtClient";

const listGuests = ({
  cbtToken,
  customerUsername,
  cbtApiUrl,
  query = "",
  limit = 25,
}) => {
  if (!cbtToken) return;
  let params = query ? `query=${encodeURIComponent(query)}&` : "";
  params += limit ? `limit=${limit}` : "";

  return cbtResponse(
    getCbtClient({ cbtToken, customerUsername, cbtApiUrl }).get(
      `/guests?${params}`
    )
  );
};

const PASSENGERS_DATA_TO_USE_FOR_CBT = [
  "title",
  "email",
  "firstname",
  "grade",
  "lastname",
  "phone_country",
  "phone_number",
  "birth_date",
  "passport_number",
  "passport_validity",
  "citizenship",
  "passport_country_of_issue",
  "frequent_flyer",
  "frequent_flyer_number",
  "DateOfBirth",
];

const prepareGuestData = ({ forms, cbtGuestsIndexes }) => {
  const ret = cbtGuestsIndexes.map((indexGuest) => {
    const guestData = {};

    PASSENGERS_DATA_TO_USE_FOR_CBT.forEach((passengerProperty) => {
      const passengerValue =
        forms[`${indexGuest}-passenger_${passengerProperty}`] ||
        forms[`${indexGuest}-traveler_${passengerProperty}`] ||
        forms[`${indexGuest}-${passengerProperty}`];

      if (passengerValue?.value) {
        guestData[passengerProperty] = passengerValue.value;

        if (guestData[passengerProperty].value) {
          if (passengerProperty === "frequent_flyer") {
            guestData.frequent_flyer_company_name =
              guestData[passengerProperty]?.label;
          }

          // if object and not string is present, use value from inside of this object (occurs in citizenship and mostly selectboxes in dynamic form)
          guestData[passengerProperty] = guestData[passengerProperty].value;
        }
      }
    });

    return guestData;
  });

  return ret;
};

const saveCbtGuestsForFuture = async ({
  guestsData,
  customerUsername,
  cbtApiUrl,
  cbtToken,
}) => {
  const cbtClient = getCbtClient({ cbtToken, customerUsername, cbtApiUrl });

  return Promise.all(
    guestsData.map((guest) => {
      const passports = !(
        guest.passport_number &&
        guest.passport_validity &&
        guest.citizenship &&
        guest.passport_country_of_issue
      )
        ? []
        : [
            {
              Number: guest.passport_number,
              Validity: guest.passport_validity,
              Citizenship: guest.citizenship,
              CountryOfIssue: guest.passport_country_of_issue,
            },
          ];

      const guestData = {
        FirstName: guest.firstname || "",
        LastName: guest.lastname || "",
        NamePrefix: guest.title?.toLowerCase() || "",
        BirthDate: guest.birth_date || guest.DateOfBirth || undefined,
        PhoneNumber: `${
          guest.phone_country ? guest.phone_country.split(" ").join("") : ""
        }`,
        ...(guest?.grade && guest?.grade !== "" && { GradeId: guest.grade }),
        Passports: passports,
        Email: guest.email || "",
      };

      if (guestData.LastName === "" && guestData.FirstName === "") {
        return;
      }

      return cbtClient.post(`/guest`, guestData);
    })
  );
};

const updateCbtGuestsForFuture = async ({
  guestsData,
  customerUsername,
  cbtApiUrl,
  cbtToken,
  guestsUsedInDynamicForm,
  cbtGuestsIndexes,
  guestsCache,
}) => {
  const cbtClient = getCbtClient({ cbtToken, customerUsername, cbtApiUrl });

  return Promise.all(
    guestsData.map((guest, indexGuestToUpdate) => {
      const guestId =
        guestsUsedInDynamicForm[cbtGuestsIndexes[indexGuestToUpdate]];

      if (!guestId) {
        return;
      }

      const guestInCache = guestsCache.find(
        (guestInCacheOne) => guestInCacheOne.Id === guestId
      );

      const passports = !(
        guest.passport_number &&
        guest.passport_validity &&
        guest.citizenship &&
        guest.passport_country_of_issue
      )
        ? guestInCache.Passports
        : [
            {
              Number: guest.passport_number,
              Validity: guest.passport_validity,
              Citizenship: guest.citizenship,
              CountryOfIssue: guest.passport_country_of_issue,
            },
          ];

      const guestData = {
        BirthDate:
          guest.birth_date || guest.DateOfBirth || guestInCache.BirthDate,
        PhoneNumber: `${
          guest.phone_country ? guest.phone_country.split(" ").join("") : ""
        }`,
        ...(guest?.grade && guest?.grade !== "" && { GradeId: guest.grade }),
        Passports: passports,
        Email: guest.email || "",
      };

      if (guestData.Email === "") {
        return;
      }

      return cbtClient.put(`/guest/${guestId}`, guestData);
    })
  );
};

export {
  listGuests,
  saveCbtGuestsForFuture,
  prepareGuestData,
  updateCbtGuestsForFuture,
};
