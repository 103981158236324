import * as actionTypes from "../actions/actionTypes";

const initState = {
  oHotelOffers: {},
  aHotelOffers: [],
  hotelRooms: {},
  imageGalleryHotelIndicator: null,
  imageGalleryIndex: 0,
  travelPolicies: [],
  travelPoliciesResults: [],
  travelPoliciesRooms: [],
  travelPoliciesRoomsResults: [],
  searchHotelRoomsQuery: "", // for revalidating purposes
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_HOTELS_VALUE:
      return { ...state, [action.data.name]: action.data.value };

    case actionTypes.SET_HOTEL_ROOMS_VALUE:
      return {
        ...state,
        hotelRooms: {
          ...state.hotelRooms,
          [action.data.name]: action.data.value,
        },
      };

    default:
      return state;
  }
};

export default reducer;
