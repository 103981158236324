import * as actionTypes from "../actions/actionTypes";

const initState = {
  reservation: {},
  loading: false,
  error: {},
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.BOOKING_INIT:
      return initState;
    case actionTypes.BOOKING_SUCCESS:
      return bookingSuccess(state, action);
    case actionTypes.BOOKING_FAILED:
      return bookingFailed(state, action);
    default:
      return state;
  }
};

const bookingSuccess = (state, action) => {
  return {
    ...state,
    reservation: action.data,
    loading: false,
  };
};

const bookingFailed = (state, action) => {
  return {
    ...state,
    error: action.data,
    loading: false,
  };
};

export default reducer;
