import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";

import Img from "@components/FileServer/Img";
import HeaderMobileMenuButton from "@components/UI/Header/HeaderMobileMenuButton";
import Link from "@components/UI/Link";

function UserHeaderMobile({ toggleMobileMenu, showMobileMenu }) {
  const router = useRouter();
  const { isLoggedIn } = useSelector((state: AppState) => state.user);
  return (
    <div className="header-mobile-buttons">
      {router.route !== "/login" ? (
        <Link href={isLoggedIn ? "/profile" : "/login"}>
          <a className="header-signin-mobile-button">
            <Img
              src="/static/images/ico-signin.svg"
              className="header-signin-image"
              alt="sign in icon"
            />
          </a>
        </Link>
      ) : null}
      <HeaderMobileMenuButton
        toggleMobileMenu={toggleMobileMenu}
        showMobileMenu={showMobileMenu}
      />
    </div>
  );
}

export default UserHeaderMobile;
