export type EnvironmentShorts =
  | "DEV"
  | "TEST"
  | "PROD"
  | "PIPELINE"
  | "UNKNOWN";

export const Environment: Record<EnvironmentShorts, EnvironmentShorts> = {
  DEV: "DEV",
  TEST: "TEST",
  PROD: "PROD",
  PIPELINE: "PIPELINE",
  UNKNOWN: "UNKNOWN",
};

export const EnvironmentString: Record<EnvironmentShorts, string> = {
  DEV: "Development",
  TEST: "Testing",
  PROD: "Production",
  PIPELINE: "Pipeline",
  UNKNOWN: "Unknown",
};
