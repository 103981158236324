import Link from "next/link";
import { SingletonRouter, withRouter } from "next/router";
import React from "react";

interface Props {
  href: any;
  children: any;
  router: SingletonRouter;
  noParams?: boolean;
}

const CustomLink = (props: Props) => {
  let { href } = props;
  if (typeof href === "string") {
    href = { pathname: props.href };
  }
  href.query =
    !props?.noParams &&
    (href.query ||
      props.router.query.lang ||
      props.router.query.currency ||
      props.router.query?.typeSearch)
      ? {
          ...href.query,
          ...(props.router.query.lang ? { lang: props.router.query.lang } : {}),
          ...(props.router.query.currency
            ? { currency: props.router.query.currency }
            : {}),
          ...(props.router.query?.typeSearch
            ? { typeSearch: props.router.query.typeSearch }
            : {}),
        }
      : undefined;

  return <Link href={href}>{props.children}</Link>;
};

export default withRouter(CustomLink);
