import * as actionTypes from "./actionTypes";

export const startSearchLoading = () => ({
  type: actionTypes.START_SEARCH_LOADING,
});

export const stopSearchLoading = () => ({
  type: actionTypes.STOP_SEARCH_LOADING,
});

export const startInitialLoading = () => ({
  type: actionTypes.START_INITIAL_LOADING,
});

export const stopInitialLoading = () => ({
  type: actionTypes.STOP_INITIAL_LOADING,
});

export const startUniversalLoading = () => ({
  type: actionTypes.START_UNIVERSAL_LOADING,
});

export const stopUniversalLoading = () => ({
  type: actionTypes.STOP_UNIVERSAL_LOADING,
});
