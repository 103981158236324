import getCbtClient, { cbtResponse } from "../cbtClient";

export function listTravelReasons({ cbtToken, customerUsername, cbtApiUrl }) {
  if (!cbtToken) return;

  return cbtResponse(
    getCbtClient({ cbtToken, customerUsername, cbtApiUrl }).get(
      `/travel-reasons`
    )
  );
}
