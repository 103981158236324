const config = require("../config.json");

const sharedConfig = {
  requestorClientId: config.requestorClientId,
  requestorPassword: config.requestorPassword,
  golApiUrl: config.golApiUrl,
  fileServerApiUrl: config.fileServerApiUrl,
  zaoApiUrl: config.zaoApiUrl,
  cbtApiUrl: config.cbtApiUrl,
};

module.exports = sharedConfig;
