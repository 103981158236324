import React from "react";

import { Icon, IconWithBackground } from "shared/types";

type IconWrapType = Icon & {
  IconComponent: React.FC<Icon>;
  type: string;
};

export const IconWrap: React.FC<IconWrapType> = ({
  IconComponent,
  ...props
}) => {
  const { size, color, type, inlineSize } = props;
  const isIconFullSize = typeof inlineSize === "undefined";

  return (
    <span
      className={`icon icon-type--${type} ${
        typeof size === "string" ? `icon-size--${size}` : ""
      } icon-color--${color} ${isIconFullSize ? "icon-full-size" : ""}`}
    >
      <IconComponent {...props} />
    </span>
  );
};

export const IconWithBackgroundWrap: React.FC<IconWithBackground> = ({
  children,
  ...props
}) => {
  const { backgroundColor, backgroundType, backgroundSize, type } = props;

  return (
    <span
      className={`icon-background icon-background--${type} icon-background-type--${backgroundType} icon-background-color--${backgroundColor} icon-background-size--${backgroundSize}`}
    >
      {children}
    </span>
  );
};
