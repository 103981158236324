import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";

import CalendarMonth from "./CalendarMonth";

const CLOSE_MODAL_TIMEOUT_MS = 250;

interface CalendarProps {
  date: string;
  onChange: (any, string) => void;
  typeSearch: string;
  disabledTillDate: string;
  disabledAfterDate?: string;
  close: () => void;
}

export default function Calendar({
  date,
  onChange,
  typeSearch,
  close,
  disabledTillDate,
  disabledAfterDate,
}: CalendarProps) {
  const router = useRouter();

  const isHotelSearchForm =
    router.query &&
    router.query.typeSearch &&
    router.query.typeSearch === "hotels";

  return (
    <div className="header-search-form-calendar">
      <div className="header-search-form-calendar-inner">
        <div className="header-search-form-calendar-months">
          <div className="header-search-form-calendar-title">
            {typeSearch === "DEPARTURE" ? (
              isHotelSearchForm ? (
                <FormattedMessage id="SearchForm.hotels.selectDepartureDate" />
              ) : (
                <FormattedMessage id="SearchForm.selectDepartureDate" />
              )
            ) : isHotelSearchForm ? (
              <FormattedMessage id="SearchForm.hotels.selectReturnDate" />
            ) : (
              <FormattedMessage id="SearchForm.selectReturnDate" />
            )}
            :
          </div>
          <div className="header-search-form-calendar-months-month">
            <CalendarMonth
              value={date}
              onChange={(e) => {
                onChange(e, typeSearch);
                setTimeout(() => {
                  close();
                }, CLOSE_MODAL_TIMEOUT_MS);
              }}
              disabledTillDate={disabledTillDate}
              disabledAfterDate={disabledAfterDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
