// eslint-disable-next-line no-unused-vars
import blah from "@babel/runtime-corejs2/helpers/esm/defineProperty";

// babel error workaround https://github.com/gatsbyjs/gatsby/issues/7668
import * as actionTypes from "../actions/actionTypes";

const initState = {
  alternativeCurrencyServicesPrice: 0,
  alternativeCurrencyOperatorFee: 0,
  alternativeCurrencyFullPrice: 0,
  alternativeCurrencySumAdditionalServicesPrice: 0,
  alternativeCurrencySumMarketplaceServicesPrices: 0,
  alternativeCurrencySumPassengersPrice: 0,
  alternativeCurrencyPassengerPrices: {},
  alternativeCurrencyAdditionalServices: {},
  alternativeCurrencyServicesPrices: {},
  alternativeCurrencyPaymentPrice: 0,
  alternativeCurrencyFinalPrice: 0,
  alternativeCurrency: "",
  servicesPrice: 0,
  operatorFee: 0,
  fullPrice: 0,
  sumAdditionalServicesPrice: 0,
  sumMarketplaceServicesPrices: 0,
  sumPassengersPrice: 0,
  passengerPrices: {},
  additionalServices: {},
  servicesPrices: {},
  paymentPrice: 0,
  finalPrice: 0,
  currency: "",
};

const setPassengersPrice = (state, action) => {
  const sumPassengersPrice = sumPassengers({ ...action.data });
  const newState = {
    ...state,
    sumPassengersPrice,
    passengerPrices: { ...action.data },
  };
  const finalPrice = getFinalPrice(newState);

  return {
    ...newState,
    finalPrice,
  };
};

const setAlternativeCurrencyPassengersPrice = (state, action) => {
  const alternativeCurrencySumPassengersPrice = sumPassengers({
    ...action.data,
  });
  const newState = {
    ...state,
    alternativeCurrencySumPassengersPrice,
    alternativeCurrencyPassengerPrices: { ...action.data },
  };
  const alternativeCurrencyFinalPrice = getAlternativeCurrencyFinalPrice(
    newState
  );

  return {
    ...newState,
    alternativeCurrencyFinalPrice,
  };
};

const sumPassengers = (passengerPrices) => {
  let sumPassengerPrice = 0;
  const passengerTypes = Object.keys(passengerPrices);
  passengerTypes.forEach((type: string) => {
    sumPassengerPrice +=
      Number(passengerPrices[type].pricePerPassenger) *
      passengerPrices[type].count;
  });
  return sumPassengerPrice;
};

export const sumMarketplaceServices = (additionalServices) => {
  let additionalServicesPrice = 0;
  const additionalServicesIndexes = Object.keys(additionalServices);
  additionalServicesIndexes.forEach((index) => {
    const additionalServicesType = Object.keys(additionalServices[index]);
    additionalServicesType.forEach((type) => {
      if (
        Object.keys(additionalServices[index][type])[0].includes("marketplace")
      ) {
        additionalServicesPrice += Object.values(
          additionalServices[index][type]
        ).reduce((a, b): number => Number(a) + Number(b.price), 0);
      }
    });
  });
  return additionalServicesPrice;
};

export const sumAdditionalServices = (additionalServices) => {
  let additionalServicesPrice = 0;
  const additionalServicesIndexes = Object.keys(additionalServices);
  additionalServicesIndexes.forEach((index) => {
    const additionalServicesType = Object.keys(additionalServices[index]);
    additionalServicesType.forEach((type) => {
      if (
        Object.keys(additionalServices[index][type])[0].includes("marketplace")
      ) {
        return;
      }

      additionalServicesPrice += Object.values(
        additionalServices[index][type]
      ).reduce((a, b): number => Number(a) + Number(b.price), 0);
    });
  });
  return additionalServicesPrice;
};

const setAdditionalServicePrice = (state, data) => {
  const newPriceObj = {
    [data.name]: {
      ...state.additionalServices[data.name],
      [data.serviceType]: {
        ...(state.additionalServices[data.name]?.[data.serviceType] ?? {}),
        [data.serviceName]: {
          price: data.price,
          ref: data.ref ?? null,
          ...(data.marketplaceServiceName
            ? { marketplaceServiceName: data.marketplaceServiceName }
            : {}),
        },
      },
    },
  };

  const additionalServices = {
    ...state.additionalServices,
    ...newPriceObj,
  };

  const sumAdditionalServicesPrice = sumAdditionalServices(additionalServices);

  const sumMarketplaceServicesPrices = sumMarketplaceServices(
    additionalServices
  );

  const newState = {
    ...state,
    sumAdditionalServicesPrice,
    sumMarketplaceServicesPrices,
    additionalServices: {
      ...additionalServices,
    },
  };

  const finalPrice = getFinalPrice(newState);

  return {
    ...newState,
    finalPrice,
  };
};

const setAlternativeCurrencyAdditionalServicePrice = (state, data) => {
  const newPriceObj = {
    [data.name]: {
      ...state.alternativeCurrencyAdditionalServices[data.name],
      [data.serviceType]: {
        ...(state.alternativeCurrencyAdditionalServices[data.name]?.[
          data.serviceType
        ] ?? {}),
        [data.serviceName]: {
          price: data.price,
          ref: data.ref ?? null,
          ...(data.marketplaceServiceName
            ? { marketplaceServiceName: data.marketplaceServiceName }
            : {}),
        },
      },
    },
  };

  const alternativeCurrencyAdditionalServices = {
    ...state.alternativeCurrencyAdditionalServices,
    ...newPriceObj,
  };

  const alternativeCurrencySumAdditionalServicesPrice = sumAdditionalServices(
    alternativeCurrencyAdditionalServices
  );

  const alternativeCurrencySumMarketplaceServicesPrices = sumMarketplaceServices(
    alternativeCurrencyAdditionalServices
  );

  const newState = {
    ...state,
    alternativeCurrencySumAdditionalServicesPrice,
    alternativeCurrencySumMarketplaceServicesPrices,
    alternativeCurrencyAdditionalServices: {
      ...alternativeCurrencyAdditionalServices,
    },
  };

  const alternativeCurrencyFinalPrice = getAlternativeCurrencyFinalPrice(
    newState
  );

  return {
    ...newState,
    alternativeCurrencyFinalPrice,
  };
};

const getFinalPrice = (state) =>
  Number(state.servicesPrice) +
  Number(state.sumPassengersPrice) +
  Number(state.sumAdditionalServicesPrice) +
  Number(state.sumMarketplaceServicesPrices);

const getAlternativeCurrencyFinalPrice = (state) =>
  Number(state.alternativeCurrencyServicesPrice) +
  Number(state.alternativeCurrencySumPassengersPrice) +
  Number(state.alternativeCurrencySumAdditionalServicesPrice) +
  Number(state.alternativeCurrencySumMarketplaceServicesPrices);

const setFlightPrice = (state, data) => {
  const flightPricing =
    data.PricingDetails?.PricingDetail?.[0]?.FlightPricing || data;

  const newState = {
    ...state,
    operatorFee: flightPricing.FlightPrice.OperatorFee,
    servicesPrice: flightPricing.FlightPrice.ServicesPrice,
    servicesPrices:
      flightPricing.ServicePrices?.ServicePrice ||
      flightPricing.FlightPrice.ServicesPrice,
    fullPrice: flightPricing.FlightPrice.FullPrice,
  };
  const finalPrice = getFinalPrice(newState);
  return { ...newState, finalPrice };
};

const setAlternativeCurrencyFlightPrice = (state, data) => {
  const flightPricing =
    data.AlternativeCurrencyPricingDetails
      ?.AlternativeCurrencyPricingDetail?.[0]?.FlightPricing || data;

  const newState = {
    ...state,
    alternativeCurrencyOperatorFee: flightPricing.FlightPrice.OperatorFee,
    alternativeCurrencyServicesPrice: flightPricing.FlightPrice.ServicesPrice,
    alternativeCurrencyServicesPrices:
      flightPricing.ServicePrices?.ServicePrice ||
      flightPricing.FlightPrice.ServicesPrice,
    alternativeCurrencyFullPrice: flightPricing.FlightPrice.FullPrice,
  };
  const alternativeCurrencyFinalPrice = getAlternativeCurrencyFinalPrice(
    newState
  );
  return { ...newState, alternativeCurrencyFinalPrice };
};

const removeAdditionalService = (state, data) => {
  const { [data.serviceName]: foo, ...rest } = state.additionalServices[
    data.name
  ][data.serviceType];

  const newPriceObj = {
    [data.name]: {
      ...state.additionalServices[data.name],
      [data.serviceType]: {
        ...rest,
      },
    },
  };
  const additionalServices = {
    ...state.additionalServices,
    ...newPriceObj,
  };
  const sumAdditionalServicesPrice = sumAdditionalServices(additionalServices);

  const newState = {
    ...state,
    sumAdditionalServicesPrice,
    additionalServices: {
      ...additionalServices,
    },
  };

  const finalPrice = getFinalPrice(newState);

  return {
    ...newState,
    finalPrice,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_FLIGHT_PRICE:
      return setFlightPrice(state, action.data);
    case actionTypes.SET_ALTERNATIVE_CURRENCY_FLIGHT_PRICE:
      return setAlternativeCurrencyFlightPrice(state, action.data);
    case actionTypes.SET_PASSENGERS_PRICE:
      return setPassengersPrice(state, action);
    case actionTypes.SET_ALTERNATIVE_CURRENCY_PASSENGERS_PRICE:
      return setAlternativeCurrencyPassengersPrice(state, action);
    case actionTypes.SET_ADDITIONAL_SERVICE_PRICE:
      return setAdditionalServicePrice(state, action.data);
    case actionTypes.SET_ALTERNATIVE_CURRENCY_ADDITIONAL_SERVICE_PRICE:
      return setAlternativeCurrencyAdditionalServicePrice(state, action.data);
    case actionTypes.REMOVE_ADDITIONAL_SERVICE:
      return removeAdditionalService(state, action.data);
    case actionTypes.SET_CURRENCY:
      return { ...state, currency: action.data };
    case actionTypes.SET_PAYMENT_PRICE:
      return { ...state, paymentPrice: action.data };
    case actionTypes.SET_ALTERNATIVE_CURRENCY_PAYMENT_PRICE:
      return { ...state, alternativeCurrencyPaymentPrice: action.data };
    case actionTypes.GET_FINAL_PRICE:
      return getFinalPrice(state);
    case actionTypes.RESET_PRICE_STATE:
      return { ...initState };
    default:
      return state;
  }
};
