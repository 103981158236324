import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";

import usePageTitle, { UsePageTitleProps } from "../../hooks/usePageTitle";

type Props = {
  title?: UsePageTitleProps;
};

type MetaTagsProps = {
  title: string;
  description: string;
  keywords: string;
};

const MetaTags = ({ title, description, keywords }: MetaTagsProps) => {
  const [currentPath, setCurrentPath] = useState("");

  const { logoUrl } = useSelector((state: AppState) => ({
    logoUrl:
      state.storage.frontendSettings?.dealerFrontendSettings?.banner?.image?.url
        ?.default,
  }));

  useEffect(() => {
    setCurrentPath(window.location.href);
  }, []);

  return (
    <Head>
      {title && typeof title === "string" && <title>{title}</title>}

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:type" content="website" />
      {currentPath && <meta property="og:url" content={currentPath} />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {logoUrl && <meta property="og:image" content={logoUrl} />}

      <meta property="twitter:card" content="summary_large_image" />
      {currentPath && <meta property="twitter:url" content={currentPath} />}
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {logoUrl && <meta property="twitter:image" content={logoUrl} />}
    </Head>
  );
};

const PageSeo = ({ title }: Props) => {
  const intl = useIntl();
  const router = useRouter();

  const {
    airports,
    defaultAirports,
    countries,
    searchQuery,
    customPageDescriptions,
    customPageKeywords,
    currentLanguage,
  } = useSelector((state: AppState) => ({
    airports: state.storage.airportDestinations,
    defaultAirports: state.storage.defaultAirportSuggestions,
    countries: state.storage.countries,
    searchQuery: state.storage.searchQuery,
    customPageDescriptions:
      state.storage?.frontendSettings?.dealerFrontendSettings?.pageDescriptions,
    customPageKeywords:
      state.storage?.frontendSettings?.dealerFrontendSettings?.pageKeywords,
    currentLanguage: state.requestorConfig.currentLanguage,
  }));

  const currentLanguageWithException =
    currentLanguage === "cs" ? "cz" : currentLanguage;

  const customPageMeta = {
    description:
      customPageDescriptions?.[currentLanguageWithException] ||
      customPageDescriptions?.default,
    keywords:
      customPageKeywords?.[currentLanguageWithException] ||
      customPageKeywords?.default,
  };

  const airportTitle = (airportCode: string) => {
    const defaultAirportCountry = defaultAirports.find(
      (airport) => airport.Code === airportCode
    )?.Country;

    if (defaultAirportCountry) {
      return countries[defaultAirportCountry];
    }

    const airportCountry = airports.find(
      (airport) => airport.Code === airportCode
    )?.Country;

    if (airportCountry) {
      return countries[airportCountry];
    }

    return null;
  };

  const pageTitle = usePageTitle({ ...title });

  const defaultDescription = intl.formatMessage({
    id: "Screen.Meta.Description",
  });

  const defaultKeywords = intl.formatMessage({
    id: "Screen.Meta.Keywords",
  });

  // FLIGHT RESULTS PAGE
  if (router.pathname === "/results") {
    const isMultiCity =
      Object.keys(searchQuery).includes("flight_1_origin") &&
      Object.keys(searchQuery).includes("flight_1_destination");

    const travelFrom = airportTitle(
      isMultiCity ? searchQuery.flight_1_origin : searchQuery.from
    );
    const travelTo = airportTitle(
      isMultiCity ? searchQuery.flight_1_destination : searchQuery.to
    );

    const originAndDestinationAvailible = travelFrom && travelTo;

    const description = intl.formatMessage(
      {
        id: "Screen.Meta.Description.Search",
      },
      {
        from: travelFrom,
        to: travelTo,
      }
    );

    const keywords = intl.formatMessage(
      {
        id: "Screen.Meta.Keywords.Search",
      },
      {
        from: travelFrom,
        to: travelTo,
      }
    );

    const descriptionContent =
      customPageMeta?.description ||
      (originAndDestinationAvailible ? description : defaultDescription);

    const keywordsContent =
      customPageMeta?.keywords ||
      (originAndDestinationAvailible ? keywords : defaultKeywords);

    return (
      <MetaTags
        title={pageTitle}
        description={descriptionContent}
        keywords={keywordsContent}
      />
    );
  }

  // DEFAULT FOR ALL OTHER PAGES
  return (
    <MetaTags
      title={pageTitle}
      description={customPageMeta?.description || defaultDescription}
      keywords={customPageMeta?.keywords || defaultKeywords}
    />
  );
};

export default PageSeo;
