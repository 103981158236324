import { createResponse, genderFromNamePrefix } from "../lib/functions";
import golApiCall from "./golApiCall";

function golRequest({ Person, namePrefixValue, loyaltyPrograms, passports }) {
  let loyalties = {};
  if (loyaltyPrograms && loyaltyPrograms.length !== 0) {
    loyalties = {
      Loyalties: {
        LoyaltyProgram: loyaltyPrograms.map((program) => ({
          Id: program.Id,
          MembershipId: program.MembershipId,
        })),
      },
    };
  }
  let passportsAttr = {};
  if (passports && passports.length !== 0) {
    passportsAttr = {
      Passports: {
        Passport: passports.map((el) => {
          const passObj = { ...el, Number: el.passportNumber ?? el.Number };
          delete passObj.passportNumber;
          return passObj;
        }),
      },
    };
  }
  return {
    GolApi: {
      RequestDetail: {
        ModifyCustomerRequest_2: {
          ModifyCustomer: {
            Person: {
              Gender: genderFromNamePrefix(namePrefixValue),
              BirthDate: Person.birthDate || "",
              NamePrefix: namePrefixValue || Person.namePrefix,
              GivenName: {
                $t: Person.givenName,
              },
              Surname: {
                $t: Person.surname,
              },
              Telephone: {
                PhoneNumber: Person.telephone,
              },
              Email: {
                $t: Person.email,
              },
            },
          },
          CustomerSettings: {
            ...(passportsAttr && { ...passportsAttr }),
            ...(loyalties && { ...loyalties }),
            Newsletter: {
              Active: (Person.newsletter || "false").toString(),
            },
            SpecialOffers: {
              Active: (Person.specialOffers || "false").toString(),
            },
          },
        },
      },
    },
  };
}

export default async (data) => {
  const modifyCustomerResponse = await golApiCall(golRequest(data));
  const responseObj = createResponse(modifyCustomerResponse);

  if (responseObj.success) {
    responseObj.data = {
      ...responseObj.data,
      formattedData: formatResponse(responseObj.data),
    };
    return responseObj;
  }
  return responseObj;
};

const formatResponse = ({
  CustomerInfo,
  CustomerPassengers,
  CustomerSettings,
  RequestorPrivateSettings,
}) => {
  const customerDetail = CustomerInfo.Person;

  const cbtToken = RequestorPrivateSettings?.TokenCBT.$t;
  const passengers = CustomerPassengers?.CustomerPassenger || [];

  const normalizePerson = (person) => ({
    email: person.Email.$t,
    givenName: person.GivenName.$t,
    surname: person.Surname.$t,
    telephone: person.Telephone.PhoneNumber,
    namePrefix: person.NamePrefix,
    birthDate: person.BirthDate,
  });

  return {
    username: CustomerInfo.Username,
    ...normalizePerson(customerDetail),
    passport: CustomerSettings.Passports.Passport.map((pass) => ({
      ...pass,
      passportNumber: pass.Number,
    })),
    loyaltyProgram: [...CustomerSettings.Loyalties.LoyaltyProgram],
    passengers: passengers.map((passenger) => ({
      id: passenger.PassengerId,
      ...normalizePerson(passenger.Person),
    })),
    newsletter: CustomerSettings.Newsletter.Active === "true",
    specialOffers: CustomerSettings.SpecialOffers.Active === "true",
    cbtToken,
  };
};
