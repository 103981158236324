import { useEffect } from "react";

export function usePreselectedPaymentType({
  preselectedPaymentTypeName,
  paymentFormMethods,
  onSelectPayment,
}) {
  useEffect(() => {
    if (preselectedPaymentTypeName) {
      const preselectedPayment = paymentFormMethods.find(
        (paymentMethod) => paymentMethod.Method === preselectedPaymentTypeName
      );

      if (preselectedPayment) {
        onSelectPayment(preselectedPayment.Id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedPaymentTypeName, paymentFormMethods?.length]);
}

export function parsePaymentFormMethods(paymentFormMethods, codebook) {
  return paymentFormMethods?.TicketingCombinations?.TicketingCombination?.map(
    (combination) => {
      const paymentMethod = codebook?.PaymentformMethods?.PaymentformMethod.find(
        (paymentCodebook) =>
          paymentCodebook.Id === combination?.PaymentformOption.Id
      );
      if (paymentMethod) {
        return { ...combination?.PaymentformOption, ...paymentMethod };
      }
      return false;
    }
  );
}

export function parseAlternativeCurrencyPaymentFormMethods(
  paymentFormMethods,
  codebook
) {
  return paymentFormMethods?.AlternativeCurrencyTicketingCombinations?.AlternativeCurrencyTicketingCombination?.map(
    (combination) => {
      const paymentMethod = codebook?.PaymentformMethods?.PaymentformMethod.find(
        (paymentCodebook) =>
          paymentCodebook.Id === combination?.PaymentformOption.Id
      );
      if (paymentMethod) {
        return { ...combination?.PaymentformOption, ...paymentMethod };
      }
      return false;
    }
  );
}

export function parsePaymentformMethodForms(codebook) {
  return codebook?.PaymentformMethodForms?.PaymentformMethodForm || [];
}
