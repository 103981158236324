import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as formReducer } from "redux-form";
import thunkMiddleware from "redux-thunk";

import BookingFormReducer from "./bookingFormReducer";
import BookingReducer from "./bookingReducer";
import CbtReducer from "./cbtReducer";
import ClientReducer from "./clientReducer";
import FormsReducer from "./forms";
import HotelsReducer from "./hotelsReducer";
import LoaderReducer from "./loaderReducer";
import MessageCenterReducer from "./messageCenterReducer";
import MobileFormsReducer from "./mobileFormsReducer";
import PriceReducer from "./priceReducer";
import RequestorConfig from "./requestorConfigReducer";
import SearchReducer from "./searchReducer";
import StorageReducer from "./storageReducer";
import UserReducer from "./userReducer";

export const rootReducer = combineReducers({
  search: SearchReducer,
  storage: StorageReducer,
  booking: BookingReducer,
  form: formReducer,
  requestorConfig: RequestorConfig,
  loading: LoaderReducer,
  mobileForms: MobileFormsReducer,
  user: UserReducer,
  client: ClientReducer,
  forms: FormsReducer,
  cbt: CbtReducer,
  price: PriceReducer,
  hotels: HotelsReducer,
  bookingForm: BookingFormReducer,
  messageCenter: MessageCenterReducer,
});

// eslint-disable-next-line no-undef
export type AppState = ReturnType<typeof rootReducer>;

export function initializeStore() {
  return createStore(rootReducer, applyMiddleware(thunkMiddleware));
}
