import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

function getGolRequestReservationDestinationsRulesInfoQuery(
  reservationId: string,
  travelerCitizenship: string[]
) {
  const golRequest = {
    GolApi: {
      RequestDetail: {
        ReservationDestinationsRulesInfoRequest_1: {
          SearchReservation: {
            Reservation: {
              ReservationId: reservationId,
              ExternalReservationId: "123456",
            },
          },
          TravelersCitizenship: {
            TravelerCitizenship: travelerCitizenship.map((citizenship) => ({
              $t: citizenship,
            })),
          },
        },
      },
    },
  };
  return golRequest;
}

export default async (reservationId: string, travelerCitizenship: string[]) => {
  const golRequest = getGolRequestReservationDestinationsRulesInfoQuery(
    reservationId,
    travelerCitizenship
  );

  const response = await golApiCall(golRequest);
  return createResponse(response);
};
