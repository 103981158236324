export class DateTimeFormat {
  static DATETIME_FORMAT_CZ = "day.month.year hour:minute";

  static DAY_MONTH_YEAR_FORMAT_CZ = "day.month.year";

  static DAY_MONTH_YEAR_FORMAT_US = "month/day/year";

  static DAY_MONTH_YEAR_FORMAT_DASH = "year-month-day";

  static DAY_MONTH_FORMAT_OPTIONS = {
    day: "2-digit",
    month: "2-digit",
  };

  static DATETIME_FORMAT_CZ_OPTIONS = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  static DAY_MONTH_YEAR_FORMAT_CZ_OPTIONS = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  static DAY_MONTH_YEAR_FORMAT_US_OPTIONS = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
}
