import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";

import { corporate as getCorporate } from "shared/cbt/api/corporates";
import { listCostCenters } from "shared/cbt/api/costCenters";
import { listGrades } from "shared/cbt/api/grades";
import { listGuests } from "shared/cbt/api/guests";
import { listTravelReasons } from "shared/cbt/api/travelReasons";
import { listTravellers } from "shared/cbt/api/travellers";
import { getCbtToken } from "shared/cbt/cbt";
import {
  changeCostCenter,
  corporateFetched,
  costCentersFetched,
  gradesFetched,
  guestsFetched,
  managersFetched,
  travelReasonsFetched,
  usersFetched,
} from "shared/data/actions/cbt";

import { AppState } from "../data/reducers";
import { listManagers } from "./api/managers";

// function for pausing the execution of the test. Mainly used for waiting for a specific UI component to appear on the screen
export const sleep = (duration: number): Promise<void> =>
  new Promise((resolve) => setTimeout(() => resolve(), duration));

export function useFetchCbtPassengersOptions(
  dispatch: Dispatch<any>,
  cbtToken: string,
  withoutTravellers?: boolean
): void {
  const { cbtApiUrl, customerUsername } = useSelector((state: AppState) => ({
    cbtApiUrl:
      state.storage.frontendSettings?.dealerCorporateSettings?.cbtApiUrl,
    customerUsername: state.user.email,
  }));

  useEffect(() => {
    if (!cbtToken || !customerUsername || !cbtApiUrl) {
      return undefined;
    }

    async function fetchData() {
      const requests = [
        listTravelReasons({ cbtToken, customerUsername, cbtApiUrl }),
        listCostCenters({ cbtToken, customerUsername, cbtApiUrl }),
        getCorporate({ cbtToken, customerUsername, cbtApiUrl }),
        listGuests({ cbtToken, customerUsername, cbtApiUrl }),
        listManagers({ cbtToken, customerUsername, cbtApiUrl }),
        listGrades({ cbtToken, customerUsername, cbtApiUrl }),
      ];

      if (!withoutTravellers) {
        requests.push(
          listTravellers({ cbtToken, customerUsername, cbtApiUrl, limit: 1000 })
        );
      }

      const [
        travelReasons,
        costCenters,
        corporateValue,
        guests,
        managers,
        grades,
        users,
      ] = await Promise.all(requests);

      dispatch(corporateFetched(corporateValue));
      dispatch(travelReasonsFetched(travelReasons));
      dispatch(costCentersFetched(costCenters));
      dispatch(guestsFetched(guests));
      dispatch(managersFetched(managers));
      dispatch(gradesFetched(grades));

      if (!withoutTravellers) {
        dispatch(usersFetched(users));
      }
    }

    fetchData();
  }, [dispatch, cbtToken, customerUsername, cbtApiUrl, withoutTravellers]);
}

export function useCbtToken(user, frontendSettings): [string, boolean] {
  const [isLoading, setLoading] = useState(true);
  const [cbtToken, setCbtToken] = useState();

  const { customerUsername, isLoggedIn } = useSelector((state: AppState) => ({
    customerUsername: state.user.email,
    isLoggedIn: state.user.isLoggedIn,
  }));

  useEffect(() => {
    async function loadToken() {
      const receivedToken = await getCbtToken(
        user,
        frontendSettings,
        customerUsername
      );
      setCbtToken(receivedToken);
      setLoading(false);
    }

    loadToken();
  }, [user, frontendSettings, customerUsername]);

  return [isLoggedIn ? cbtToken : null, isLoading];
}

export function useFlightOffer(flightOffer) {
  const defaultSelections = flightOffer.FlightItinerary.FlightStream.map(
    (fs) => fs[0].Key
  );
  const [selectedStreamIndexes, setSelectedStreamIndexes] = useState(
    defaultSelections
  );

  function changeSelectedStreamIndex(streamIndex: number, key: string): void {
    setSelectedStreamIndexes((currentSelectedStreamIndexes) => {
      const newStreamIndex = [...currentSelectedStreamIndexes];
      newStreamIndex[streamIndex] = key;
      return newStreamIndex;
    });
  }

  return [selectedStreamIndexes, changeSelectedStreamIndex];
}

export function keyFromSelectedStream(
  pricingDetailKey: string,
  selectedStreamParts: string[]
): string {
  return `${pricingDetailKey}-${selectedStreamParts.slice(1).join("-")}`;
}

export function useTravelPolicies(
  flightOffer,
  selectedStreamParts,
  selectedBrand
) {
  const { travelPoliciesResults, travelPolicies } = useSelector(
    (state: AppState) => ({
      travelPoliciesResults: state.search.travelPoliciesResults,
      travelPolicies: state.search.travelPolicies,
    })
  );
  const key = keyFromSelectedStream(
    selectedBrand.PricingDetailKey,
    selectedStreamParts
  );
  const travelPoliciesResult = travelPoliciesResults?.[key];

  return {
    travelPolicies,
    travelPoliciesResult,
  };
}

function filterCostCenters(eligibleTravellers, costCenters) {
  const allowedCostCentersIds = eligibleTravellers.reduce((ids, traveller) => {
    if (!traveller) {
      return;
    }
    traveller.CostCenterIds.forEach((t) => ids.add(t));
    return ids;
  }, new Set());

  return costCenters.filter((cc) => allowedCostCentersIds.has(cc.value));
}

export function useCostCenters({
  corporate,
  costCenter,
  costCenters,
  travellers,
  users,
  dispatch,
}) {
  const dontFilterUnassignedCostCenters = !corporate?.FilterUnassignedCostCenters;

  const allowedCostCenters = useMemo(() => {
    if (dontFilterUnassignedCostCenters) {
      return costCenters;
    }

    const eligibleTravellers =
      travellers.length === 0
        ? Object.values(users)
        : travellers.map((tid) => users[tid]);

    return eligibleTravellers.includes(undefined)
      ? costCenters
      : filterCostCenters(eligibleTravellers, costCenters);
  }, [costCenters, travellers, users, dontFilterUnassignedCostCenters]);

  const selectedCostCenter =
    allowedCostCenters.find(({ value }) => value === costCenter) || null; // use null to reset SimpleSelect to blank state

  const allEligibleCostCenters = useMemo(() => {
    return dontFilterUnassignedCostCenters
      ? costCenters
      : filterCostCenters(Object.values(users), costCenters);
  }, [costCenters, users, dontFilterUnassignedCostCenters]);

  useEffect(() => {
    if (
      (!costCenter && !selectedCostCenter) ||
      costCenter !== (selectedCostCenter?.value ?? null)
    ) {
      if (allEligibleCostCenters.length === 1) {
        dispatch(changeCostCenter(allEligibleCostCenters[0].value));
      } else {
        dispatch(changeCostCenter(null));
      }
    }
  }, [dispatch, allEligibleCostCenters, costCenter, selectedCostCenter]);

  return { allowedCostCenters, selectedCostCenter };
}
