import { SingletonRouter, withRouter } from "next/router";
import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import HeaderLogoStripeContainer from "shared/containers/UI/Header/HeaderLogoStripeContainer";
import { AppState } from "shared/data/reducers";

import FormattedMessage from "@components/UI/FormattedMessage";
import HeaderLogo from "@components/UI/Header/HeaderLogo";
import LoginButton from "@components/UI/Header/LoginButton";
import HeaderMenu from "@components/UI/Header/Menu";
import HeaderMobileMenu from "@components/UI/Header/MobileMenu";
import ReservationsButton from "@components/UI/Header/ReservationsButton";
import SetCurrency from "@components/UI/Header/SetCurrency";
import SetLanguage from "@components/UI/Header/SetLanguage";
import UserHeader from "@components/UI/Header/UserHeader";
import UserHeaderMobile from "@components/UI/Header/UserHeaderMobile";

import { ROUTES } from "@shared/constants";

interface Props {
  isLoggedIn: boolean;
  router: SingletonRouter;
  dispatch: Dispatch<any>;
  showCondensedHeaderLogoStripe?: boolean;
  frontendSettings: any;
  fileServerData: any;
}

function HeaderLogoStripe({
  router,
  showCondensedHeaderLogoStripe,
  frontendSettings,
  dispatch,
  isLoggedIn,
}: Props) {
  const [bannerHeight, setBannerHeight] = React.useState(75);
  const banner = frontendSettings?.dealerFrontendSettings?.banner;
  const bannerUrl = banner?.iframe?.url?.default;

  useEffect(() => {
    if (bannerUrl) {
      const iframe = document.getElementById(
        "header-banner"
      ) as HTMLIFrameElement;

      if (iframe) {
        window.addEventListener("message", (event) => {
          const { width } = event.data;
          if (!width) {
            return;
          }
          setBannerHeight(width);
        });
      }
    }
  }, [bannerUrl]);

  if (router.route.includes("/iframe")) {
    return null;
  }

  const pagesWithoutLoginRequired = [
    ROUTES.LOGIN,
    ROUTES.FORGOTTEN_PASSWORD,
    ROUTES.CREATE_NEW_PASSWORD,
    ROUTES.REGISTER,
    ROUTES.EMAIL_CONFIRM,
  ];

  const canLinkToHomepage = !(
    pagesWithoutLoginRequired.includes(router.route) &&
    router.query.isLoginRequired === "true"
  );

  const isCbtEnabled =
    frontendSettings.dealerCorporateSettings?.enableCbt === "true";

  const isHotelSearchForm = router?.query?.typeSearch === "hotels";
  return (
    <HeaderLogoStripeContainer dispatch={dispatch} router={router}>
      {({
        state,
        toggleMobileMenu,
        logout,
        toggleMobileChangeLanguage,
        toggleMobileChangeCurrency,
      }) => (
        <div>
          <div>
            {bannerUrl && (
              <iframe
                id="header-banner"
                src={bannerUrl}
                height={bannerHeight}
                title="Header Banner"
                style={{
                  width: "100%",
                  border: "none",
                }}
              />
            )}
            <div className="container header-container">
              {!bannerUrl ? (
                <HeaderLogo canLinkToHomepage={canLinkToHomepage} />
              ) : (
                <div />
              )}
              <UserHeaderMobile
                showMobileMenu={state.showMobileMenu}
                toggleMobileMenu={toggleMobileMenu}
              />
              <div className="header-links-container">
                {isLoggedIn ? (
                  <>
                    <ReservationsButton />
                    <div className="separator-dotted" />
                    <UserHeader logout={logout} />
                  </>
                ) : router.route !== "/login" ? (
                  <LoginButton />
                ) : (
                  isCbtEnabled && (
                    <a
                      className="header-button header-button-text"
                      target="_blank"
                      href="https://www.cee-systems.com/privacypolicy"
                    >
                      <FormattedMessage id="HeaderLogoStripe.privacyPolicy" />
                    </a>
                  )
                )}
                <div className="separator-dotted" />
                <SetLanguage />

                <SetCurrency />
              </div>
            </div>

            <div className="clearfix" />
            <HeaderMenu />
            <HeaderMobileMenu
              toggleMobileMenu={toggleMobileMenu}
              showMobileChangeLanguage={state.showMobileChangeLanguage}
              showMobileChangeCurrency={state.showMobileChangeCurrency}
              showMobileMenu={state.showMobileMenu}
              toggleMobileChangeLanguage={toggleMobileChangeLanguage}
              toggleMobileChangeCurrency={toggleMobileChangeCurrency}
              isLoggedIn={isLoggedIn}
              logout={logout}
              isCbtEnabled={isCbtEnabled}
            />

            {showCondensedHeaderLogoStripe !== true && (
              <h1 className="header-h1">
                {isHotelSearchForm ? (
                  <FormattedMessage id="HeaderLogoStripe.hotels.firstRow" />
                ) : (
                  <FormattedMessage id="HeaderLogoStripe.firstRow" />
                )}{" "}
                <br className="only-mobile" />
                <b>
                  <FormattedMessage id="HeaderLogoStripe.secondRow" />
                </b>
                <span className="secondary-color">.</span>
              </h1>
            )}
          </div>
        </div>
      )}
    </HeaderLogoStripeContainer>
  );
}

export default withRouter(
  connect((state: AppState) => ({
    fileServerData: state.requestorConfig.fileServerData,
    isLoggedIn: state.user.isLoggedIn,
    frontendSettings: state.storage.frontendSettings,
  }))(injectIntl(HeaderLogoStripe))
);
