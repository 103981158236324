import golApiCall from "shared/gol-api/golApiCall";
import { createResponse } from "shared/lib/functions";

function getGolListSpecialoffersRequest() {
  return {
    GolApi: {
      RequestDetail: {
        ListSpecialoffersRequest_1: {
          SpecialofferTypes: {
            SpecialofferType: { Code: "flight" },
          },
        },
      },
    },
  };
}

export default async (options?: { alternativeCurrency?: string }) => {
  const request = getGolListSpecialoffersRequest();
  const rawResponse = await golApiCall(request, options);
  return createResponse(rawResponse);
};
