import classnames from "classnames";
import { ReactElement, SyntheticEvent } from "react";

import Img from "@components/FileServer/Img";

interface Props {
  customCheckboxClass?: string;
  customContainerClass?: string;
  customLabelClass?: string;
  onClick?: (e?: SyntheticEvent) => void;
  swapOrder?: boolean;
  checked?: boolean;
  id?: string;
  idCheckbox?: string;
  idLabel?: string;
  label?: string | ReactElement;
  disabled?: boolean;
}

const Checkbox = (props: Props) => (
  <div
    role="checkbox"
    className={classnames("checkbox", props.customContainerClass, {
      disabled: !!props?.disabled,
    })}
    onClick={props.onClick}
    id={props.id}
    aria-checked={props.checked}
  >
    {props.checked ? (
      <Img
        src="/static/images/ico-checkbox-checked.svg"
        className={classnames(
          "checkbox-background pointer",
          props.customCheckboxClass
        )}
        id={props.idCheckbox}
      />
    ) : (
      <Img
        src="/static/images/ico-checkbox-unchecked.svg"
        className={classnames(
          "checkbox-background pointer",
          props.customCheckboxClass
        )}
        id={props.idCheckbox}
      />
    )}
    <span
      className={classnames(
        "checkbox-label",
        {
          "checkbox-swap-order": props.swapOrder,
        },
        props.customLabelClass
      )}
      id={props.idLabel}
    >
      {props.label}
    </span>
  </div>
);

export default Checkbox;
