import golApiCall from "./golApiCall";

function getGolRequestListReservationsQuery(refs) {
  const golRequest = {
    GolApi: {
      RequestDetail: {
        BaggageDetailsRequest_1: {
          SearchedReferences: {
            Reference: refs.map((oRef) => ({ $t: oRef })),
          },
        },
      },
    },
  };
  return golRequest;
}

export default async (refs) => {
  const golRequest = getGolRequestListReservationsQuery(refs);
  const response = await golApiCall(golRequest);
  return response;
};
