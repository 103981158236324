import { FrontendSettings } from "../../types";
import * as actionTypes from "../actions/actionTypes";

interface IStorageData {
  defaultAirportSuggestions: any[];
  airportDestinations: any[];
  passengerPrefix: any[];
  presetAirports: string;
  carrierFilter: null | string[];
  countries: any[];
  // eslint-disable-next-line no-undef
  searchQuery: Record<any, any>;
  requestorPostfix: string;
  country: string;
  departureDays: number;
  durationOfStay: number;
  frontendSettings: FrontendSettings;
  loyaltyPrograms: any[];
  rawDefaultAirports: any[];
}

const initState: IStorageData = {
  defaultAirportSuggestions: [],
  airportDestinations: [],
  passengerPrefix: [],
  presetAirports: "",
  countries: [],
  searchQuery: {},
  carrierFilter: null,
  requestorPostfix: "mimi",
  country: "",
  departureDays: 7,
  durationOfStay: 7,
  frontendSettings: {
    dealerGoogleSettings: null,
    dealerFlexShoppingSettings: null,
    dealerCorporateSettings: null,
  },
  loyaltyPrograms: [],
  rawDefaultAirports: [],
};

const setDefaultAirportSuggestions = (state, action) => {
  return { ...state, defaultAirportSuggestions: [...action.data] };
};

const addAirportDestinations = (state, action) => {
  const accumulatedDestinations = [
    ...action.data,
    ...state.airportDestinations,
  ];
  const deduplicatedDestinations = accumulatedDestinations.reduce(
    (acc, current) => {
      const x = acc.find(({ Code }) => Code === current.Code);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    },
    []
  );
  return { ...state, airportDestinations: deduplicatedDestinations };
};

const setCountries = (state, action) => {
  return { ...state, countries: { ...action.data } };
};

const setSearchQuery = (state, action) => {
  return { ...state, searchQuery: { ...action.data } };
};

const setRequestorData = (state, action) => {
  return {
    ...state,
    requestorPostfix: action.data.requestorPostfix,
    country: action.data.country,
  };
};

const setAirlines = (state, action) => {
  return {
    ...state,
    airlines: [...action.data],
  };
};

const setPassengerPrefixes = (state, action) => {
  return {
    ...state,
    passengerPrefix: [...action.data],
  };
};

const setCalendarSettings = (state, action) => {
  return {
    ...state,
    departureDays: Number(action.data.departureDays),
    durationOfStay: Number(action.data.durationOfStay),
  };
};

const setLoyaltyPrograms = (state, action) => {
  return {
    ...state,
    loyaltyPrograms: [...action.data],
  };
};

const setFrontendSettings = (state, action) => {
  const formattedData = {
    ...action.data,
    carrierFilter:
      action.data.carrierFilter !== undefined
        ? action.data.carrierFilter?.split(",")?.map((item) => item.trim())
        : null,
  };

  return {
    ...state,
    frontendSettings: { ...formattedData },
  };
};

const setRawDefaultAirports = (state, action) => {
  return {
    ...state,
    rawDefaultAirports: { ...action.data },
  };
};

const reducer = (state = initState, action): IStorageData => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_AIRPORT_SUGGESTIONS:
      return setDefaultAirportSuggestions(state, action);
    case actionTypes.ADD_AIRPORT_DESTINATIONS:
      return addAirportDestinations(state, action);
    case actionTypes.SET_COUNTRIES:
      return setCountries(state, action);
    case actionTypes.SET_QUERY:
      return setSearchQuery(state, action);
    case actionTypes.SET_REQUESTOR_DATA:
      return setRequestorData(state, action);
    case actionTypes.SET_AIRLINES:
      return setAirlines(state, action);
    case actionTypes.SET_PASSENGER_PREFIXES:
      return setPassengerPrefixes(state, action);
    case actionTypes.SET_CALENDAR_SETTINGS:
      return setCalendarSettings(state, action);
    case actionTypes.SET_LOYALTY_PROGRAMS:
      return setLoyaltyPrograms(state, action);
    case actionTypes.SET_FRONTEND_SETTINGS:
      return setFrontendSettings(state, action);
    case actionTypes.SET_RAW_DEFAULT_AIRPORTS:
      return setRawDefaultAirports(state, action);
    default:
      return state;
  }
};

export default reducer;
