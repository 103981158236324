import golApiCall from "shared/gol-api/golApiCall";
import { createResponse, normalizeXMLAttribute } from "shared/lib/functions";

function getGolCheckSpecialoffersRequest({
  specialOfferId,
  passengers,
  startDate,
  endDate,
  dates,
}) {
  const filteredDates = dates.filter((el) => !!el);

  return {
    GolApi: {
      RequestDetail: {
        CheckSpecialoffersRequest_1: {
          SearchedSpecialOffer: {
            SpecialOfferId: specialOfferId,
            ...(startDate && { StartDate: startDate }),
            ...(endDate && { EndDate: endDate }),
          },
          SearchedPassengers: {
            SearchedPassenger: normalizeXMLAttribute(
              passengers.map((passenger) => ({
                Code: passenger,
              }))
            ),
          },
          PlanCalendar: {
            CalendarStep: normalizeXMLAttribute(
              filteredDates.map((date) => ({
                DateRange: {
                  DateFrom: date.dateFrom,
                  DateTo: date.dateTo,
                },
              }))
            ),
          },
        },
      },
    },
  };
}

export default async (params) => {
  const request = getGolCheckSpecialoffersRequest(params);
  const rawResponse = await golApiCall(request);
  return createResponse(rawResponse);
};
