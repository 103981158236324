import { COOKIE_BACKEND_ID_NAME } from "../data/cookies";
import { getCookie } from "../data/cookiesOps";
import axios from "./axios";

const sharedConfig = require("../config/sharedConfig");

export default axios.create({
  baseURL: `${sharedConfig.golApiUrl}${getBackendIdForAxiosReq()}`,
});

function getBackendIdForAxiosReq() {
  if (process.browser) {
    if (window.location.search.includes("backend_id=")) {
      return `?backend_id=${window.location.search.split("backend_id=")[1]}`;
    }
    return "";
  }

  const backendIdValue = getCookie(COOKIE_BACKEND_ID_NAME);

  if (backendIdValue) {
    return `?backend_id=${backendIdValue}`;
  }

  return "";
}
