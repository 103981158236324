import axios from "axios";

import { logoutCustomer } from "../data/customerTokens";
import Logger from "../services/Logger";

const sharedConfig = require("../config/sharedConfig");

export async function cbtResponse(response) {
  try {
    const resp = await response;
    const { status, data } = resp;

    if (status >= 200 && status < 300) {
      return { success: true, data };
    }
    if (data.error) {
      cbtError(data.error);
    }

    return { success: false, errorMsg: data, errorCode: status };
  } catch (e) {
    cbtError(e);
    return { success: false, errorMsg: e, errorCode: e.response?.status };
  }
}

function cbtError(e) {
  Logger.error("CBT communication error: ", e);

  if (!isReactNativeEnvironment()) {
    if (!window.errorDisplayed) {
      alert(
        `We have some problem with CBT communication and you were logged off. Please log in again. If the problem persists please contact our support. Thank you.`
      );
      window.errorDisplayed = true;
    }
    window.location = "/";
  } else {
    alert(
      `We have some problem with CBT mobile communication and you were logged off. Please log in again. If the problem persists please contact our support. Thank you.`
    );
  }

  logoutCustomer(); // todo give back
}

export function isReactNativeEnvironment() {
  // eslint-disable-next-line eqeqeq
  if (typeof document != "undefined") {
    return false;
  }

  return true;
}

export default function getCbtClient({
  cbtToken,
  customerUsername,
  cbtApiUrl,
}) {
  return axios.create({
    baseURL: cbtApiUrl || sharedConfig.cbtApiUrl,
    params: customerUsername && {
      email: customerUsername.includes("---")
        ? customerUsername.split("---")[2]
        : customerUsername,
    },
    headers: { "x-api-key": cbtToken },
    validateStatus: (status) => {
      return (status >= 200 && status < 300) || status === 409;
    },
  });
}
