import { SearchQuery } from "shared/data/reducers/types/storage";

import { findAndRemoveKeyFromObj } from "../../lib/functions";
import { isMobile } from "../../lib/isMobile";
import getCbtClient, { cbtResponse } from "../cbtClient";

export async function resolvePoliciesGOL({
  searchFlightsExtendedResp,
  userIds,
  guests,
  travelReasonId,
  costCenterId,
  tripType,
  cbtToken,
  customerUsername,
  cbtApiUrl,
  guestsGradeIds,
}) {
  if (!cbtToken) return;

  const filteredResponse = findAndRemoveKeyFromObj(searchFlightsExtendedResp, [
    "References",
    "BrandServices",
  ]);

  // isMobile() for feature not finished in mobile app https://gitlab.in.cee-systems.com/gol/d4/-/issues/2261
  const payload = {
    UserIds: userIds,
    ...(isMobile() ? { Guests: guests } : {}),
    TravelReasonId: travelReasonId,
    ...(!isMobile() ? { GuestsGradeIds: guestsGradeIds } : {}),
    CostCenterId: costCenterId,
    TripType: tripType,
    GolApiResponse: filteredResponse,
  };

  return cbtResponse(
    getCbtClient({ cbtToken, customerUsername, cbtApiUrl }).post(
      `/policies/resolve-gol-api-v2`,
      payload
    )
  );
}

export function getTripType(query: SearchQuery) {
  if (query.flight_1_origin) return "MultiCity";
  if (query.returnDate && query.returnDate.length > 0) return "RoundTrip";
  return "OneWay";
}
