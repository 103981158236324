import { countPassengers } from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";

/* eslint-disable-next-line no-shadow */
enum PassengerErrorMessages {
  noPassenger,
  onlyChild,
  tooManyPassengerTypes,
  moreInfantsThanAdults,
}

export const isPassengersSelectError = (
  passengers: PassengersCount
): PassengerErrorMessages => {
  if (countPassengers(passengers) === 0) {
    return PassengerErrorMessages.noPassenger;
  }
  if (
    (passengers.INF || passengers.CHD) &&
    countPassengers(passengers) ===
      (passengers.INF ? Number(passengers.INF) : 0) +
        (passengers.CHD ? Number(passengers.CHD) : 0)
  ) {
    return PassengerErrorMessages.onlyChild;
  }

  if (passengers.INF !== undefined || passengers.CHD !== undefined) {
    const numINF = passengers.INF ? Number(passengers.INF) : 0;
    const numCHD = passengers.CHD ? Number(passengers.CHD) : 0;
    const numPassengers = countPassengers(passengers);

    if (numINF > numPassengers - numINF - numCHD) {
      return PassengerErrorMessages.moreInfantsThanAdults;
    }
  }

  return null;
};

export const setPassengersSelectErrorMessage = (
  passengers: PassengersCount,
  setErrorMessage?: (messageNumber: number) => void
): boolean => {
  const errorMessage = isPassengersSelectError(passengers);

  if (errorMessage === null) {
    return false;
  }

  if (typeof setErrorMessage !== "undefined") {
    setErrorMessage(errorMessage);
  }

  return true;
};
