import React from "react";

import { Icon } from "shared/types";

import { IconWrap } from "@components/UI/Icons/IconWrap";

const DARK_ORANGE = "#e85e20";

const CrossIcon: React.FC<Icon> = ({ ...props }) => {
  const adjustedColor =
    props.color === "dark-orange" ? DARK_ORANGE : props.color; // default is gray;
  const icon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M8.749,25.73,16.182,18.3,8.749,10.863a1.5,1.5,0,1,1,2.121-2.118L18.3,16.179l7.434-7.434a1.5,1.5,0,1,1,2.121,2.118L20.418,18.3l7.434,7.434a1.5,1.5,0,1,1-2.121,2.118L18.3,20.415l-7.434,7.434A1.5,1.5,0,0,1,8.746,25.73Z"
        transform="translate(-8.301 -8.297)"
        fill={adjustedColor}
      />
    </svg>
  );

  return <IconWrap IconComponent={icon} type="cross" {...props} />;
};

export default CrossIcon;
