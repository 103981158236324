import { isFalsy } from "./isFalsy";

export default function formatUrl(url: string): string {
  const urlWithoutTrailingSlash = url.replace(/\/+$/, "");
  const httpsUrl = `https://${urlWithoutTrailingSlash}`;

  const startsWithwww = urlWithoutTrailingSlash.startsWith("www");

  if (urlWithoutTrailingSlash.startsWith("/")) {
    return urlWithoutTrailingSlash;
  }

  // add http if url does not have http or https prefix
  if (!/^https?:\/\//i.test(urlWithoutTrailingSlash) || startsWithwww) {
    return httpsUrl;
  }

  if (!/^https?:\/\//i.test(urlWithoutTrailingSlash) && !startsWithwww) {
    return httpsUrl;
  }

  return urlWithoutTrailingSlash;
}

export function formatTripmanagerDeeplink({
  reservationId,
  surname,
}: {
  reservationId: string;
  surname: string;
}): { sourceUrl: string; restrictUrl: string } {
  if (isFalsy(reservationId) && isFalsy(surname)) {
    throw new Error("Cannot format URL from empty reservationId and surname");
  }

  if (!reservationId || typeof reservationId !== "string") {
    throw new Error("reservationId must be a non-empty string");
  }

  if (!surname || typeof surname !== "string") {
    throw new Error("surname must be a non-empty string");
  }

  const deepLinkURL = `https://www.tripmanager.com/deeplink?locator=${reservationId}&passenger_name=${surname}`;

  // Extract the hostname manually
  const urlParts = deepLinkURL.match(/^(https?:\/\/[^/]+)(.*)/);
  const restrictUrl = urlParts ? `${urlParts[1]}/` : "";

  return {
    sourceUrl: deepLinkURL,
    restrictUrl,
  };
}
