import cookie from "js-cookie";

export function setCookie({
  name,
  value,
  expiration,
}: {
  name: string;
  value: string;
  expiration?: number;
}) {
  let expires: boolean | Date = false;
  if (expiration) {
    expires = new Date();
    expires.setTime(expires.getTime() + expiration);
  }
  cookie.set(name, value, { expires, sameSite: "strict", secure: true });
}

export function getCookie(name: string): string | undefined {
  const value = cookie.get(name);

  if (value === "undefined") {
    return undefined;
  }
  return value;
}

export function deleteCookie(name: string) {
  cookie.remove(name);
}
