import React from "react";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";

import Img from "@components/FileServer/Img";

import { changeCurrency } from "./SetCurrency";

const DEFAULT_CURRENCY_ICON_SRC = "/static/images/ico-currency-na.svg";

type ConfigType = {
  currency: string | null;
  alternativeCurrencies: string[];
};

function SetCurrency() {
  const { currency, alternativeCurrencies }: ConfigType = useSelector(
    (state: AppState) => state.requestorConfig
  );

  return [...new Set([currency, ...alternativeCurrencies])].map((c) => (
    <a
      key={c}
      href="#"
      className="header-mobile-menu-link"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        changeCurrency(c, currency);
      }}
    >
      <Img
        className="header-menu-currency-option-ico"
        src={`/static/images/ico-currency-${c.toLowerCase()}.svg`}
        onError={(e) => {
          e.target.src = DEFAULT_CURRENCY_ICON_SRC;
        }}
      />
      {c.toUpperCase()}
    </a>
  ));
}

export default SetCurrency;
