import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

function getGolRequestImportReservationQuery(ReservationPartID: string) {
  return {
    GolApi: {
      RequestDetail: {
        ImportReservationRequest_1: {
          ReservationPartID: { $t: ReservationPartID },
        },
      },
    },
  };
}

export default async (reservationId: string) => {
  const golRequest = getGolRequestImportReservationQuery(reservationId);
  const importReservationResponse = await golApiCall(golRequest);
  return createResponse(importReservationResponse);
};
