import classNames from "classnames";

function RenderInputField({
  input,
  required,
  defaultMessage,
  placeholder,
  id,
  type,
  autoFocus,
  meta: { touched, error },
}) {
  return (
    <div
      className="custom-input-container"
      style={{
        marginBottom: touched && error ? "2px" : "17px",
      }}
    >
      <div
        className={classNames("custom-input-wrapper", {
          "input-wrapper-required": required,
          "input-wrapper-error": touched && error,
        })}
      >
        <span className="input-label">{defaultMessage}</span>
        <input
          {...input}
          type={type}
          className={classNames("input-input", {
            "custom-input-error": touched && error,
          })}
          placeholder={placeholder}
          id={id}
          autoFocus={autoFocus}
        />
      </div>
      {touched && error ? (
        <span className="custom-input-wrapper-error">{error}</span>
      ) : null}
    </div>
  );
}

export default RenderInputField;
