import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { createPortal } from "react-dom";
import useResizeObserver from "use-resize-observer";

import CrossIcon from "@components/UI/Icons/Cross";

let modalRoot: HTMLElement = null;
if (typeof window !== "undefined") {
  modalRoot = document.getElementById("modal");
}

interface IProps {
  children: React.ReactNode;
  isActive: boolean;
  className?: string;
  innerClassName?: string;
  onClose?: () => void;
  modalTitle?: string;
  modalTitleClassName?: string;
  setShowVerticalScrollBar?: Dispatch<SetStateAction<boolean>>;
  setShowHorizontalScrollBar?: Dispatch<SetStateAction<boolean>>;
  isCloseButtonHidden?: boolean;
  disableOutsideClickClose?: boolean;
}

const setShowScrollbars = (
  elements: { verticalElem: HTMLElement; horizontalElem: HTMLElement },
  setShowVerticalScrollBar: Dispatch<SetStateAction<boolean>>,
  setShowHorizontalScrollBar: Dispatch<SetStateAction<boolean>>
) => {
  const { verticalElem, horizontalElem } = elements;

  if (verticalElem.scrollHeight > verticalElem.offsetHeight) {
    setShowVerticalScrollBar(true);
  } else {
    setShowVerticalScrollBar(false);
  }

  if (horizontalElem.scrollWidth > verticalElem.offsetWidth) {
    setShowHorizontalScrollBar(true);
  } else {
    setShowHorizontalScrollBar(false);
  }
};

const Modal: React.FC<IProps> = ({
  isActive,
  className,
  innerClassName,
  onClose,
  modalTitle,
  modalTitleClassName,
  setShowVerticalScrollBar,
  setShowHorizontalScrollBar,
  children,
  isCloseButtonHidden,
  disableOutsideClickClose,
}) => {
  const modalInnerElement = React.useRef<HTMLDivElement>(null);
  const modalContentElement = React.useRef<HTMLDivElement>(null);

  const { width, height } = useResizeObserver<HTMLDivElement>({
    ref: modalInnerElement,
  });

  React.useEffect(() => {
    if (
      modalInnerElement.current instanceof HTMLElement &&
      modalContentElement.current instanceof HTMLElement &&
      setShowHorizontalScrollBar &&
      setShowVerticalScrollBar
    ) {
      setShowScrollbars(
        {
          verticalElem: modalInnerElement.current,
          horizontalElem: modalContentElement.current,
        },
        setShowVerticalScrollBar,
        setShowHorizontalScrollBar
      );
    }
  }, [width, height, setShowVerticalScrollBar, setShowHorizontalScrollBar]);

  React.useEffect(() => {
    document.body.style.overflow = isActive ? "hidden" : "scroll";

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" && onClose) {
        onClose();
      }
    };

    if (isActive) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.style.overflow = "scroll";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isActive, onClose]);

  const handleModalClick = (e: React.MouseEvent) => {
    const elBg = document.getElementsByClassName("modal-inner");

    const lastElement = elBg[modalRoot.childElementCount - 1];
    if (
      !onClose ||
      disableOutsideClickClose ||
      (e.target instanceof Node &&
        modalInnerElement.current.contains(e.target)) ||
      (modalRoot.childElementCount > 1 &&
        !modalInnerElement.current.isSameNode(lastElement))
    ) {
      return;
    }

    onClose();
  };

  if (!isActive || modalRoot === null) {
    return null;
  }

  return createPortal(
    <div
      role="button"
      className={`modal-root ${isActive ? "-active" : ""} ${className}`}
      onClick={handleModalClick}
    >
      <div className="modal-bg">
        <div
          className={`modal-inner ${innerClassName}`}
          ref={modalInnerElement}
        >
          {!isCloseButtonHidden && (
            <button
              className="modal-close-btn"
              onClick={() => {
                onClose();
              }}
              type="button"
            >
              <CrossIcon size="md" color="dark-gray" />
            </button>
          )}

          {modalTitle ? (
            <h2
              className={`modal-title ${
                typeof modalTitleClassName === "string"
                  ? `modal-title--${modalTitleClassName}`
                  : ""
              }`}
            >
              {modalTitle}
            </h2>
          ) : null}
          <div className="modal-content" ref={modalContentElement}>
            {children}
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;
