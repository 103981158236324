import classnames from "classnames";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import Select, { components } from "react-select";

import Img from "@components/FileServer/Img";
import FormattedMessage from "@components/UI/FormattedMessage";

export function FormControl(props) {
  const { value, error, touched } = props.selectProps.controlOptions || {};
  const { disabled } = props.selectProps;

  return (
    <components.Control
      {...props}
      className={classnames("custom-select-control", {
        "custom-select-control--filled": value,
        "custom-select-control--error": error,
        "custom-select-control--error-and-touched": error && touched,
        "custom-select-control--disabled": disabled,
      })}
    />
  );
}

interface Props {
  isClearable?: boolean;
  center?: boolean;
  right?: boolean;
  hideDropdown?: boolean;
  displayOnlyAfterMount?: boolean;
  testId?: string;
  instanceId?: string;
  control?: any;
  menu?: any;
  styles?: any;
  value: any;
  options: { label: string; value: string }[];
  defaultValue?: string;
  isSearchable?: boolean;
  clearable?: boolean;
  clearIndicator?: React.ReactElement;
  components?: any;
  onChange: any;
  className?: string;
  classNamePrefix?: string;
  autoComplete?: string;
  iframeView?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

interface State {
  show: boolean;
}

class SimpleSelect extends Component<Props, State> {
  state = { show: false };

  componentDidMount() {
    this.setState({ show: true });
  }

  DropdownIndicator = (props) => {
    if (!this.props.hideDropdown) {
      return (
        <components.DropdownIndicator {...props}>
          <Img src="/static/images/ico-arrow-down.svg" />
        </components.DropdownIndicator>
      );
    }
    return null;
  };

  Input = (props) => (
    <components.Input
      {...props}
      data-cy={this.props.testId}
      style={{ flexGrow: 1 }}
      autoComplete={this.props.autoComplete}
    />
  );

  SingleValue = (props) => {
    return (
      <div id={`${this.props.testId}-singleValue`}>
        <components.SingleValue {...props} />
      </div>
    );
  };

  Option = (props) => (
    <div data-testid={this.props.testId}>
      <components.Option
        className={classnames("simple-select-option", {
          "simple-select-option--selected":
            !props.isFocused && props.isSelected,
          "simple-select-option--focused": props.isFocused,
        })}
        {...props}
      />
    </div>
  );

  Menu = (props) => (
    <components.Menu
      {...props}
      className={classnames("select-menu-outer", {
        "filter-bar-airlines-iframe": this.props.iframeView,
      })}
    />
  );

  NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <div>
        <FormattedMessage id="SimpleSelect.noOptions" />
      </div>
    </components.NoOptionsMessage>
  );

  render() {
    const { defaultValue, center, right } = this.props;

    let justifyValueContainerContent = "flex-start";
    if (center) justifyValueContainerContent = "center";
    if (right) justifyValueContainerContent = "flex-end";

    return (
      <Select
        data-testid={this.props.testId}
        id={this.props.testId}
        instanceId={this.props.testId}
        placeholder={
          defaultValue && typeof defaultValue !== "object"
            ? defaultValue
            : this.props.placeholder
            ? this.props.placeholder
            : this.props.intl.formatMessage({ id: "Select.placeholder" })
        }
        isClearable={this.props.isClearable}
        isDisabled={this.props.disabled}
        className={classnames({
          "simple-select-beforemount": this.props.displayOnlyAfterMount,
          "simple-select-aftermount-appear":
            this.props.displayOnlyAfterMount && this.state.show,
          [this.props.testId]: this.props.testId,
          [this.props.customClass]: this.props.customClass,
        })}
        menuPortalTarget={
          typeof window !== "undefined"
            ? window.document.querySelector("body")
            : null
        }
        {...this.props}
        components={{
          DropdownIndicator: !this.props.hideDropdown
            ? this.DropdownIndicator
            : null,
          IndicatorSeparator: () => null,
          Input: this.Input,
          Option: this.Option,
          Menu: this.Menu,
          SingleValue: this.SingleValue,
          NoOptionsMessage: this.NoOptionsMessage,
          ...this.props.components,
        }}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) =>
            this.props.control
              ? this.props.control
              : {
                  ...base,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border: "none",
                  flexGrow: 1,
                  textAlign: "left",
                },
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            cursor: "pointer",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
          }),
          valueContainer: (base) => ({
            ...base,
            justifyContent: justifyValueContainerContent,
            paddingLeft: "11px",
            display: "flex",
            fontSize: 13,
            cursor: "pointer",
          }),
          placeholder: (base) => ({
            ...base,
            justifyContent: "left",
          }),
          menu: (base) =>
            this.props.menu
              ? this.props.menu
              : {
                  ...base,
                  animation: "fadeIn 0.2s ease",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  boxShadow: "0 0 13px 0 rgba(158, 160, 172, 0.1)",
                  marginTop: 0,
                  borderRadius: 0,
                  zIndex: 99999,
                },
          menuList: (base) => ({
            ...base,
            paddingTop: 0,
          }),
          input: (base) => ({ ...base }),
          ...this.props.styles,
        }}
        onChange={this.props.onChange}
      />
    );
  }
}

export default injectIntl(SimpleSelect) as any;
