import classnames from "classnames";
import moment from "moment";
import { useRouter } from "next/router";
import React from "react";

import DatePicker from "@components/SearchForm/Calendar/DatePicker";
import FormattedMessage from "@components/UI/FormattedMessage";

interface DatesRowProps {
  flightTypeSearch: string;
  departureDate: string;
  returnDate: string;
  setDate: (name: string, value: string) => void;
  blockSearchDaysBefore: number;
  blockSearchDaysAfter?: number;
}

const DISABLE_DATES_AFTER_DAYS = 361;

export default function DatesRow({
  flightTypeSearch,
  departureDate,
  returnDate,
  setDate,
  blockSearchDaysBefore,
  blockSearchDaysAfter,
}: DatesRowProps) {
  const router = useRouter();

  const isHotelSearchForm =
    router.query &&
    router.query.typeSearch &&
    router.query.typeSearch === "hotels";

  const isIframe = router.route === "/iframe";

  let disabledTillDate = moment().add(blockSearchDaysBefore, "days").toDate();
  let disabledAfterDate = moment()
    .add(blockSearchDaysAfter || DISABLE_DATES_AFTER_DAYS, "days")
    .toDate();

  if (isHotelSearchForm) {
    disabledTillDate = moment().add(1, "days").toDate();
    disabledAfterDate = blockSearchDaysAfter
      ? moment().add(blockSearchDaysAfter, "days").toDate()
      : undefined;
  }

  return (
    <div
      className={classnames(
        "header-search-form-desktop-line header-search-form-desktop-line--half",
        {
          iframe: isIframe,
        }
      )}
    >
      <div
        className={classnames("flex-1", {
          iframe: isIframe,
        })}
        id="departure_date-container"
      >
        <div
          className={classnames("header-search-form-inner-field", {
            iframe: isIframe,
          })}
        >
          <span className="header-search-form-inner-field-label">
            {isHotelSearchForm ? (
              <FormattedMessage id="SearchForm.hotels.checkInDate" />
            ) : (
              <span id="GOL_package-textStorage-SearchForm.departureDate">
                <FormattedMessage id="SearchForm.departureDate" />
              </span>
            )}
          </span>
          <DatePicker
            testId="departure_date"
            typeSearch="DEPARTURE"
            date={departureDate}
            onChange={setDate}
            disabledTillDate={disabledTillDate}
            disabledAfterDate={disabledAfterDate}
          />
        </div>
      </div>
      <div
        className={classnames("flex-1", {
          iframe: isIframe,
        })}
        id="return_date-container"
      >
        {(flightTypeSearch === "RETURN" || isHotelSearchForm) && (
          <div
            className={classnames("header-search-form-inner-field", {
              iframe: isIframe,
            })}
          >
            <span className="header-search-form-inner-field-label">
              {isHotelSearchForm ? (
                <FormattedMessage id="SearchForm.hotels.checkOutDate" />
              ) : (
                <span id="GOL_package-textStorage-SearchForm.returnDate">
                  <FormattedMessage id="SearchForm.returnDate" />
                </span>
              )}
            </span>
            <DatePicker
              testId="return_date"
              typeSearch="RETURN"
              date={returnDate}
              onChange={setDate}
              disabledTillDate={
                departureDate ? new Date(departureDate) : disabledTillDate
              }
              disabledAfterDate={disabledAfterDate}
            />
          </div>
        )}
      </div>
      <div
        className={classnames("", {
          iframe: isIframe,
        })}
      />
    </div>
  );
}
