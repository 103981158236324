import { searchFlightBase } from "../../lib/constants";
import { getBasicDate } from "../../lib/functions";
import * as actionTypes from "../actions/actionTypes";

const INITIAL_DEPARTURE_DATE_DAYS_TO_ADD = 1;
const INITIAL_RETURN_DATE_DAYS_TO_ADD = 8;

const initState = {
  // searchForm mobile
  typeSearch: "RETURN",
  from: "",
  to: "",
  flightClass: "ECO", // FlightClasses
  departureDate: getBasicDate(INITIAL_DEPARTURE_DATE_DAYS_TO_ADD),
  returnDate: getBasicDate(INITIAL_RETURN_DATE_DAYS_TO_ADD),
  ADT: 1,
  INF: 0,
  CHD: 0,
  YTH: 0,
  YCD: 0,
  searchFormAirports: {},
  maxTransfers: null,
  preferredAirline: "all",
  searchFlights: [
    {
      ...searchFlightBase,
      departureDate: getBasicDate(7),
      returnDate: getBasicDate(7 + 7),
      origin: "",
      destination: "",
    },
  ],

  // mobile filters (web filters are in url)
  query: {
    airports_from: false,
    airports_transfers: false,
    airports_to: false,
    sort: "PRICE",
  },

  // results web + mobile
  // new
  oFlightOffers: {},
  aFlightOffers: [], // array of all links to object in oFLightOffers
  actualFlightServices: [],
  mobileOffersSortedFiltered: [], // with applied filters
  flights: [], // old
  oTransportCompanies: [],
  airports: [],
  maxNumTransfers: null,
  airportsFrom: [],
  airportsTo: [],
  airportsTransfers: [],
  highestPrice: 999999999,
  lowestPrice: 0,
  highestAlternativeCurrencyPrice: 999999999,
  lowestAlternativeCurrencyPrice: 0,
  toggleServiceInfo: false,
};

const flightSearchSuccess = (state, action) => {
  return { ...state, ...action.data, loading: false };
};

const flightSearchFail = (state) => {
  return { ...state, flights: [], loading: false };
};

const searchSetTransportCompanies = (state, action) => {
  return {
    ...state,
    oTransportCompanies: { ...state.oTransportCompanies, ...action.data },
  };
};

const searchSetSearchFlights = (state, action) => {
  return {
    ...state,
    searchFlights: { ...state.searchFlights, ...action.data },
  };
};

const searchAddSearchFlight = (state, action) => {
  return {
    ...state,
    searchFlights: state.searchFlights.concat(action.data),
  };
};

const searchRemoveSearchFlight = (state, action) => {
  return {
    ...state,
    searchFlights: state.searchFlights.filter((o, i) => i !== action.data.id),
  };
};

const searchUpdateSearchFlightOrigin = (state, action) => {
  return {
    ...state,
    searchFlights: state.searchFlights.map((searchFlight, i) =>
      i === action.data.id
        ? { ...searchFlight, origin: action.data.payload }
        : searchFlight
    ),
  };
};

const searchUpdateSearchFlightDestination = (state, action) => {
  return {
    ...state,
    searchFlights: state.searchFlights.map((searchFlight, i) =>
      i === action.data.id
        ? { ...searchFlight, destination: action.data.payload }
        : searchFlight
    ),
  };
};

const searchUpdateSearchFlightDepartureDate = (state, action) => {
  return {
    ...state,
    searchFlights: state.searchFlights.map((searchFlight, i) =>
      i === action.data.id
        ? { ...searchFlight, departureDate: action.data.payload }
        : searchFlight
    ),
  };
};

const searchUpdateSearchFlightReturnDate = (state, action) => {
  return {
    ...state,
    searchFlights: state.searchFlights.map((searchFlight, i) =>
      i === action.data.id
        ? { ...searchFlight, returnDate: action.data.payload }
        : searchFlight
    ),
  };
};

const searchSetAirports = (state, action) => {
  return { ...state, airports: { ...state.airports, ...action.data } };
};

const searchSetPolicies = (state, action) => {
  return {
    ...state,
    travelPoliciesResults: { ...state.travelPoliciesResults, ...action.data },
  };
};

const searchSetAirplanes = (state, action) => {
  return { ...state, airplanes: { ...state.airplanes, ...action.data } };
};

const searchSetAirportFilters = (state, action) => {
  return {
    ...state,
    maxNumTransfers: action.data.maxNumTransfers,
    airportsFrom: [...action.data.airportsFrom],
    airportsTo: [...action.data.airportsTo],
    airportsTransfers: [...action.data.airportsTransfers],
    highestPrice: action.data.highestPrice,
    lowestPrice: action.data.lowestPrice,
  };
};

const setActualFlightServices = (state, action) => {
  return {
    ...state,
    actualFlightServices: action.data,
  };
};

const deleteActualFlightServices = (state) => {
  return {
    ...state,
    actualFlightServices: [],
  };
};

const toggleServiceInfo = (state) => {
  return { ...state, toggleServiceInfo: !state.toggleServiceInfo };
};

const flightSearchAddAirports = (state, action) => {
  return {
    ...state,
    searchFormAirports: { ...state.searchFormAirports, ...action.data.value },
  };
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_SET:
      return { ...state, [action.data.name]: action.data.value };
    case actionTypes.FLIGHT_SEARCH_SUCCESS:
      return flightSearchSuccess(state, action);
    case actionTypes.SEARCH_SET_FORM_AIRPORTS:
      return flightSearchAddAirports(state, action);
    case actionTypes.FLIGHT_SEARCH_FAIL:
      return flightSearchFail(state);
    case actionTypes.SEARCH_SET_TRANSPORT_COMPANIES:
      return searchSetTransportCompanies(state, action);
    case actionTypes.SEARCH_SET_SEARCH_FLIGHTS:
      return searchSetSearchFlights(state, action);
    case actionTypes.SEARCH_ADD_SEARCH_FLIGHT:
      return searchAddSearchFlight(state, action);
    case actionTypes.SEARCH_REMOVE_SEARCH_FLIGHT:
      return searchRemoveSearchFlight(state, action);
    case actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_ORIGIN:
      return searchUpdateSearchFlightOrigin(state, action);
    case actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_DESTINATION:
      return searchUpdateSearchFlightDestination(state, action);
    case actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_DEPARTURE_DATE:
      return searchUpdateSearchFlightDepartureDate(state, action);
    case actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_RETURN_DATE:
      return searchUpdateSearchFlightReturnDate(state, action);
    case actionTypes.SEARCH_SET_AIRPORTS:
      return searchSetAirports(state, action);
    case actionTypes.SEARCH_SET_ACTUAL_FLIGHT_SERVICES:
      return setActualFlightServices(state, action);
    case actionTypes.DELETE_ACTUAL_FLIGHT_SERVICES:
      return deleteActualFlightServices(state);
    case actionTypes.SEARCH_SET_AIRPLANES:
      return searchSetAirplanes(state, action);
    case actionTypes.SEARCH_SET_POLICIES:
      return searchSetPolicies(state, action);
    case actionTypes.SEARCH_SET_FILTERS_DATA:
      return searchSetAirportFilters(state, action);
    case actionTypes.FLIGHT_TOGGLE_SERVICE_INFO:
      return toggleServiceInfo(state);
    default:
      return state;
  }
};

export default reducer;
