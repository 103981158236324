import { SingletonRouter, withRouter } from "next/router";
import { Line } from "rc-progress";
import React, { useEffect, useState } from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";

import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import pageWithIntl from "@components/PageWithIntl";
import FormattedMessage from "@components/UI/FormattedMessage";

import { ROUTES } from "@shared/constants";

import PageSeo from "../Utils/PageSeo";
import Spinner from "./Spinner";

const SEARCH_DURATION_SECONDS = 30;
const CHANGE_TEXT_MILISECONDS = 200;
const CHANGE_LINE_MILISECONDS = 1000;
const TEXTS_SEARCH_PLACEHOLDER = [
  "Air Canada",
  "Air China",
  "Air France",
  "Air New Zeland",
  "Air One",
  "Air Onix",
  "Alitalia",
  "Asiana Airlines",
  "Austrian",
  "American Airlines",
  "BMI",
  "British Airways",
  "Brussels Airlines",
  "Bulgaria Air",
  "Air Canada",
  "Continental Airlines",
  "Croatia Airlines",
  "Cyprus Airways",
  "Czech Airlines",
  "Delta Air Lines",
  "Emirates",
  "Finnair",
  "Iberia",
  "Japan Airlines",
  "Jat Airways",
  "KLM Royal Dutch Airlines",
  "LOT Polish Airlines",
  "Lufthansa",
  "Macedonian Airlines",
  "Moldavian Airlines",
  "Montenegro Airlines",
  "Niki",
  "Olympic Air",
  "Oman Air",
  "Qantas Airways",
  "Qatar Airways",
  "SAS Scandinavian Airlines",
  "Singapore Airlines",
  "Smart Wings",
  "Swiss",
  "TAP Portugal",
  "Tunisair",
  "Turkish Airlines",
  "Ukraine Intl Airlines",
  "United Airlines",
  "Virgin Atlantic",
  "Vietnam Airlines",
];

const { headerTitle } = defineMessages({
  headerTitle: formattedMessageParams("Loader.headerTitle"),
});

interface Props {
  universalLoading: boolean;
  searchLoading: boolean;
  router: SingletonRouter;
  intl: IntlShape;
  waitPageImageUrl: string;
}

function Loader({
  universalLoading,
  searchLoading,
  router,
  intl,
  waitPageImageUrl,
}: Props) {
  const [percent, setPercent] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [destinationImage, setDestinationImage] = useState<string>(null);

  const destination = router.query.to
    ? router.query.to
    : router.query.flight_1_destination;

  const clearIntervals = (textIndexInterval, percentInterval) => {
    clearInterval(textIndexInterval);
    clearInterval(percentInterval);
  };

  useEffect(() => {
    if (searchLoading) {
      const textIndexInterval = setInterval(() => {
        setTextIndex((prevState) =>
          prevState < TEXTS_SEARCH_PLACEHOLDER.length - 1 ? prevState + 1 : 0
        );
      }, CHANGE_TEXT_MILISECONDS);
      const percentInterval = setInterval(() => {
        setPercent((prevState) => {
          return prevState < 100 - 100 / SEARCH_DURATION_SECONDS
            ? prevState + 100 / SEARCH_DURATION_SECONDS
            : 100 - 100 / SEARCH_DURATION_SECONDS;
        });
      }, CHANGE_LINE_MILISECONDS);
      return () => clearIntervals(textIndexInterval, percentInterval);
    }
  }, [searchLoading]);

  useEffect(() => {
    const fetchBackgroundImage = async () => {
      setDestinationImage(null);
      const res = await fetch(
        waitPageImageUrl.replace(
          "{code}",
          destination.toLowerCase().replace("+", "")
        )
      );
      document.body.style.overflow = "hidden";
      if (res.status === 200) {
        return setDestinationImage(res.url);
      }
    };
    if (destination && waitPageImageUrl) {
      fetchBackgroundImage();
    }
  }, [destination, waitPageImageUrl]);

  const isHotels = router.pathname === ROUTES.HOTELS;

  return (
    <div>
      {universalLoading !== false && <Spinner />}
      {searchLoading !== false && (
        <>
          <PageSeo
            title={{
              defaultTitle: intl.formatMessage(headerTitle),
            }}
          />

          {destinationImage && (
            <Img
              loading="lazy"
              className="loader-background"
              src={destinationImage}
              alt="landing plane icon"
            />
          )}

          <div
            className={`loader-wrapper ${
              destinationImage ? "" : "no-background-image"
            }`}
          >
            <div className="loader">
              <div className="loader-top-image">
                <Img
                  src="/static/images/ico-loading.svg"
                  alt="landing plane icon"
                />
              </div>

              <div>
                <h3 className="h3 h3-loader">
                  {isHotels ? (
                    <FormattedMessage id="Loader.titleHotels" />
                  ) : (
                    <FormattedMessage id="Loader.title" />
                  )}
                </h3>
                <Line
                  className="loader-line"
                  percent={percent}
                  strokeWidth={3}
                  trailWidth={2}
                />
                <div className="loader-placeholder">
                  ...
                  {!isHotels && TEXTS_SEARCH_PLACEHOLDER[textIndex]}
                </div>
                <br />
                <br />
                <span>
                  <FormattedMessage id="Loader.wait" />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  waitPageImageUrl: state.requestorConfig.waitPageImageUrl,
  searchLoading: state.loading.searchLoader,
  universalLoading: state.loading.universalLoader,
  fileServerData: state.requestorConfig.fileServerData,
  textStorage: state.requestorConfig.textStorage,
});

export default connect(mapStateToProps)(withRouter(pageWithIntl(Loader)));
