import React, { Component } from "react";
import { IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";

const areIntlLocalesSupported = require("intl-locales-supported");
// Register React Intl's locale data for the user's locale in the browser. This
// locale data was added to the page by `pages/_document.js`. This only happens
// once, on initial page load in the browser.
if (typeof window !== "undefined" && window.ReactIntlLocaleData) {
  Object.keys(window.ReactIntlLocaleData).forEach((lang) => {
    if (
      !areIntlLocalesSupported(window.ReactIntlLocaleData[lang]) &&
      window.ReactIntlLocaleData[lang]
    ) {
      require("@formatjs/intl-pluralrules/polyfill");
      // eslint-disable-next-line security/detect-non-literal-require -- added addtional checks above
      require(`@formatjs/intl-pluralrules/dist/locale-data/${window.ReactIntlLocaleData[lang]}`);
    }
  });
}

const PageWithIntl = (Page) => {
  const IntlPage = injectIntl(Page);

  class PageWithIntl_ extends Component {
    static async getInitialProps(context) {
      let props;
      if (typeof Page.getInitialProps === "function") {
        props = await Page.getInitialProps(context);
      }

      // Get the `locale` and `messages` from the request object on the server.
      // In the browser, use the same values that the server serialized.
      const { req } = context;
      const { locale, messages } =
        req ||
        (typeof window !== "undefined" && window.__NEXT_DATA__.props.pageProps);

      // Always update the current time on page load/transition because the
      // <IntlProvider> will be a new instance even with pushState routing.
      const now = Date.now();

      return { ...props, locale, messages, now };
    }

    render() {
      const { locale, messages, now, ...props } = this.props;

      return (
        <IntlProvider
          locale="cs"
          messages={this.props.textStorage ? this.props.textStorage : messages} //  ||
          initialNow={now}
        >
          <IntlPage {...props} />
        </IntlProvider>
      );
    }
  }

  PageWithIntl_.getLayout = Page.getLayout;

  return connect(mapStateToProps)(PageWithIntl_);
};

const mapStateToProps = (state) => ({
  fileServerData: state.requestorConfig.fileServerData,
  textStorage: state.requestorConfig.textStorage,
  currentLanguage: state.requestorConfig.currentLanguage,
});

export default PageWithIntl;
