import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ROUTES } from "shared/constants";
import { loadWebRequestorDetails } from "shared/data/actions/storage";
import { COOKIE_REDIRECT_AFTER_LOGIN } from "shared/data/cookies";
import { AppState } from "shared/data/reducers";
import golApi from "shared/gol-api";
import { useLogin } from "shared/hooks/login";
import { getGolApiUrl } from "shared/lib/golCopy";

import Img from "@components/FileServer/Img";
import SsoButton from "@components/Login/SsoButton";
import pageWithIntl from "@components/PageWithIntl";
import { generateRandomString } from "@components/Register/Form";
import Alert from "@components/UI/Alert";
import FormattedMessage from "@components/UI/FormattedMessage";
import Link from "@components/UI/Link";
import Spinner from "@components/UI/Spinner";
import useModalAlert from "@components/UI/useModalAlert";

import redirectAfterLogin from "@lib/redirectAfterLogin";

const Form = () => {
  const {
    registrationEnabled,
    cbtEnabled,
    namespaceSwitchingEnabled,
    passwordCustomerLoginAllowed,
    ssoIdentityProvider,
  } = useSelector((state: AppState) => ({
    fileServerData: state.requestorConfig.fileServerData,
    textStorage: state.requestorConfig.textStorage,
    registrationEnabled:
      state.storage.frontendSettings?.dealerCorporateSettings
        ?.enableRegistration === "true",
    cbtEnabled:
      state.storage.frontendSettings?.dealerCorporateSettings?.enableCbt ===
      "true",
    namespaceSwitchingEnabled:
      state.storage.frontendSettings?.dealerCorporateSettings
        ?.enableNamespaceSwitching === "true",
    passwordCustomerLoginAllowed:
      state.requestorConfig.passwordCustomerLoginAllowed,
    ssoIdentityProvider: state.requestorConfig.ssoIdentityProvider,
  }));

  const [loginStatus, submitLogin] = useLogin();
  const { isLoading, isFailed } = loginStatus;
  const [confirmationError, setConfirmationError] = useState(null);

  const intl = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const namespaceRef = useRef(null);

  const [ModalAlertError, showError] = useModalAlert({
    hideHeader: true,
  });

  const [ModalAlertSuccess] = useModalAlert({
    hideHeader: true,
  });

  const isSsoEnabled = ssoIdentityProvider && ssoIdentityProvider.length > 0;
  const isSsoOnlyLogin = isSsoEnabled && !passwordCustomerLoginAllowed;
  const isBasicAuthOnlyLogin = !isSsoEnabled;
  const isSsoAndBasicAuthLogin = isSsoEnabled && passwordCustomerLoginAllowed;

  const loginDescriptionSubtitleId = isSsoOnlyLogin
    ? "Login.descriptionSubtitle.sso"
    : isBasicAuthOnlyLogin
    ? "Login.descriptionSubtitle.basicAuth"
    : "Login.descriptionSubtitle.ssoAndBasicAuth";

  const confirmEmailProcess = ({ errorMessage }) => {
    setConfirmationError(errorMessage);
  };

  const sendLoginForm = async (e) => {
    e.preventDefault();
    await submitLogin({
      username: usernameRef?.current?.value,
      password: passwordRef?.current?.value,
      namespace: namespaceRef?.current?.value,
      afterLoginCallback: () => redirectAfterLogin(router),
      confirmEmailProcess,
    });
    await dispatch(
      loadWebRequestorDetails({
        selectedLanguage: router.query.lang,
        golApiUrl: getGolApiUrl(),
      })
    );
  };
  useEffect(() => {
    async function prepareSsoLogin() {
      if (router.query.redirectAfterLogin)
        sessionStorage.setItem(
          COOKIE_REDIRECT_AFTER_LOGIN,
          router.query.redirectAfterLogin
        );
      if (router.query.saml) {
        const redirectAfterLoginSSO =
          sessionStorage.getItem(COOKIE_REDIRECT_AFTER_LOGIN) ||
          `/${router.query.lang ? `?lang=${router.query.lang}` : ""}`;
        await submitLogin({
          identityProviderId: router.query.id,
          identityProviderData: decodeURIComponent(router.query.saml),
          afterLoginCallback: () => {
            window.location.href = redirectAfterLoginSSO;
          },
        });
      }
    }

    prepareSsoLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {router.query.saml && !isFailed && <Spinner />}
      <div className="login-form">
        {registrationEnabled && (
          <div className="header-search-form-tabs">
            <div className="header-search-form-tab header-search-form-tab--active">
              <span className="header-search-form-tab-text">
                <FormattedMessage id="Login.alreadyHaveAccount" />
              </span>
            </div>

            <Link
              href={{
                pathname: ROUTES.REGISTER,
                query: {
                  ...router.query,
                  redirectAfterLogin: router.query.redirectAfterLogin,
                  isLoginRequired: router.query?.isLoginRequired ?? false,
                },
              }}
            >
              <div className="header-search-form-tab">
                <a className="login-register-link">
                  <span className="header-search-form-tab-text">
                    <FormattedMessage id="Login.needAccount" />
                  </span>
                </a>
              </div>
            </Link>
          </div>
        )}
        <div className="login-form-inner">
          <h1 className="login-title">
            <FormattedMessage id="Login.description-title" />
          </h1>
          <p className="login-description">
            <FormattedMessage id={loginDescriptionSubtitleId} />
          </p>
          {ssoIdentityProvider.map((provider, idx) => (
            <SsoButton provider={provider} url="/" key={idx} />
          ))}
          {(isBasicAuthOnlyLogin || isSsoAndBasicAuthLogin) && (
            <>
              <form onSubmit={sendLoginForm} method="post">
                <input
                  type="hidden"
                  name="SecurityContent"
                  defaultValue={generateRandomString()}
                />
                <div className="input-wrapper ">
                  <span className="input-label" style={{ width: 160 }}>
                    <FormattedMessage id="Form.username" />
                  </span>
                  <input
                    id="input-login"
                    autoFocus
                    type="text"
                    className="input-input"
                    ref={usernameRef}
                    required
                  />
                </div>
                <div className="input-wrapper ">
                  <span className="input-label" style={{ width: 160 }}>
                    <FormattedMessage id="Form.password" />
                  </span>
                  <input
                    id="input-password"
                    type="password"
                    className="input-input"
                    ref={passwordRef}
                    required
                  />
                </div>
                {cbtEnabled && namespaceSwitchingEnabled && (
                  <div className="input-wrapper ">
                    <span className="input-label" style={{ width: 160 }}>
                      <FormattedMessage id="Form.company" />
                    </span>
                    <input
                      id="input-namespace"
                      type="text"
                      className="input-input"
                      ref={namespaceRef}
                    />
                  </div>
                )}
                {isFailed && (
                  <div className="input-wrapper input-wrapper-required">
                    <div className="input-label" />
                    <div className="login-submit-row">
                      <div className="login-error-warning">
                        <Alert type="warning">
                          <FormattedMessage id="Alert.wrongCredentials" />
                        </Alert>
                      </div>
                    </div>
                  </div>
                )}
                {confirmationError && (
                  <div className="input-wrapper input-wrapper-required">
                    <div className="input-label" />
                    <div className="login-submit-row">
                      <div className="login-error-warning">
                        <Alert type="warning">
                          <div>
                            <span>{confirmationError}</span>{" "}
                            <span
                              role="button"
                              id="resendEmail"
                              className="fake-link"
                              onClick={async () => {
                                const confirmEmailResp = await golApi.confirmCustomerEmail(
                                  usernameRef?.current?.value,
                                  true
                                );

                                if (confirmEmailResp?.success) {
                                  showError(
                                    intl.formatMessage({
                                      id: "EmailConfirmation.sendAgainSuccess",
                                    })
                                  );
                                } else {
                                  showError(
                                    intl.formatMessage({
                                      id: "EmailConfirmation.sendAgainError",
                                    })
                                  );
                                }

                                setConfirmationError(null);
                              }}
                            >
                              <FormattedMessage id="Form.resendEmail" />
                            </span>
                          </div>
                        </Alert>
                      </div>
                    </div>
                  </div>
                )}

                <div className="input-wrapper input-wrapper-required">
                  <div className="input-label" />
                  <div className="login-submit-row">
                    <button
                      type="submit"
                      id="login-button"
                      className="button-special-offers-offer button-secondary-color modal-submit-button modal-submit-login-button login-submit-button"
                      disabled={isLoading}
                    >
                      {!isLoading ? (
                        <FormattedMessage id="Screen.Login.title" />
                      ) : (
                        <FormattedMessage id="Login.loginButtonProgress" />
                      )}
                    </button>
                    <Link
                      href={{
                        pathname: "/forgottenPassword",
                        query: router.query.lang
                          ? { lang: router.query.lang }
                          : {},
                      }}
                    >
                      <span
                        id="forgotten-password-action-button"
                        className="link pointer login-link-forgotten-password"
                        role="button"
                      >
                        <FormattedMessage id="Auth.forgottenPassword" />
                      </span>
                    </Link>
                  </div>
                </div>
              </form>
            </>
          )}
          <div className="login-partners">
            <Img src="/static/images/cee-logo.png" alt="CEE Systems" />
            <Img src="/static/images/logo-travelport.png" alt="Travelport" />
            <Img src="/static/images/logo.png" alt="Logo" />
          </div>
        </div>
        <ModalAlertError />
        <ModalAlertSuccess />
      </div>
    </>
  );
};

export default pageWithIntl(Form);
