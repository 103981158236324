import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

type PriceReservation = { ReservationId: string; Price: string | number };

function getGolRequestPaymentParametersQuery(
  priceReservations: PriceReservation[]
) {
  const golRequest = {
    GolApi: {
      RequestDetail: {
        PaymentParamsRequest_1: {
          PaidReservations: {
            PriceReservation: priceReservations,
          },
        },
      },
    },
  };
  return golRequest;
}

export default async (priceReservations: PriceReservation[]) => {
  const golRequest = getGolRequestPaymentParametersQuery(priceReservations);

  const response = await golApiCall(golRequest);
  return createResponse(response);
};
