import React from "react";
import { connect } from "react-redux";

import { AppState } from "shared/data/reducers";
import Logger from "shared/services/Logger";

type Props = {
  fileServerData: any;
  src: string;
  className?: string;
  style?: any;
  id?: string;
  onClick?: () => void;
  onError?: (any) => void;
  alt?: string;
  title?: string;
  role?: string;
  width?: number;
  height?: number;
  loading?: string;
};

const Img = ({
  fileServerData,
  src,
  className,
  id,
  style,
  onClick,
  onError,
  alt,
  title,
  role,
  width,
  height,
  loading,
}: Props) => {
  let imgAddress = src;

  if (fileServerData) {
    const imgFileServerName = `images/${
      src.split("/")[src.split("/").length - 1]
    }`;

    if (fileServerData[imgFileServerName]) {
      imgAddress = fileServerData[imgFileServerName];
    } else {
      Logger.info("Image ", src, " not found on fileserver. Serving default.");
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      loading={loading || "auto"}
      src={imgAddress || src}
      className={className || ""}
      {...(id && { id })}
      style={style || {}}
      onClick={onClick}
      onError={onError}
      alt={alt}
      title={title || ""}
      role={role}
      width={width}
      height={height}
    />
  );
};

export default connect((state: AppState) => ({
  fileServerData: state.requestorConfig.fileServerData,
}))(Img);
