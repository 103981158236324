import golApiCall from "./golApiCall";

function getGolRequestAnonymousTokenQuery({ deviceId, deviceName }) {
  const golRequest = {
    GolApi: {
      RequestDetail: {
        CreateCustomerTokenRequest_1: {
          Device: {
            Id: deviceId || generateRandomNumber(1000, 999999),
            $t: deviceName || generateRandomNumber(1000, 999999),
          },
        },
      },
    },
  };
  return golRequest;
}

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default async ({ deviceId, deviceName }) => {
  const golRequest = getGolRequestAnonymousTokenQuery({ deviceId, deviceName });
  const clientTokenResponse = await golApiCall(golRequest);
  return clientTokenResponse;
};
