import axios from "axios";
import moment from "moment";
import nextCookie from "next-cookies";
import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";

import { listTravellers } from "shared/cbt/api/travellers";
import { getEnvironmentFromRequestorClientId } from "shared/config/utils";
import { usersFetched } from "shared/data/actions/cbt";
import { setRequestorConfig } from "shared/data/actions/requestorConfig";
import {
  getGOLAPIUrl,
  loadAirlines,
  loadCountries,
  loadWebRequestorDetails,
} from "shared/data/actions/storage";
import { logoutUser, setUserValues } from "shared/data/actions/user";
import {
  getCbtToken,
  logoutCustomer,
  setCustomerDevice,
  setCustomerToken,
} from "shared/data/customerTokens";
import detailCustomer2 from "shared/gol-api/detailCustomer2";
import { getRequestorClientId } from "shared/lib/functions";
import { POSSIBLE_LANGUAGES } from "shared/lib/languages";
import { getInitialClientId, setLocale } from "shared/lib/requestorFunctions";
// @ts-ignore
import Logger from "shared/services/Logger";
// @ts-ignore
import { getDomainForFileServer } from "shared/services/requestorConfiguration";

import LoginForm from "@components/Login/Form";
import ErrorLayout from "@components/UI/Layouts/ErrorLayout";
import StandardLayout from "@components/UI/Layouts/StandardLayout";

import { pageDataLayer } from "@lib/dataLayers";
import withReduxStore from "@lib/with-redux-store";
import * as Sentry from "@sentry/browser";
import { ROUTES } from "@shared/constants";

import "../styles/main.scss";

const config = require("shared/config.json");
const { generateRandomString } = require("../lib/serverHelpers.ts");

// const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const DEFAULT_LANG_GLOBAL = "cs";

const errorRoutes = ["/404", "/_error"];

Sentry.init({
  dsn: config.sentryUrl,
  environment: getEnvironmentFromRequestorClientId().label,
  ignoreErrors: ["_abt/*init*/(arg[0]='isArray')"],
});

interface IPageProps {
  domain?: string;
  fileServerPagesToDownload?: string[];
  gtm?: {
    gtmId?: any;
    nonce?: string;
  };
  downloadedPages?: Record<string, any>;
}

interface IAppProps {
  reduxStore: Store;
  pageProps: IPageProps;
  randomString: string;
}

class MyApp extends App<IAppProps> {
  static async getInitialProps(props) {
    const {
      Component,
      ctx,
      ctx: { reduxStore },
    } = props;
    let pageProps: IPageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
      let lang = reduxStore.getState().requestorConfig.currentLanguage; // default
      setLocale(lang);
      let { domain } = pageProps;

      const requestorClientId = getRequestorClientId(ctx);
      const nonce = ctx?.res?.locals?.nonce;

      const { fileServerPagesToDownload = [] } = pageProps;

      /*
      Server side
       */
      if (ctx.req) {
        let menuItemsLang = DEFAULT_LANG_GLOBAL;
        let isDefaultLang = true;
        if (
          ctx.req?.query?.lang !== undefined &&
          POSSIBLE_LANGUAGES.includes(ctx.req.query.lang)
        ) {
          setLocale(ctx.req.query.lang);
          lang = ctx.req.query.lang;
          menuItemsLang = ctx.req.query.lang;
          isDefaultLang = false;
        }

        await reduxStore.dispatch(
          getWebRequestorDetails({
            menuLanguage: menuItemsLang,
            isDefaultLanguage: isDefaultLang,
            ctx,
          })
        );

        /*
        If isDefaultLang is true language is set based on first returned
        value from DetailRequestor SupportedLanguage attribute
         */
        if (isDefaultLang) {
          lang = reduxStore.getState().requestorConfig.currentLanguage;
          setLocale(lang);
        }

        const restrictAirlines = reduxStore.getState().storage?.frontendSettings
          ?.carrierFilter;

        await Promise.all([
          reduxStore.dispatch(loadCountries(ctx.req)),
          reduxStore.dispatch(loadAirlines(ctx.req, restrictAirlines)),
        ]);

        // check logged
        const customerToken = getCustomerToken(ctx);
        const customerDevice = getCustomerDevice(ctx);

        if (customerToken && customerDevice) {
          await setCustomerToken(customerToken);
          await setCustomerDevice(customerDevice);

          const clientId = getInitialClientId(ctx.req.hostname);

          const customerDetail = await detailCustomer2({
            customerToken,
            requestorPublicKey: nextCookie(ctx)?.d4requestorPublicKey,
            clientId,
          });

          if (customerDetail.success) {
            await reduxStore.dispatch(
              setUserValues({
                isLoggedIn: true,
                ...customerDetail.data.formattedData,
              })
            );
          } else {
            await logoutCustomer();
            await reduxStore.dispatch(logoutUser());
          }
        } else if (
          reduxStore.getState().storage.frontendSettings.dealerCorporateSettings
            ?.enableAnonymousSearch === "false"
        ) {
          if (!ctx.req?.originalUrl?.includes(ROUTES.IFRAME)) {
            if (typeof window !== "undefined") {
              window.location = "/";
            } else if (ctx.req?.originalUrl?.includes(ROUTES.BOOKED)) {
              ctx.res.redirect("/");
            }
          }
        }
      }

      if (!domain && process.browser) {
        domain = window.location.hostname;
      }

      pageProps.gtm = {
        nonce,
      };

      const fileServerData = await loadStorageData({
        domain,
        lang,
        reduxStore,
      });

      const footerHTML = reduxStore.getState().requestorConfig?.footerInnerHTML;

      if (!footerHTML) {
        fileServerPagesToDownload.push("SF_STANDART_FOOTER");
      }
      const downloadedPages = await downloadPages(
        fileServerPagesToDownload,
        fileServerData
      );
      if (downloadedPages) {
        pageProps.downloadedPages = downloadedPages;
        if (downloadedPages.SF_STANDART_FOOTER) {
          reduxStore.dispatch(
            setRequestorConfig("footerInnerHTML", {
              html: downloadedPages.SF_STANDART_FOOTER,
              nonce,
              clientId: requestorClientId,
            })
          );
        }
      }
    }

    const randomString = generateRandomString();

    return { pageProps, randomString };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      // @ts-ignore
      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  prepareCbtUsers = async ({ reduxStore }) => {
    const cbtToken = await getCbtToken();

    const { frontendSettings } = reduxStore.getState().storage;

    const allUsers = await listTravellers({
      cbtToken,
      customerUsername: reduxStore.getState().user.email,
      cbtApiUrl: frontendSettings.dealerCorporateSettings?.cbtApiUrl,
      limit: 1000,
    });

    if (allUsers?.success) {
      const enhancedUsers = allUsers.data.data.map((user) => ({
        ...user,
        value: user?.Id,
        label: `${user?.FirstName} ${user?.LastName}`,
      }));

      await reduxStore.dispatch(
        usersFetched({
          success: allUsers.success,
          data: { data: enhancedUsers },
        })
      );
    }
  };

  componentDidMount() {
    const { reduxStore, router } = this.props;

    const lang = reduxStore.getState().requestorConfig.currentLanguage;

    const transformedLang = this.transformAlbanianLanguageShortcut(lang);
    moment.locale(transformedLang);
    setLocale(transformedLang);

    const { user, storage } = reduxStore.getState();

    const isCbtUser =
      storage?.frontendSettings.dealerCorporateSettings?.enableCbt === "true";

    if (user?.isLoggedIn && isCbtUser) {
      this.prepareCbtUsers({ reduxStore });
    }

    if (window.Cypress) {
      window.store = reduxStore;
    }

    if (config.requestorClientId.includes(".gol.") && window.spyTester) {
      window.spyTester.init({ idSite: "tpd4" });
    }

    if (router.route?.includes(`${ROUTES.RESERVATION}/`)) {
      pageDataLayer("other", user);
    } else {
      pageDataLayer("home", user);
    }
  }

  private transformAlbanianLanguageShortcut(lang: string) {
    return lang === "al" ? "sq" : lang;
  }

  render() {
    const { Component, pageProps, router, reduxStore } = this.props;

    const isAnonymousSearchEnabled =
      reduxStore.getState().storage.frontendSettings.dealerCorporateSettings
        ?.enableAnonymousSearch === "true";

    const isLoginIframe =
      router.route.includes(ROUTES.IFRAME) &&
      isAnonymousSearchEnabled === false;

    return (
      <Provider store={reduxStore}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Head>
        <div className="layout-wrapper">
          <div
            style={{ position: "absolute" }}
            dangerouslySetInnerHTML={{
              __html: `<!--${this.props.randomString}-->`,
            }}
          />
          {(() => {
            if (Component.getLayout) {
              return Component.getLayout(
                <Component {...pageProps} router={router} />
              );
            }
            if (errorRoutes.includes(router.route)) {
              return (
                <ErrorLayout>
                  <Component {...pageProps} router={router} />
                </ErrorLayout>
              );
            }
            return (
              <StandardLayout
                route={router.route}
                nonce={pageProps?.gtm?.nonce}
                hideSearchForm={isLoginIframe}
              >
                {isLoginIframe && <LoginForm />}
                <Component
                  {...pageProps}
                  showloginIframeTitle={isLoginIframe}
                  router={router}
                />
              </StandardLayout>
            );
          })()}
        </div>
      </Provider>
    );
  }
}

async function downloadPages(urls, fileServerData) {
  try {
    const result = {};

    if (!fileServerData) {
      return result;
    }

    const downloadPromises = urls
      .filter((url) => !!url)
      .filter((url) => !!fileServerData[`data/${url}.html`])
      .map((url) => axios.get(fileServerData[`data/${url}.html`]));

    const fetchedFileServerPages = await Promise.all(downloadPromises);

    fetchedFileServerPages.forEach((page, index) => {
      result[urls[index]] = page.data;
    });

    return result;
  } catch (e) {
    Logger.log("Error dowloading pages: ", e);
  }
}

async function fetchFileServerData(urlToAsk) {
  try {
    const data = await axios.get(urlToAsk);
    return data.data;
  } catch (e) {
    Logger.error(e);
    return null;
  }
}

async function loadStorageData({ domain, lang, reduxStore }) {
  if (!domain) {
    return;
  }

  if (reduxStore.getState().requestorConfig.fileServerData !== null) {
    return reduxStore.getState().requestorConfig.fileServerData;
  }

  try {
    const domainForFileServer = getDomainForFileServer(domain);

    const urlToAsk = `${config.fileServerApiUrl}${domainForFileServer}/${
      lang === "cz" ? "cs" : lang
    }/index.json`;

    const fileServerData = await fetchFileServerData(urlToAsk);

    if (domain.includes("localhost")) {
      // eslint-disable-next-line security/detect-non-literal-require -- this is only for localhost && no user input
      const defaultTextstorage = require(`./../../shared/lang/${
        lang === "cz" ? "cs" : lang
      }.json`);
      await reduxStore.dispatch(
        setRequestorConfig("textStorage", defaultTextstorage)
      );
    } else if (fileServerData["textstorage.json"]) {
      let urlTextStorage = fileServerData["textstorage.json"];
      if (
        urlTextStorage.includes("https:") &&
        (urlTextStorage.includes("localhost") ||
          urlTextStorage.includes("ao3-resources"))
      ) {
        urlTextStorage = `http://${
          fileServerData["textstorage.json"].split("ttps://")[1]
        }`;
      }

      const textStorageFetched = await axios.get(urlTextStorage);
      const textStorage = textStorageFetched.data;
      await reduxStore.dispatch(setRequestorConfig("textStorage", textStorage));
    }

    await reduxStore.dispatch(
      setRequestorConfig("fileServerData", fileServerData)
    );

    return fileServerData;
  } catch (e) {
    Logger.error(
      "Error - unable to load fileServer storage data. Maybe you have incorrect url? ",
      e
    );
  }
}

function getCustomerToken(ctx) {
  const allCookies = nextCookie(ctx);

  if (!allCookies || !allCookies.d4customerToken) {
    return false;
  }

  return allCookies.d4customerToken;
}

function getCustomerDevice(ctx) {
  const allCookies = nextCookie(ctx);

  if (!allCookies || !allCookies.d4customerDevice) {
    return false;
  }

  return allCookies.d4customerDevice;
}

function getWebRequestorDetails({ menuLanguage, isDefaultLanguage, ctx }) {
  const requestorPublicKey = nextCookie(ctx)?.d4requestorPublicKey;
  const customerToken = nextCookie(ctx)?.d4customerToken;
  const clientId = getInitialClientId(ctx.req.hostname);

  return loadWebRequestorDetails({
    selectedLanguage: isDefaultLanguage ? null : menuLanguage,
    golApiUrl: getGOLAPIUrl(ctx.req),
    requestorPublicKey,
    forcedCustomerToken: customerToken,
    forcedClientId: clientId,
  });
}

export default withReduxStore(MyApp);
