import { useState } from "react";
import { useDispatch } from "react-redux";

import { setUserValues } from "../data/actions/user";
import { COOKIE_REQUESTOR_PUBLIC_KEY } from "../data/cookies";
import {
  generateRandomNumber,
  setCbtToken,
  setCustomerDevice,
  setCustomerName,
  setCustomerToken,
  setRequestorPublicKey,
} from "../data/customerTokens";
import detailCustomer2 from "../gol-api/detailCustomer2";
import { getCustomerToken, sendForgottenPasswordEmail } from "../lib/golCopy";

export type LoginData = {
  username?: string;
  password?: string;
  namespace?: string;
  afterLoginCallback?: Function;
  confirmationalUserToken?: any;
  confirmEmailProcess?: Function;
  identityProviderId?: string;
  identityProviderData?: string;
};

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const dispatch = useDispatch();

  async function submitLogin(loginData: LoginData) {
    setIsLoading(true);

    const credentials = {
      Username: loginData.username,
      Password: loginData.password,
      Namespace: loginData.namespace,
      IdentityProviderId: loginData.identityProviderId,
      IdentityProviderData: loginData.identityProviderData,
      DeviceId: generateRandomNumber(10000000, 99999999),
      DeviceName:
        (window.navigator && window.navigator.userAgent) ||
        generateRandomNumber(1000, 999999999),
    };

    const customerToken =
      loginData.confirmationalUserToken ||
      (await getCustomerToken(credentials));

    if (customerToken?.needsConfirmation) {
      await loginData.confirmEmailProcess({
        formUsername: loginData.username,
        errorMessage: customerToken.errorMessage,
        formPassword: loginData.password,
      });
      setIsLoading(false);
      setIsFailed(false);
      return;
    }

    if (customerToken && customerToken.token) {
      setIsFailed(false);

      await setCustomerToken(customerToken.token);
      await setCustomerDevice({
        id: credentials.DeviceId,
        name: credentials.DeviceName,
      });

      if (customerToken.requestorPublicKey) {
        await setRequestorPublicKey(customerToken.requestorPublicKey);
        // @ts-ignore
        if (process.browser) {
          sessionStorage.setItem(
            COOKIE_REQUESTOR_PUBLIC_KEY,
            customerToken.requestorPublicKey
          );
        }
      }

      // @ts-ignore
      const clientDetail = await detailCustomer2();
      if (clientDetail.success) {
        const {
          data: { formattedData },
        } = clientDetail;

        await dispatch(setUserValues({ isLoggedIn: true, ...formattedData }));

        await setCustomerName(getCustomerNameFromClientDetail(formattedData));
        await setCbtToken(getCbtTokenFromClientDetail(formattedData));

        if (loginData?.afterLoginCallback)
          await loginData.afterLoginCallback(formattedData);
      } else {
        setIsFailed(true);
        setIsLoading(false);
      }
    } else {
      setIsFailed(true);
      setIsLoading(false);
    }
  }

  return [{ isLoading, isFailed }, submitLogin];
};

export const useForgottenPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  async function submitSendForgottenPassword(email) {
    setIsLoading(true);

    const sentPassword = await sendForgottenPasswordEmail({
      Username: email,
    });

    setIsLoading(false);
    if (sentPassword) {
      setIsFailed(false);
      setIsSuccess(true);
    } else {
      setIsFailed(true);
    }
  }

  return [{ isLoading, isFailed, isSuccess }, submitSendForgottenPassword];
};

export function getCustomerNameFromClientDetail(clientDetail) {
  return `${clientDetail.givenName}---${clientDetail.surname}---${clientDetail.username}`;
}

export function getCbtTokenFromClientDetail(clientDetail) {
  return clientDetail.cbtToken;
}
