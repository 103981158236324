import React from "react";

function HeaderMobileMenuButton({ toggleMobileMenu, showMobileMenu }) {
  return (
    <div className="header-menu-link" onClick={toggleMobileMenu} role="button">
      <span className="header-menu-link-hamburger">
        {showMobileMenu ? "x" : "☰"}
      </span>{" "}
      <span className="header-menu-link-text">Menu</span>
    </div>
  );
}

export default HeaderMobileMenuButton;
