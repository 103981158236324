import React, { Component } from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { AppState } from "shared/data/reducers";
import { loadAirportSuggestions } from "shared/lib/golCopy";
import { AirportSelectValue } from "shared/lib/golObjectTypes/SelectObjects";
import { formattedMessageParams } from "shared/messages";

const { hotelPlaceholder, noAirportsFound, fillInPlaces } = defineMessages({
  hotelPlaceholder: formattedMessageParams("HotelSelect.hotelPlaceholder"),
  noAirportsFound: formattedMessageParams("AirportSelect.noAirportsFound"),
  fillInPlaces: formattedMessageParams("HotelSelect.fillInPlaces"),
});

const TAB_KEY_CODE = 9;
const MIN_NUMBER_CHARACTERS_TO_START_SEARCH = 3;

interface Props {
  testId: string;
  idJumToAfterTab?: string;
  changeValue: (option: string) => void;
  defaultAirportSuggestions: AirportSelectValue[];
  oCountries: any;
  allowDefaultOptions: boolean;
  defaultValue?: AirportSelectValue;
  error?: any;
  intl: IntlShape;
  aSuggestions: any[];
}

type State = {
  noOptionsMessage: boolean;
  airportSuggestions: any[];
};

class AirportSelect extends Component<Props, State> {
  state = {
    noOptionsMessage: false,
    airportSuggestions: [],
  };

  onInput = async (inputValue) => {
    if (inputValue.length < MIN_NUMBER_CHARACTERS_TO_START_SEARCH) {
      this.setState({ noOptionsMessage: false });
      return;
    }

    const airportSuggestions = await loadAirportSuggestions(inputValue);

    this.setState({
      noOptionsMessage: true,
      airportSuggestions: [...airportSuggestions],
    });

    return airportSuggestions.map((oSuggestion) => ({
      value: oSuggestion.Code,
      label: oSuggestion.$t,
      Country: oSuggestion.Country,
    }));
  };

  formatCityLabel = (label) => {
    if (!label) {
      return "";
    }

    let newLabel = "";

    const cities = label.split("/");
    cities.forEach((city, index) => {
      const cityName = city.split("-");
      if (cities.length > 0 && index !== cities.length - 1) {
        newLabel += `${cityName[0]}/`;
      } else if (cities.length > 0 && index === cities.length - 1) {
        newLabel += cityName[0];
      } else {
        newLabel = label;
      }
    });

    return newLabel;
  };

  SingleValue = (props) => {
    const label = this.formatCityLabel(props.data.label);

    if (!label) {
      return null;
    }

    return (
      <span
        role="button"
        onClick={() => this.clearInput(props)}
        id={`${this.props.testId}-value`}
      >
        <span className="header-search-form-inner-field-value">{label}</span>{" "}
        <span className="header-search-form-inner-field-additional">
          {` (${props.data.value})`}
        </span>
      </span>
    );
  };

  SelectContainer = (props) => (
    <components.SelectContainer
      {...props}
      onClick={() => this.clearInput(props)}
      className="react-select-2-wrapper-2"
    />
  );

  customMenuContainer = (props) => {
    if (
      props.selectProps.inputValue.length === 0 &&
      !this.props.allowDefaultOptions
    ) {
      return null;
    }

    return <components.Menu {...props} className="select-menu-outer" />;
  };

  Input = (props) => (
    <components.Input
      {...props}
      data-cy={this.props.testId}
      className="r3"
      id={`airport-select-input-${this.props.testId}`}
    />
  );

  onChange = (option) => {
    if (option !== undefined && option !== null) {
      this.props.changeValue(option);
    } else {
      this.props.changeValue(null);
    }
  };

  clearInput = (props) => {
    props.clearValue();
    this.props.changeValue(null);
  };

  onKeyDown = (e) => {
    if (!this.props.idJumToAfterTab) return;

    if (e.keyCode === TAB_KEY_CODE) {
      document
        .getElementById(`airport-select-input-${this.props.idJumToAfterTab}`)
        .focus();
    }
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="header-search-form-results">
        <AsyncSelect
          onKeyDown={this.onKeyDown}
          placeholder={intl.formatMessage(hotelPlaceholder)}
          className="react-select-2-wrapper"
          classNamePrefix="react-select-hotels"
          styles={{
            control: () => ({}),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 0,
              marginLeft: 0,
              paddingTop: 0,
              marginTop: -4,
            }),
            placeholder: (base) => ({
              ...base,
              marginTop: -1,
            }),
            menuList: (base) => ({
              ...base,
              maxHeight: "200px",
              paddingTop: 0,
              paddingBottom: 0,
            }),
            noOptionsMessage: () => ({
              marginLeft: "15px",
              fontFamily: "Muli",
              padding: "10px 0px 10px 0px",
              display: !this.state.noOptionsMessage ? "none" : "auto",
            }),
          }}
          isClearable
          value={this.props.defaultValue}
          noOptionsMessage={() =>
            this.props.intl.formatMessage(noAirportsFound)
          }
          components={{
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            LoadingIndicator: () => null,
            SingleValue: this.SingleValue,
            Menu: this.customMenuContainer,
            SelectContainer: this.SelectContainer,
            Input: this.Input,
            Option: (val) => {
              const sug =
                this.state.airportSuggestions.length === 0
                  ? this.props.defaultAirportSuggestions
                  : this.state.airportSuggestions;
              return (
                <OptionComponent
                  oCountries={this.props.oCountries}
                  {...val}
                  aSuggestions={sug}
                  className="r5"
                  intl={intl}
                />
              );
            },
            Placeholder: (props) => {
              if (props.isFocused) return null;
              return (
                <components.Placeholder
                  {...props}
                  className="react-select-2-placeholder"
                />
              );
            },
          }}
          onChange={this.onChange}
          loadOptions={this.onInput}
          defaultOptions={
            this.props.allowDefaultOptions
              ? this.props.defaultAirportSuggestions.map((oSuggestion) => ({
                  value: oSuggestion.Code,
                  label: oSuggestion.label,
                  Country: oSuggestion.Country,
                }))
              : null
          }
          cacheOptions
        />
        {this.props.error ? (
          <div className="search-field-error">
            {intl.formatMessage(fillInPlaces)}
          </div>
        ) : null}
      </div>
    );
  }
}

interface OptionProps {
  aSuggestions: any[];
  data: any;
  isFocused: boolean;
  isSelected: boolean;
  selectOption: (string) => void;
  oCountries: any;
}

const OptionComponent = (props: OptionProps) => {
  let hasMoreAirports = false;
  if (props.aSuggestions.length !== 0) {
    const res = props.aSuggestions.find(
      (oSuggestion) => oSuggestion.Code === props.data.value
    );
    hasMoreAirports = res === undefined ? false : res.Parent.length;
  }
  return (
    <div
      role="button"
      className={`header-search-form-option ${
        props.isFocused || props.isSelected
          ? "header-search-form-option-selected"
          : ""
      }`}
      onClick={() => props.selectOption(props.data)}
    >
      {hasMoreAirports ? "└ " : ""}
      {props.data.label}
      {props.aSuggestions &&
      props.aSuggestions.length > 0 &&
      props.aSuggestions.filter(
        (oSuggestion) =>
          oSuggestion.Parent !== "" && oSuggestion.Parent === props.data.value
      ).length > 0
        ? `` //  - ${props.intl.formatMessage(allAirports)} maybe whole city in future
        : ""}
      {props.oCountries && props.oCountries[props.data.Country]
        ? `, ${props.oCountries[props.data.Country]}`
        : ""}{" "}
      <b>({props.data.value})</b>
    </div>
  );
};

export default connect((state: AppState) => ({
  oCountries: state.storage.countries,
  defaultAirportSuggestions: state.storage.defaultAirportSuggestions,
}))(AirportSelect);
