import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import golApiCall from "./golApiCall";

const getGolRequestListReservationsQuery = (reservationCodes) => ({
  GolApi: {
    RequestDetail: {
      ListReservationsRequest_2: {
        // todo use v2
        ReservationTypes: {
          ReservationType: reservationCodes.map((reservationCode) => ({
            Code: reservationCode,
          })),
        },
      },
    },
  },
});

export default async (
  reservationCodes = ["flight"]
): Promise<ResponseObject> => {
  const golRequest = getGolRequestListReservationsQuery(reservationCodes);
  const response = await golApiCall(golRequest);
  return createResponse(response);
};
