import { getBookingFormElementDescription } from "../../hooks/bookingForm/getBookingFormElementDescription";
import * as actionTypes from "../actions/actionTypes";

const initState = {
  acquisitionParamsBookingForm: [],
  bookingFormSections: [],
  bookingFormFields: {},
  codeBook: {},
  ticketingCombinations: [],
  paymentFormMethods: [],
  paymentFormMethodForms: [],
  paymentTypeId: null,
  isConditionsConfirmed: false,
  showValidationErrors: false,
  cbtGuestsUsedInDynamicForm: null,
  cbtGuestsToUpdateInDynamicForm: null,
  cbtGuestsToCreateInDynamicForm: null,
  cbtGuestsInDynamicFormShowingSearchForm: null,
  isFetchingServices: false,
  promoCode: null,
  lastPaymentDate: "",
  bookingFormElementRefs: [],
  managedTravellers: { travellers: [], shouldDisplayGuests: false },
};

const setBookingFormParams = (state, action) => {
  return {
    ...state,
    bookingFormSections: action.data.sections,
    bookingFormFields: action.data.fields,
  };
};

const setBookingFormFromAcquisitionParams = (state, action) => {
  return {
    ...state,
    bookingFormSections: action.data.sections,
    bookingFormFields: action.data.fields,
    acquisitionParamsBookingForm: action.data.acquisitionParamsBookingForm,
    codeBook: action.data.codeBook,
    ticketingCombinations: action.data.ticketingCombinations,
    cbtGuestsInDynamicFormShowingSearchForm:
      action.data.cbtGuestsInDynamicFormShowingSearchForm,
    cbtGuestsUsedInDynamicForm: [],
    paymentFormMethods: action.data.paymentFormMethods,
    paymentFormMethodForms: action.data.paymentFormMethodForms,
    showValidationErrors: false,
    alternativeCurrencyPaymentFormMethods:
      action.data.alternativeCurrencyPaymentFormMethods,
    paymentTypeId:
      action.data.codeBook?.PaymentformMethods?.PaymentformMethod.length === 1
        ? action.data.codeBook?.PaymentformMethods?.PaymentformMethod[0].Id
        : null,
  };
};

const setBookingFormMarketplaceFromAcquisitionParams = (state, action) => {
  const acquisitionParams = action.data?.acquisitionParamsBookingForm;

  if (!acquisitionParams) {
    return state;
  }

  const marketplaceServices = acquisitionParams
    .filter((param) => param.Code === "marketplace")
    .map((param) => param?.BookingFormGroup?.BookingFormGroup)
    .flat();

  const marketplaceServicesFormatted = marketplaceServices.map((service) =>
    getBookingFormElementDescription({
      bookingFormElement: service?.BookingFormGroup[0]?.BookingFormElement,
      passengerIndex: null,
      cbt: state.cbt,
    })
  );

  const updatedBookingFormFields = { ...state.bookingFormFields };

  marketplaceServicesFormatted.forEach((field) => {
    updatedBookingFormFields[field.name] = {
      ...field,
      value: state.bookingFormFields[field.name]?.value,
    };
  });

  const selectedOptions = marketplaceServicesFormatted.map((field) =>
    updatedBookingFormFields[field.name]?.options.find(
      (option) => option.value === state.bookingFormFields[field.name]?.value
    )
  );

  action.data?.getActualMarketplaceServices(selectedOptions);

  return {
    ...state,
    bookingFormFields: updatedBookingFormFields,
  };
};

const setBookingFormValue = (state, action) => {
  return {
    ...state,
    [action.data.name]: action.data.value,
  };
};

const setIsFetchingServices = (state, action) => {
  return {
    ...state,
    isFetchingServices: action.data.value,
  };
};

// sets content of the whole bookingFormField
const setBookingFormFieldValue = (state, action) => {
  return {
    ...state,
    bookingFormFields: {
      ...state.bookingFormFields,
      [action.data.name]: action.data.value,
    },
  };
};

// sets value of bookingFormField
const setBookingFormFieldValueOnly = (state, action) => {
  const newState = {
    ...state,
    bookingFormFields: {
      ...state.bookingFormFields,
      [action.data.name]: {
        ...state.bookingFormFields[action.data.name],
        value: action.data.value,
      },
    },
  };

  return newState;
};

const setBookingFormElementRefs = (state, action) => {
  return {
    ...state,
    bookingFormElementRefs: action.value,
  };
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_BOOKING_FORM_ELEMENTS_REFS:
      return setBookingFormElementRefs(state, action);
    case actionTypes.SET_BOOKING_FORM_PARAMS:
      return setBookingFormParams(state, action);
    case actionTypes.SET_BOOKING_FORM_FROM_ACQUISITION_PARAMS:
      return setBookingFormFromAcquisitionParams(state, action);
    case actionTypes.SET_BOOKING_FORM_MARKETPLACE_FROM_ACQUISITION_PARAMS:
      return setBookingFormMarketplaceFromAcquisitionParams(state, action);
    case actionTypes.SET_BOOKING_FORM_VALUE:
      return setBookingFormValue(state, action);
    case actionTypes.SET_BOOKING_FORM_FIELD_VALUE:
      return setBookingFormFieldValue(state, action);
    case actionTypes.SET_BOOKING_FORM_FIELD_VALUE_ONLY:
      return setBookingFormFieldValueOnly(state, action);
    case actionTypes.SET_BOOKING_FETCHING_SERVICES:
      return setIsFetchingServices(state, action);
    case actionTypes.INIT_FORM_STATE:
      return initState;
    default:
      return state;
  }
};

export default reducer;
