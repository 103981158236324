import { useSelector } from "react-redux";

import { AppState } from "../data/reducers";

export function useCustomerName() {
  const { firstname, surname, username } = useSelector(
    (state: AppState) => state.user
  );

  if (firstname === "undefined" && surname === "undefined") {
    return username;
  }

  return `${firstname !== "undefined" ? firstname : ""} ${
    surname !== "undefined" ? surname : ""
  }`;
}
