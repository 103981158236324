import { useRouter } from "next/router";
import React from "react";

import FormattedMessage from "@components/UI/FormattedMessage";
import SetCurrencyMobile from "@components/UI/Header/SetCurrencyMobile";
import SetLanguageMobile from "@components/UI/Header/SetLanguageMobile";
import Link from "@components/UI/Link";

import { useHeaderMenu } from "./Menu";

export default function HeaderMobileMenu({
  toggleMobileMenu,
  showMobileChangeLanguage,
  showMobileChangeCurrency,
  showMobileMenu,
  toggleMobileChangeLanguage,
  toggleMobileChangeCurrency,
  isLoggedIn,
  logout,
  isCbtEnabled,
}) {
  const router = useRouter();
  const menuItems = useHeaderMenu();

  return (
    <>
      <div
        role="button"
        onClick={toggleMobileMenu}
        className={`header-mobile-menu ${
          showMobileMenu ? "header-mobile-menu-show" : ""
        }`}
      >
        {!showMobileChangeLanguage && !showMobileChangeCurrency && isLoggedIn && (
          <Link
            href={{
              pathname: "/reservations",
              query: router.query.lang ? { lang: router.query.lang } : {},
            }}
          >
            <a className="header-mobile-menu-link">
              <FormattedMessage id="HeaderLogoStripe.reservation-button" />{" "}
            </a>
          </Link>
        )}
        {!showMobileChangeLanguage &&
          !showMobileChangeCurrency &&
          menuItems.map((menuItem) => {
            if (menuItem.alternativeUrl) {
              return (
                <a
                  href={menuItem.alternativeUrl}
                  target="_blank"
                  className="header-mobile-menu-link"
                >
                  {menuItem.label}
                </a>
              );
            }
            return (
              <Link
                href={{
                  pathname: "/page",
                  query: { url: menuItem.url },
                }}
                as={`/page/${menuItem.url}`}
                key={menuItem.url}
              >
                <a className="header-mobile-menu-link">{menuItem.label}</a>
              </Link>
            );
          })}
        {showMobileChangeLanguage && <SetLanguageMobile />}
        {showMobileChangeCurrency && <SetCurrencyMobile />}
        {isCbtEnabled && (
          <a
            className="header-mobile-menu-link"
            target="_blank"
            href="https://www.cee-systems.com/privacypolicy"
          >
            <FormattedMessage id="HeaderLogoStripe.privacyPolicy" />
          </a>
        )}
        {!showMobileChangeLanguage && !showMobileChangeCurrency && (
          <>
            <a
              href="#"
              onClick={toggleMobileChangeLanguage}
              className="header-mobile-menu-link"
            >
              <FormattedMessage id="HeaderLogoStripe.changeLanguage" />
            </a>
            <a
              href="#"
              onClick={toggleMobileChangeCurrency}
              className="header-mobile-menu-link"
            >
              <FormattedMessage id="HeaderLogoStripe.changeCurrency" />
            </a>
          </>
        )}
        {showMobileChangeLanguage && !showMobileChangeCurrency && (
          <a
            href="#"
            onClick={toggleMobileChangeLanguage}
            className="header-mobile-menu-link"
          >
            <FormattedMessage id="HeaderLogoStripe.keepLanguage" />
          </a>
        )}
        {!showMobileChangeLanguage && showMobileChangeCurrency && (
          <a
            href="#"
            onClick={toggleMobileChangeCurrency}
            className="header-mobile-menu-link"
          >
            <FormattedMessage id="HeaderLogoStripe.keepCurrency" />
          </a>
        )}{" "}
        {!showMobileChangeLanguage && !showMobileChangeCurrency && isLoggedIn && (
          <div
            role="button"
            onClick={logout}
            className="header-mobile-menu-link underline"
            id="header-mobile-logout"
          >
            <FormattedMessage id="Profile.logout" />
          </div>
        )}
      </div>
      {showMobileMenu && <div className="header-mobile-menu-background" />}
    </>
  );
}
