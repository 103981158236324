import * as actionTypes from "./actionTypes";

export const setGeneratedHTMLTime = () => {
  return {
    type: actionTypes.SET_GENERATED_HTML_TIME,
  };
};

export const setRequestorPostfix = (data) => {
  return {
    type: actionTypes.SET_REQUESTOR_POSTFIX,
    data,
  };
};

export const setRequestorConfig = (name, value) => {
  return {
    type: actionTypes.SET_REQUESTOR_CONFIG,
    data: { name, value },
  };
};
