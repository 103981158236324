import { parse } from "node-html-parser";

import { hasElement } from "shared/lib/functions";

import * as actionTypes from "../actions/actionTypes";

const initState = {
  generatedHTMLTime: 0,
  clientId: "user_mock", // universal mock user_copy
  requestorPostfix: "",
  fileServerData: null,
  textStorage: null,
  currentLanguage: null,
  currency: "CZK",
  alternativeCurrencies: [],
  supportedLanguages: [],
  menuItems: [],
  footerInnerHTML: "",
  registrationAllowed: true,
  hotelsEnabled: false,
  specialOfferEnabled: false,
  countries: [], // used in mobile booking form
  agency: null,
  promoCodesEnabled: false,
  allowedTravelerTypes: null,
  customerEmailConfirmation: false,
  passwordCustomerLoginAllowed: true,
  module: [],
  ssoIdentityProvider: [],
};

const secureFooter = (footerHTML, nonce, clientId) => {
  // TODO: Get rid of this after https://gitlab.in.cee-systems.com/gol/ao3/-/issues/2931
  const clientExceptions = [
    "simo.gol.in.cee-systems.com",
    "zamazal.gol.in.cee-systems.com",
    "soutor.gol.in.cee-systems.com",
    "nuriev.gol.in.cee-systems.com",
    "olsovsky.gol.in.cee-systems.com",
    "orbix.golibe.com",
  ].includes(clientId);
  // TODO: Replace from redux store after https://gitlab.in.cee-systems.com/gol/ao3/-/issues/2931
  const isCookiePolicySet = true;
  const isCspActive = isCookiePolicySet && clientExceptions;

  let sanitizedHTML = footerHTML;
  if (hasElement(footerHTML, "script") && isCspActive) {
    const root = parse(sanitizedHTML);
    const scripts = root.querySelectorAll("script");
    scripts.forEach((script) => {
      script.setAttribute("nonce", nonce || "");
      script.setAttribute("nonce-value", nonce || "");
    });
    sanitizedHTML = root.toString();
  }
  return sanitizedHTML;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_GENERATED_HTML_TIME:
      return { ...state, generatedHTMLTime: +new Date() };
    case actionTypes.SET_REQUESTOR_POSTFIX:
      return { ...state, requestorPostfix: action.data };
    case actionTypes.SET_REQUESTOR_CONFIG:
      if (action.data.name === "footerInnerHTML") {
        return {
          ...state,
          [action.data.name]: secureFooter(
            action.data.value.html,
            action.data.value.nonce,
            action.data.value.clientId
          ),
        };
      }
      return { ...state, [action.data.name]: action.data.value };
    default:
      return state;
  }
};

export default reducer;
