import * as actionTypes from "../actions/actionTypes";

const initState = {
  universalLoader: false,
  searchLoader: false,
  initialLoader: false,
};
const startInitialLoading = (state) => ({ ...state, initialLoader: true });
const stopInitialLoading = (state) => ({ ...state, initialLoader: false });
const startSearchLoading = (state) => ({ ...state, searchLoader: true });
const stopSearchLoading = (state) => ({ ...state, searchLoader: false });
const startUniversalLoading = (state) => ({ ...state, universalLoader: true });
const stopUniversalLoading = (state) => ({ ...state, universalLoader: false });

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_UNIVERSAL_LOADING:
      return startUniversalLoading(state);
    case actionTypes.STOP_UNIVERSAL_LOADING:
      return stopUniversalLoading(state);
    case actionTypes.START_INITIAL_LOADING:
      return startInitialLoading(state);
    case actionTypes.STOP_INITIAL_LOADING:
      return stopInitialLoading(state);
    case actionTypes.START_SEARCH_LOADING:
      return startSearchLoading(state);
    case actionTypes.STOP_SEARCH_LOADING:
      return stopSearchLoading(state);
    default:
      return state;
  }
};

export default reducer;
