import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

function getGolRequestReservationDetailsQuery(reservationsIds: string[]) {
  return {
    GolApi: {
      RequestDetail: {
        DetailReservationsRequest_2: {
          SearchReservations: {
            SearchReservation: reservationsIds.map((reservationId) => ({
              Reservation: { ReservationId: reservationId },
            })),
          },
        },
      },
    },
  };
}

export default async (reservationsIds: string[]) => {
  const golRequest = getGolRequestReservationDetailsQuery(reservationsIds);
  const reservationDetailsResponse = await golApiCall(golRequest);
  return createResponse(reservationDetailsResponse);
};
