import { INVALID_DATE_ERROR_CODE } from "shared/constants/results/errors";
import { scrollTo } from "shared/lib/functions";

import golApi from "../../gol-api/index";
import { SearchQuery } from "../reducers/types/storage";
import * as actionTypes from "./actionTypes";
import { startSearchLoading, stopSearchLoading } from "./loader";
import { setSearchQuery } from "./storage";

const normalizeDate = (date) => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

export const flightSearch = (searchQuery: SearchQuery, opts = {}) => {
  const blockSearchDaysBefore = opts.blockSearchDaysBefore || 0;

  const addDaysToDate = (date, days) => {
    const dateObj = normalizeDate(new Date(date));
    const newDate = new Date(dateObj.getTime() + days * 24 * 60 * 60 * 1000);
    return newDate;
  };

  const today = normalizeDate(new Date());
  const todayDateWithBlackedDays = new Date(
    normalizeDate(addDaysToDate(today.toISOString(), blockSearchDaysBefore))
  );
  let areFutureDates = true;

  Object.values(searchQuery).forEach((value, index) => {
    const key = Object.keys(searchQuery)[index];
    if (
      typeof value === "string" &&
      (key.includes("departureDate") || key.includes("returnDate"))
    ) {
      if (value === "") {
        return;
      }
      const pickedDate = normalizeDate(new Date(value));

      if (pickedDate.getTime() < todayDateWithBlackedDays.getTime()) {
        areFutureDates = false;
      }
    }
  });

  const isDepartureBeforeReturn = Object.entries(searchQuery).every(
    ([key, value]) => {
      if (key.includes("returnDate") && searchQuery.departureDate) {
        if (value === "") {
          return true;
        }

        return searchQuery.departureDate <= value;
      }
      return true;
    }
  );

  return async (dispatch): Promise<void> => {
    dispatch(startSearchLoading());
    dispatch(setSearchQuery(searchQuery));

    if (!areFutureDates) {
      return dispatch(
        flightSearchFail(
          "Flight.notFound.notValidDates",
          INVALID_DATE_ERROR_CODE,
          true
        )
      );
    }

    if (!isDepartureBeforeReturn) {
      return dispatch(
        flightSearchFail(
          "Flight.notFound.departureBeforeSearchDays",
          INVALID_DATE_ERROR_CODE,
          true
        )
      );
    }

    const flightSearchResponse = await golApi.flightSearch(searchQuery, opts);

    if (flightSearchResponse.isError) {
      if (!flightSearchResponse.errorMsg && !flightSearchResponse.errorCode) {
        return dispatch(stopSearchLoading());
      }
      return dispatch(
        flightSearchFail(
          flightSearchResponse.errorMsg,
          flightSearchResponse.errorCode
        )
      );
    }
    dispatch({
      type: actionTypes.ADD_AIRPORT_DESTINATIONS,
      data: Object.values(flightSearchResponse.airports),
    });
    dispatch(flightSearchSuccess(flightSearchResponse));
    scrollTo("#results-wrapper");
    dispatch(stopSearchLoading());
  };
};

export const flightSearchSuccess = (flightSearchData) => {
  return {
    type: actionTypes.FLIGHT_SEARCH_SUCCESS,
    data: flightSearchData,
  };
};

export const flightSearchFail = (error, errorCode, isIntl = false) => {
  return { type: actionTypes.FLIGHT_SEARCH_FAIL, error, errorCode, isIntl };
};

export const toggleServiceInfo = () => {
  return { type: actionTypes.FLIGHT_TOGGLE_SERVICE_INFO };
};

export const setTransportCompanies = (data) => {
  return { type: actionTypes.SEARCH_SET_TRANSPORT_COMPANIES, data };
};

export const setAirports = (data) => {
  return { type: actionTypes.SEARCH_SET_AIRPORTS, data };
};

export const setActualFlightServices = (data) => {
  return { type: actionTypes.SEARCH_SET_ACTUAL_FLIGHT_SERVICES, data };
};

export const deleteActualFlightServices = () => {
  return { type: actionTypes.DELETE_ACTUAL_FLIGHT_SERVICES };
};

export const setSearch = (name, value) => {
  return { type: actionTypes.SEARCH_SET, data: { name, value } };
};

export const setAirplanes = (data) => {
  return { type: actionTypes.SEARCH_SET_AIRPLANES, data };
};

export const setPolicies = (data) => {
  return { type: actionTypes.SEARCH_SET_POLICIES, data };
};

export const setSearchFlights = (data) => {
  return { type: actionTypes.SEARCH_SET_SEARCH_FLIGHTS, data };
};

export const addSearchFlight = (data) => {
  return { type: actionTypes.SEARCH_ADD_SEARCH_FLIGHT, data };
};

export const removeSearchFlight = (data) => {
  return { type: actionTypes.SEARCH_REMOVE_SEARCH_FLIGHT, data };
};

export const updateSearchFlightOrigin = (data) => {
  return { type: actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_ORIGIN, data };
};

export const updateSearchFlightDestination = (data) => {
  return { type: actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_DESTINATION, data };
};

export const updateSearchFlightDepartureDate = (data) => {
  return { type: actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_DEPARTURE_DATE, data };
};

export const updateSearchFlightReturnDate = (data) => {
  return { type: actionTypes.SEARCH_UPDATE_SEARCH_FLIGHT_RETURN_DATE, data };
};
