import { useRouter } from "next/router";
import React from "react";

import Img from "@components/FileServer/Img";
import FormattedMessage from "@components/UI/FormattedMessage";
import Link from "@components/UI/Link";

function LoginButton() {
  const router = useRouter();

  return (
    <Link
      href={{
        pathname: "/login",
        query: {
          ...(router.query?.isLoginRequired && {
            isLoginRequired: router.query.isLoginRequired,
          }),
          redirectAfterLogin: router.asPath,
        },
      }}
    >
      <a className="header-button">
        <Img
          src="/static/images/ico-signin.svg"
          className="header-signin-image"
          alt="sign in icon"
        />
        <span className="header-button-text" id="header-signin">
          <FormattedMessage id="Screen.Login.title" />
        </span>
      </a>
    </Link>
  );
}

export default LoginButton;
