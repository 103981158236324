import En from "../lang/en.json";

export type FormattedMessageId = keyof typeof En;

export function formattedMessageParams(
  id: FormattedMessageId
): { id: string; defaultMessage: string } {
  return {
    id: id as string,
    defaultMessage: En[id] as string,
  };
}
