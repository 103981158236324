import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import golApiCall from "./golApiCall";

type Device = {
  DeviceId: string;
  DeviceName: string;
};

const confirmCustomerEmailRequestQuery = async (
  userName: string,
  resend: boolean,
  device?: Device
) => ({
  GolApi: {
    RequestDetail: {
      ConfirmCustomerEmailRequest_1: {
        ...(device
          ? {
              Device: {
                Id: device?.DeviceId,
                $t: device?.DeviceName,
              },
            }
          : {}),
        IssueCustomer: {
          Username: userName,
        },
        ...(resend ? { ResendEmail: {} } : {}),
      },
    },
  },
});

export default async (
  userName: string,
  resend: false,
  device?: Device,
  code?: string
): Promise<ResponseObject> => {
  const golRequest = await confirmCustomerEmailRequestQuery(
    userName,
    resend,
    device
  );

  const response = await golApiCall(golRequest, null, code);
  return createResponse(response);
};
