import React, { ReactElement } from "react";

import { ROUTES } from "shared/constants";

import FormattedMessage from "@components/UI/FormattedMessage";
import Link from "@components/UI/Link";

function ReservationsButton(): ReactElement {
  return (
    <Link href={ROUTES.RESERVATIONS}>
      <a className="header-button">
        <span
          className="header-button-text"
          id="header-reservations-button"
          role="button"
        >
          <FormattedMessage id="HeaderLogoStripe.reservation-button" />
        </span>
      </a>
    </Link>
  );
}

export default ReservationsButton;
