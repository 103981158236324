// private, different version for public
// not using now, for mobile will be a litte different
import {
  setGeneratedHTMLTime,
  setRequestorConfig,
  setRequestorPostfix,
} from "../data/actions/requestorConfig";
import Logger from "../services/Logger";
import { IS_MOBILE } from "../services/persistentStorageService";
// @ts-ignore
import { getClientId as getClientIdFromRequestorConfiguration } from "../services/requestorConfiguration";

const sharedConfig = require("../config/sharedConfig");

let clientId = null; // requestor clientId
let locale = "cs"; // default
const DEFAULT_CLIENT_ID = "localhost";

export function setClientId(id) {
  clientId = id;
}

export function setLocale(value) {
  locale = value;
}

export function getCurrency() {
  if (
    process.browser &&
    window.location.search &&
    window.location.search.includes("currency=")
  ) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("currency");
  }
  return "";
}

export function getLocale() {
  if (
    process.browser &&
    window.location.search &&
    window.location.search.includes("lang=")
  ) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("lang");
  }
  return locale;
}

export function getClientId() {
  if (IS_MOBILE) {
    setClientId(sharedConfig.requestorClientId);
    return sharedConfig.requestorClientId || DEFAULT_CLIENT_ID;
  }
  if (clientId === null) {
    return getInitialClientId(process.browser ? window.location.hostname : "");
  }
  return clientId;
}

// ClientId is always taken from URL without protocol and port
export function getInitialClientId(host) {
  return getClientIdFromRequestorConfiguration(host);
}

export function getDomain(req) {
  if (!req || !req.headers || !req.headers.host) return null;

  if (req.headers.host.includes("localhost")) {
    return "localhost";
  }

  return req.headers.host;
}

export async function setInitialRequestorConfig(initialProps) {
  try {
    // @ts-ignore
    if (process.browser) {
      return { domain: window.location.hostname };
    }

    const { reduxStore } = initialProps;
    const clientIdConfig = getInitialClientId(initialProps.req.hostname);

    setClientId(clientIdConfig);

    const domain = getDomain(initialProps.req);

    await reduxStore.dispatch(setRequestorConfig("clientId", clientIdConfig));
    await reduxStore.dispatch(setGeneratedHTMLTime());
    await reduxStore.dispatch(setRequestorPostfix("112233"));

    return { domain };
  } catch (e) {
    Logger.error(e);
  }
}

export function plusMinusDaysEnabled(frontendSettings) {
  return (
    frontendSettings &&
    frontendSettings.dealerFlexShoppingSettings &&
    frontendSettings.dealerFlexShoppingSettings.enablePlusMinusDays === "true"
  );
}

export function isAnonymousSearchEnabled(frontendSettings) {
  return (
    frontendSettings &&
    frontendSettings.dealerCorporateSettings &&
    frontendSettings.dealerCorporateSettings?.enableAnonymousSearch === "true"
  );
}

export function maxPlusMinusDays(frontendSettings) {
  return Number(
    frontendSettings &&
      frontendSettings.dealerFlexShoppingSettings &&
      frontendSettings.dealerFlexShoppingSettings.maxPlusMinusDay
  );
}
