import cookie from "js-cookie";

import {
  ANONYMOUS_CLIENT_TOKEN_EXPIRATION_TIME,
  CLIENT_TOKEN_EXPIRATION_TIME,
} from "../lib/constants";
import { getCbtToken } from "./customerTokens";

const NUM_MAX_LAST_SEARCH_OPTIONS = 3;

// logging in in web
export const setClientToken = (token) =>
  setCookie("token", token, CLIENT_TOKEN_EXPIRATION_TIME);

export const setAnonymousToken = (token) =>
  setCookie("token", token, ANONYMOUS_CLIENT_TOKEN_EXPIRATION_TIME);

export const getClientToken = () => cookie.get("token");

export function setCookie(
  name: string,
  val: string,
  expiration: number = CLIENT_TOKEN_EXPIRATION_TIME
) {
  const expires = new Date();
  expires.setTime(expires.getTime() + expiration);
  cookie.set(name, val, { expires, sameSite: "strict" });
}

export function getCookie(name: string): string | undefined {
  const username = cookie.get(name);
  if (username === "undefined") {
    return undefined;
  }
  return username;
}

export const getUsername = () => getCookie("username");

export const getPersistedCbtToken = () => getCbtToken();

export function saveCbtToken(cbtToken) {
  setCookie("cbtToken", cbtToken);
}

export const isClientTokenValid = () => {
  // web
  const clientToken = cookie.get("token");

  return clientToken !== undefined;
};

// saving reservations info for usage after payment with credit card returns something (because it cannot use url params for passing this info easier)
export function setReservationsInfo(reservationsInfo): void {
  const now = new Date();
  now.setTime(now.getTime() + CLIENT_TOKEN_EXPIRATION_TIME);
  cookie.set("reservationsInfo", JSON.stringify(reservationsInfo), {
    expires: now,
    sameSite: "strict",
  });
}

export function setLanguage(language: string): void {
  const now = new Date();
  now.setTime(now.getTime() + CLIENT_TOKEN_EXPIRATION_TIME);
  cookie.set("language", language, {
    expires: now,
    sameSite: "strict",
  });
}

export function getReservationsInfo() {
  const reservationsInfoRaw = cookie.get("reservationsInfo");
  if (!reservationsInfoRaw) {
    return false;
  }
  const reservationsInfo = JSON.parse(reservationsInfoRaw);

  return reservationsInfo;
}

export const getLastSearchDestinations = () => {
  const result = cookie.get("lastSearchDestinations");
  return result;
};

export const setLastSearchDestination = (option) => {
  const alreadySetLastSearchDestinations = getLastSearchDestinations();
  if (alreadySetLastSearchDestinations === null) {
    cookie.set("lastSearchDestinations", JSON.stringify([option]));
  } else {
    const alreadySetLastSearchDestinationsArray = JSON.parse(
      alreadySetLastSearchDestinations
    );

    cookie.set(
      "lastSearchDestinations",
      JSON.stringify(
        [option]
          .concat(
            alreadySetLastSearchDestinationsArray.filter(
              (o) => o.value !== option.value
            )
          )
          .filter((o, i) => i < NUM_MAX_LAST_SEARCH_OPTIONS)
      )
    );
  }
};
