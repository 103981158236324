import golApiCall from "shared/gol-api/golApiCall";
import { createResponse } from "shared/lib/functions";

function getGolSearchSpecialoffersRequest(reference) {
  return {
    GolApi: {
      RequestDetail: {
        SearchSpecialoffersRequest_1: {
          SpecialofferReference: { $t: reference },
        },
      },
    },
  };
}

export default async (reference) => {
  const request = getGolSearchSpecialoffersRequest(reference);
  const rawResponse = await golApiCall(request);
  return createResponse(rawResponse);
};
