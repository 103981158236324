import { useSelector } from "react-redux";

import { AppState } from "../data/reducers";

export function useCbtGuestData({ index, indexPassenger }) {
  const {
    cbt,
    bookingFormSection,
    cbtGuestsInDynamicFormShowingSearchForm,
  } = useSelector((state: AppState) => ({
    cbt: state.cbt,
    bookingFormSection: state.bookingForm.bookingFormSections[index],
    cbtGuestsInDynamicFormShowingSearchForm:
      state.bookingForm.cbtGuestsInDynamicFormShowingSearchForm,
  }));

  const isCbtGuest = getIsCbtGuestFromCbtAndBookingFormSectionAndIndex(
    cbt,
    bookingFormSection,
    index
  );

  const showCbtGuestSearchForm =
    isCbtGuest &&
    cbtGuestsInDynamicFormShowingSearchForm?.includes(indexPassenger);

  const setShowCbtGuestSearchForm = () => {};

  const indexGuest = indexPassenger;

  return {
    showCbtGuestSearchForm,
    setShowCbtGuestSearchForm,
    indexGuest,
  };
}

function getIsCbtGuestFromCbtAndBookingFormSectionAndIndex(
  cbt,
  bookingFormSection,
  index
) {
  const numCbtTravellers =
    (cbt?.passengersForm?.travellers?.length || 0) +
    (cbt?.passengersForm?.guests || 0);

  if (numCbtTravellers === 0) {
    return false;
  }

  if (cbt.corporate === null) {
    return false;
  }

  if (!bookingFormSection.title.startsWith("CODE-")) {
    return false;
  }

  if (index < (cbt.passengersForm?.travellers?.length || 0)) {
    return false;
  }

  return true;
}
