import classnames from "classnames";
import React from "react";

type Props = {
  decrease: () => void;
  increase: () => void;
  disableDecrease?: boolean;
  disableIncrease?: boolean;
  label?: string;
  value: number;
  id?: string;
  className?: string;
};

export default function NumberStepper({
  decrease,
  increase,
  disableDecrease,
  disableIncrease,
  label,
  value,
  id,
  className,
}: Props) {
  const decreaseClasses = classnames(
    "number-stepper__action-btn",
    "number-stepper__dec-btn",
    {
      "number-stepper__action-btn--disabled": disableDecrease,
    }
  );

  const increaseClasses = classnames(
    "number-stepper__action-btn",
    "number-stepper__inc-btn",
    {
      "number-stepper__action-btn--disabled": disableIncrease,
    }
  );

  return (
    <div id={id} className={classnames("number-stepper", className)}>
      <div className="number-stepper__label">{label}</div>
      <div
        role="button"
        className={decreaseClasses}
        onClick={!disableDecrease ? decrease : undefined}
      >
        -
      </div>
      <div className="number-stepper__number">{value}</div>
      <div
        role="button"
        className={increaseClasses}
        onClick={!disableIncrease ? increase : undefined}
      >
        +
      </div>
    </div>
  );
}
