import classNames from "classnames";
import { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { cutString } from "shared/lib/stringFormat";
import { formattedMessageParams } from "shared/messages";

const { pleaseType } = defineMessages({
  pleaseType: formattedMessageParams("General.pleaseType"),
});

const ReusableSelect = ({ className, onChange, optionsArray }) => {
  const intl = useIntl();
  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [inputValue, setValue] = useState("");
  const [selectedOptionIdx, setSelectedOptionIdx] = useState(0);

  useEffect(() => {
    setOptions(
      optionsArray.filter(({ label }) =>
        label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
      )
    );

    if (optionsArray.length > 100 && !inputValue) {
      setOptions([]);
    }
  }, [inputValue, optionsArray]);

  return (
    <div className={classNames("travelersSelect", className)}>
      <div className="travelersSelect__input-wrap">
        <input
          autoFocus
          className="travelersSelect__input"
          type="text"
          placeholder={intl.formatMessage(pleaseType)}
          value={inputValue}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={({ key }) => {
            switch (key) {
              case "Enter":
                if (options[selectedOptionIdx]) {
                  onChange(options[selectedOptionIdx]);
                  if (inputValue) {
                    setOptions([]);
                  }
                  setValue("");
                }
                break;
              case "ArrowUp":
                setSelectedOptionIdx((idx) => (idx - 1) % options.length);
                break;
              case "ArrowDown":
                setSelectedOptionIdx((idx) => (idx + 1) % options.length);
                break;
              case "Escape":
                onChange({ choosen: inputValue, open: false });
                break;
              default:
            }
          }}
        />
      </div>

      <ul id="cbt-reusable-select" className="travelersSelect__options">
        {options.map(({ label, value }, idx) => (
          <li key={value}>
            <button
              type="button"
              className={classNames("travelersSelect__option", {
                "travelersSelect__option--hover": selectedOptionIdx === idx,
              })}
              data-label={label}
              onClick={() => onChange({ label, value })}
              onMouseEnter={() => setSelectedOptionIdx(idx)}
            >
              {cutString(label, 16)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReusableSelect;
