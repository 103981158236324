import React from "react";
import { FormattedMessage as IntlFormattedMessage } from "react-intl";

import { FormattedMessageId, formattedMessageParams } from "shared/messages";

interface Props {
  id: FormattedMessageId;
  values?: any;
}

const FormattedMessageComponent: React.FC<Props> = React.memo(
  ({ id, values }) => {
    if (values === undefined) {
      return <IntlFormattedMessage {...formattedMessageParams(id)} />;
    }
    return (
      <IntlFormattedMessage values={values} {...formattedMessageParams(id)} />
    );
  }
);

export default FormattedMessageComponent;
