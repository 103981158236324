import classNames from "classnames";
import React from "react";

import SimpleSelect, { FormControl } from "@components/UI/SimpleSelect";

interface Props {
  required?: boolean;
  defaultMessage?: string;
  input: any;
  options: any;
  id?: string;
  readOnly?: boolean;
  placeholder?: string;
  meta: { touched?: boolean; error?: any };
}

function CustomSelectInput({
  required,
  defaultMessage,
  input,
  options,
  id,
  readOnly,
  placeholder,
  meta: { touched, error },
}: Props) {
  const defaultValue = input.value !== "" ? input.value : null;

  return (
    <div
      className="custom-input-container"
      style={{
        marginBottom: touched && error ? "2px" : "17px",
      }}
      id={id}
    >
      <div
        className={classNames("custom-input-wrapper", {
          "input-wrapper-required": required,
          "input-wrapper-error": touched && error,
        })}
      >
        <span className="input-label">{defaultMessage}</span>

        <SimpleSelect
          testId={`custom-select-${id}`}
          styles={{
            container: (base) => ({
              ...base,
              flexGrow: 1,
            }),
            valueContainer: (base) => ({
              ...base,
            }),

            placeholder: (base) => ({
              ...base,
            }),
          }}
          value={defaultValue}
          options={options}
          placeholder={placeholder}
          onChange={(value) => input.onChange(value)}
          isSearchable={!readOnly}
          controlOptions={{ value: defaultValue, error: touched && error }}
          components={{
            Control: FormControl,
          }}
          {...input}
          onBlur={(event) => event.preventDefault()}
        />
      </div>
      {touched && error ? (
        <span className="custom-input-wrapper-error">{error}</span>
      ) : null}
    </div>
  );
}

export default CustomSelectInput;
