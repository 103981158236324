export const getPassengersFromQuery = (query, allowedTravelerTypes) => {
  const passengers = {};

  allowedTravelerTypes.forEach((allowedTravelerType) => {
    if (!Number(query[allowedTravelerType.Code])) {
      return;
    }
    passengers[allowedTravelerType.Code] = query[allowedTravelerType.Code]
      ? Number(query[allowedTravelerType.Code])
      : 0;
  });

  return passengers;
};
