import { getCookie } from "shared/data/cookiesOps";
import { SearchQuery } from "shared/data/reducers/types";

type PageType =
  | "results"
  | "home"
  | "bestOffers"
  | "bestOfferResults"
  | "purchase"
  | "customerInfo"
  | "services"
  | "other";

type EventType =
  | "page"
  | "search"
  | "purchase"
  | "view_promotion"
  | "begin_checkout"
  | "add_payment_info";

export const pageDataLayer = (
  pageType: PageType,
  user: { username?: string; email?: string }
) => {
  const payload = {
    page_type: pageType,
    user_id: user?.username || "",
    user_email: user?.email || "",
  };
  pushToDataLayer("page", payload);
};

export const searchDataLayer = (searchQuery: SearchQuery) => {
  const payload = {
    search: {
      term: `${searchQuery?.from || ""}_${searchQuery?.to || ""}`,
      tolerance: Number(searchQuery?.toleranceDays || "0"),
      airlines: `${searchQuery?.preferred_airline || ""}`,
      type: `${searchQuery?.max_transfers === "direct" ? "direct" : "all"}`,
    },
  };
  pushToDataLayer("search", payload);
};

export const purchaseDataLayer = (bookingData, intl) => {
  const flightTypes = {
    ONE_WAY: {
      name: intl.formatMessage({ id: "Constant.oneWay" }),
      id: intl.formatMessage({ id: "Constant.oneWayCode" }),
    },
    RETURN: {
      name: intl.formatMessage({ id: "Constant.return" }),
      id: intl.formatMessage({ id: "Constant.returnCode" }),
    },
    MULTICITY: {
      name: intl.formatMessage({ id: "Constant.multicity" }),
      id: intl.formatMessage({ id: "Constant.multicityCode" }),
    },
    TWO_ONE_WAY: {
      name: intl.formatMessage({ id: "Constant.twoOneWay" }),
      id: intl.formatMessage({ id: "Constant.twoOneWayCode" }),
    },
  };

  const flightType = () => {
    if (bookingData?.reservationsIds?.length === 1) {
      if (
        bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
          ?.length === 1
      ) {
        return flightTypes.ONE_WAY;
      }

      if (
        bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
          ?.length === 2
      ) {
        return flightTypes.RETURN;
      }

      if (
        bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
          ?.length > 2
      ) {
        return flightTypes.MULTICITY;
      }
    }

    if (bookingData?.reservationsIds?.length === 2) {
      return flightTypes.TWO_ONE_WAY;
    }
  };

  const discount =
    Math.abs(
      bookingData?.reservationsDetails
        .flatMap((reservation) =>
          reservation.ServicePrices?.ServicePrice?.filter(
            (service) => service.Service === "discount"
          )
        )
        .reduce((total, service) => total + parseFloat(service.Price), 0) || 0
    ) || "";

  const flights = bookingData?.reservationsDetails.flatMap((reservation) =>
    reservation.FlightItinerary?.FlightStream.flatMap((flightStream) =>
      flightStream.FlightOption?.flatMap((flightOption) =>
        flightOption.FlightSegments?.FlightSegment?.map(
          (flightSegment, index) => ({
            item_id:
              flightSegment?.MarketingAirline + flightSegment?.FlightNumber,
            item_name: `${flightSegment?.OriginAirport}_${flightSegment?.DestinationAirport}`,
            coupon: getCookie("promoCode") ?? "",
            discount,
            currency: bookingData?.currency,
            index,
            item_list_id: flightType()?.id,
            item_list_name: flightType()?.name,
            item_brand: flightSegment?.MarketingAirline,
            price: bookingData?.fullPrices[index],
            quantity: 1,
          })
        )
      )
    )
  );

  const items = [];
  let servicesTotal = 0;

  bookingData?.reservationsDetails?.forEach((reservation) => {
    reservation.FlightPassengerPrices.FlightPassengerPrice.forEach(
      (passengerPrice) => {
        const code = passengerPrice.Code;
        const existingItem = items.find(
          (item) =>
            item.item_id === code &&
            item.price === parseFloat(passengerPrice.FullPricePerPassenger)
        );

        if (existingItem) {
          existingItem.quantity += 1;
        } else {
          items.push({
            item_id: code,
            item_name: `${code} ${intl.formatMessage({
              id: "Reservation.gtm.ticket",
            })}`,
            currency: bookingData.currency,
            price: parseFloat(passengerPrice.FullPricePerPassenger),
            quantity: 1,
          });
        }
      }
    );

    reservation.ServicePrices.ServicePrice.forEach((service) => {
      if (service.Service !== "discount") {
        servicesTotal += parseFloat(service.Price);
      }
    });
  });

  if (servicesTotal > 0) {
    items.push({
      item_id: "SERVICES",
      item_name: "Services",
      currency: bookingData.currency,
      price: servicesTotal,
      quantity: 1,
    });
  }

  const itemsIndexed = items.map((item, index) => ({
    ...item,
    index,
  }));

  const segments = flights.map((flight, index) => ({
    segment_id: flight.item_id,
    segment_name: flight.item_name,
    segment_list_id: flight.item_list_id,
    segment_list_name: flight.item_list_name,
    segment_brand: flight.item_brand,
    index,
  }));

  const totalValue =
    itemsIndexed
      .filter((item) => item.item_id !== "SERVICES")
      .reduce((acc, item) => acc + item.price * item.quantity, 0) -
    (discount === "" ? 0 : discount) +
    servicesTotal;

  const payload = {
    event: "purchase",
    gtm: { uniqueEventId: 9, start: Date.now() },
    page_type: "purchase",
    user_id: bookingData.reservationsEmail,
    user_email: bookingData.reservationsEmail,
    ecommerce: {
      transaction_id: bookingData?.reservationsIds[0],
      value: totalValue,
      currency: bookingData?.currency,
      discount,
      shipping: 0,
      tax: 0,
      coupon: getCookie("promoCode") ?? "",
      items: itemsIndexed.map(
        // eslint-disable-next-line camelcase
        ({ item_list_id, item_list_name, item_brand, ...rest }) => rest
      ),
      segments,
    },
  };

  pushToDataLayer("purchase", payload);
};

export const viewPromotionDataLayer = () => {
  pushToDataLayer("view_promotion");
};

export const beginCheckoutDataLayer = () => {
  pushToDataLayer("begin_checkout");
};

export const addPaymentInfo = () => {
  pushToDataLayer("add_payment_info");
};

const pushToDataLayer = (event: EventType, payload?: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...payload,
  });
};
