import {
  isPossiblePhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";

export function getInputFieldValue(inputFieldRef) {
  if (!inputFieldRef || !inputFieldRef.value) return "";
  return inputFieldRef.value;
}

export const isValidConfirmationCode = (value: string): boolean => {
  if (!/^\d+$/.test(value)) return false;

  if (value.length !== 6) return false;

  return true;
};

export const isEmail = (value) =>
  // eslint-disable-next-line security/detect-unsafe-regex -- no harm regex
  !!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value.toLowerCase()
  );

export const isTelephone = (value) => {
  const phoneNumber = parsePhoneNumberFromString(value);

  const isPossiblePhoneNumberCheck = isPossiblePhoneNumber(
    value,
    phoneNumber?.country
  );

  if (phoneNumber?.country === "CI" && value.startsWith("+225")) {
    if (phoneNumber?.nationalNumber?.length === 10) {
      return true;
    }
    return false;
  }

  if (phoneNumber?.country === "SK") {
    if (
      phoneNumber?.nationalNumber?.length !== 9 ||
      !isPossiblePhoneNumberCheck
    ) {
      return false;
    }
  }

  if (value.startsWith("+61")) {
    if (
      phoneNumber?.nationalNumber?.length !== 9 &&
      isPossiblePhoneNumberCheck
    ) {
      return false;
    }
  }

  if (phoneNumber) {
    return isPossiblePhoneNumberCheck;
  }

  return false;
};
