import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import golApiCall from "./golApiCall";

const getGolRequestSsoLink = (providerId) => ({
  GolApi: {
    RequestDetail: {
      SsoLinkRequest_1: {
        IdentityProvider: {
          Id: providerId,
        },
      },
    },
  },
});

export default async (providerId: string): Promise<ResponseObject> => {
  if (!providerId) {
    throw new Error("providerId is required");
  }

  const golRequest = getGolRequestSsoLink(providerId);
  const response = await golApiCall(golRequest);
  return createResponse(response);
};
