import { Component } from "react";

import { loadWebRequestorDetails } from "shared/data/actions/storage";
import { logoutUser } from "shared/data/actions/user";
import { logoutCustomer } from "shared/data/customerTokens";
import { getGolApiUrl } from "shared/lib/golCopy";

type Props = {
  dispatch?: (call: any) => void;
  router?: any;
  children?: any;
};
type State = {
  showMobileMenu: boolean;
  showMobileChangeLanguage: boolean;
  showMobileChangeCurrency: boolean;
  isLogin: boolean;
  showModal: boolean;
  isUserMenuOpen: boolean;
};

class HeaderLogoStripeContainer extends Component<Props, State> {
  state = {
    showMobileMenu: false,
    showMobileChangeLanguage: false,
    showMobileChangeCurrency: false,
    isUserMenuOpen: false,
    isLogin: true,
    showModal: false,
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      showMobileMenu: !prevState.showMobileMenu,
    }));
  };

  logout = async () => {
    await logoutCustomer();
    this.props.dispatch(logoutUser());

    window.location.href = `/${
      this.props.router.query.lang
        ? `?lang=${this.props.router.query.lang}`
        : ""
    }`;
    await this.props.dispatch(
      loadWebRequestorDetails({
        selectedLanguage: this.props.router.query.lang,
        golApiUrl: getGolApiUrl(),
        requestorPublicKey: null,
      })
    );
  };

  toggleMobileChangeLanguage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState((prevState) => ({
      showMobileChangeLanguage: !prevState.showMobileChangeLanguage,
    }));
  };

  toggleMobileChangeCurrency = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState((prevState) => ({
      showMobileChangeCurrency: !prevState.showMobileChangeCurrency,
    }));
  };

  toggleUserMenu = () =>
    this.setState({ isUserMenuOpen: !this.state.isUserMenuOpen });

  render() {
    return this.props.children({
      state: this.state,
      toggleMobileMenu: this.toggleMobileMenu,
      logout: this.logout,
      toggleMobileChangeLanguage: this.toggleMobileChangeLanguage,
      toggleMobileChangeCurrency: this.toggleMobileChangeCurrency,
      toggleUserMenu: this.toggleUserMenu,
    });
  }
}

export default HeaderLogoStripeContainer;
