import axios from "axios";

import {
  COOKIE_CBT_TOKEN_NAME,
  COOKIE_CUSTOMER_NAME_NAME,
  COOKIE_CUSTOMER_TOKEN_NAME,
  COOKIE_REQUESTOR_PUBLIC_KEY,
} from "../data/cookies";
import { deleteCookie, getCookie } from "../data/cookiesOps";

axios.interceptors.request.use(
  (request) => {
    if (!process.browser) {
      return request;
    }

    const requestorCookie = getCookie(COOKIE_REQUESTOR_PUBLIC_KEY);
    const requestorStorage = sessionStorage.getItem(
      COOKIE_REQUESTOR_PUBLIC_KEY
    );

    if (!requestorCookie && !requestorStorage) {
      return request;
    }

    if (requestorCookie && !requestorStorage) {
      sessionStorage.setItem(COOKIE_REQUESTOR_PUBLIC_KEY, requestorCookie);
      return;
    }

    if (
      requestorCookie &&
      requestorStorage &&
      requestorCookie === requestorStorage
    ) {
      return request;
    }

    deleteCookie(COOKIE_CUSTOMER_TOKEN_NAME);
    deleteCookie(COOKIE_CBT_TOKEN_NAME);
    deleteCookie(COOKIE_CUSTOMER_NAME_NAME);
    deleteCookie(COOKIE_REQUESTOR_PUBLIC_KEY);
    sessionStorage.removeItem(COOKIE_REQUESTOR_PUBLIC_KEY);

    window.location.href = "/";

    alert(
      "You have been logged out in another window. To proceed please log in again."
    );
  },
  (error) => Promise.reject(error)
);

export default axios;
