const Spinner = () => (
  <div>
    <div className="spinner_overlay" />
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Spinner;
