import * as actionTypes from "shared/data/actions/actionTypes";
import { updateObject } from "shared/lib/functions";

export const MAX_PASSENGERS = 9;

export interface CbtPassengersForm {
  travellers: string[];
  guests: number;
  flightClass: string;
  costCenter?: string;
  guestsGradeIds: string[];
  travelReason?: string;
  errorMaxGuests: boolean;
}

interface CbtReducer {
  users: any[];
  usersCache: any[];
  guestsCache: any[];
  guestsUsedInDynamicForm: any; // selected predefined guests from cbt api in dynamic form
  guestsToUpdateInDynamicForm: any; // guests with checked update button, send patch request to cbt api before sending reservation
  guestsToCreateInDynamicForm: any; // guests with checked create button, send post request to cbt api to create them before sending reservation
  guestsInDynamicFormShowingSearchForm: any; // guests with checked create button, send post request to cbt api to create them before sending reservation
  costCenters: any[];
  grades: any[];
  travelReasons: any[];
  passengersForm: CbtPassengersForm;
  policyResolvedId?: string;
  managers: any[];
  corporate: any;
}

const initialState: CbtReducer = {
  users: [],
  usersCache: [],
  guestsCache: [],
  guestsUsedInDynamicForm: null,
  guestsToUpdateInDynamicForm: null,
  guestsToCreateInDynamicForm: null,
  guestsInDynamicFormShowingSearchForm: null,
  costCenters: [],
  travelReasons: [],
  corporate: null,
  policyResolvedId: null,
  grades: [],
  passengersForm: {
    travellers: [],
    guests: 0,
    guestsGradeIds: [],
    flightClass: "ECO",
    costCenter: null,
    travelReason: null,
    errorMaxGuests: false,
    wasClosed: false,
  },
  managers: [],
};

function canAddPassenger(state) {
  return (
    state.passengersForm.guests + state.passengersForm.travellers.length + 1 <
    MAX_PASSENGERS
  );
}

function clearErrors(state) {
  return updateObject(["passengersForm", "errorMaxGuests"], () => false, state);
}

const setCbtValue = (state, action) => {
  return {
    ...state,
    [action.data.name]: action.data.value,
  };
};

const setPolicyResolvedId = (state, action) => {
  return {
    ...state,
    [action.data.name]: action.data.value,
  };
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CBT_USERS_FETCHED: {
      const users = (action.responseData.data || []).reduce((acc, user) => {
        acc[user.Id] = {
          ...user,
          value: user.Id,
          label: `${user.FirstName} ${user.LastName}`,
        };
        return acc;
      }, {});

      return {
        ...state,
        usersCache: { ...state.usersCache, ...users },
        users,
      };
    }

    case actionTypes.CBT_MANAGERS_FETCHED: {
      const managers = (action.responseData.data || []).reduce(
        (acc, manager) => {
          acc[manager.Id] = {
            ...manager,
            value: manager.Id,
            label: `${manager.FirstName} ${manager.LastName}`,
          };
          return acc;
        },
        {}
      );
      return {
        ...state,
        managers,
      };
    }

    case actionTypes.CBT_GRADES_FETCHED: {
      return {
        ...state,
        grades: action.responseData || [],
      };
    }

    case actionTypes.CBT_RESET_GUESTS_AND_TRAVELLERS: {
      return {
        ...state,
        passengersForm: {
          ...state.passengersForm,
          guests: 0,
          travellers: [],
        },
      };
    }

    case actionTypes.CBT_COST_CENTERS_FETCHED: {
      return {
        ...state,
        costCenters: action.responseData
          .map(({ Id, Name }) => ({
            value: Id,
            label: Name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      };
    }

    case actionTypes.CBT_TRAVEL_REASONS_FETCHED: {
      const travelReasons = action.responseData
        .map(({ Id, Name }) => ({
          value: Id,
          label: Name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      return {
        ...state,
        travelReasons,
        passengersForm: {
          ...state.passengersForm,
          travelReason:
            travelReasons.length === 1
              ? travelReasons[0].value
              : state.passengersForm.travelReason,
        },
      };
    }

    case actionTypes.CBT_GUESTS_FETCHED: {
      return {
        ...state,
        guestsCache: action.responseData.data,
      };
    }

    case actionTypes.SET_CBT_VALUE:
      return setCbtValue(state, action);

    case actionTypes.CBT_CORPORATE_FETCHED:
      return {
        ...state,
        corporate: action.corporate,
      };

    case actionTypes.CBT_INIT_FROM_URL: {
      const { policyResolvedId, ...passengersForm } =
        action.passengersForm || initialState.passengersForm;

      return updateObject(
        ["passengersForm"],
        (form) => ({
          ...form,
          ...(passengersForm || initialState),
        }),
        policyResolvedId ? { ...state, policyResolvedId } : state
      );
    }

    case actionTypes.CBT_INCREMENT_GUESTS: {
      return canAddPassenger(state)
        ? updateObject(["passengersForm", "guests"], (guests) => guests + 1, {
            ...state,
            passengersForm: {
              ...state.passengersForm,
              guestsGradeIds: action.id
                ? [...state.passengersForm.guestsGradeIds, action.id]
                : state.passengersForm.guestsGradeIds,
            },
          })
        : updateObject(["passengersForm", "errorMaxGuests"], () => true, state);
    }
    case actionTypes.CBT_DECREMENT_GUESTS: {
      return state.passengersForm.guests > 0
        ? updateObject(["passengersForm", "guests"], (guests) => guests - 1, {
            ...clearErrors(state),
            passengersForm: {
              ...state.passengersForm,
              guestsGradeIds: action.id
                ? state.passengersForm.guestsGradeIds.filter(
                    (id, index, self) => {
                      const firstIndex = self.indexOf(action.id);
                      return id !== action.id || index !== firstIndex;
                    }
                  )
                : state.passengersForm.guestsGradeIds,
            },
          })
        : state;
    }
    case actionTypes.CBT_CHANGE_FLIGHT_CLASS:
      return updateObject(
        ["passengersForm", "flightClass"],
        () => action.flightClass,
        clearErrors(state)
      );
    case actionTypes.CBT_CHANGE_COST_CENTER:
      return updateObject(
        ["passengersForm", "costCenter"],
        () => action.costCenter,
        clearErrors(state)
      );
    case actionTypes.SET_CBT_POLICY_RESOLVED_ID:
      return setPolicyResolvedId(state, action);
    case actionTypes.CBT_CHANGE_TRAVEL_REASON:
      return updateObject(
        ["passengersForm", "travelReason"],
        () => action.travelReason,
        clearErrors(state)
      );
    case actionTypes.CBT_ADD_TRAVELLER:
      return canAddPassenger(state)
        ? updateObject(
            ["passengersForm", "travellers"],
            (travellers) => [...travellers, action.traveller],
            state
          )
        : updateObject(["passengersForm", "errorMaxGuests"], () => true, state);
    case actionTypes.CBT_REMOVE_TRAVELLER:
      return updateObject(
        ["passengersForm", "travellers"],
        (travellers) => travellers.filter((t) => t !== action.traveller),
        clearErrors(state)
      );
    case actionTypes.CBT_CLOSE_PASSENGERS_FORM:
      return updateObject(["passengersForm", "wasClosed"], () => true, state);
    case actionTypes.LOGOUT_USER: {
      return initialState;
    }
    case actionTypes.CBT_PASSENGER_FORM_CLEAR_ERRORS:
      return clearErrors(state);
    default:
      return state;
  }
}
