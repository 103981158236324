export const RESERVATION_STATUSES = {
  ACTIVE: "ACTIVE",
  ACTIVE_PAID: "ACTIVE_PAID",
  ACTIVE_UNPAID: "ACTIVE_UNPAID",
  ACTIVE_UNAPPROVED: "ACTIVE_UNAPPROVED",
  PROCESSING: "PROCESSING",
  PROCESSING_LONG: "PROCESSING_LONG",
  ISSUED: "ISSUED",
  PAID: "PAID",
  UNSUCCESSFUL: "UNSUCCESSFUL",
  NEED_PAYMENT: "NEED_PAYMENT",
  TOISSUE: "TOISSUE",
  NEED_CONFIRMATION: "NEED_CONFIRMATION",
  CANCELED: "CANCELED",
  TOREFUND: "TOREFUND",
};
