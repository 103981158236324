import getCbtClient, { cbtResponse } from "../cbtClient";

export function listCostCenters({ cbtToken, customerUsername, cbtApiUrl }) {
  if (!cbtToken) return;

  return cbtResponse(
    getCbtClient({
      cbtToken,
      customerUsername,
      cbtApiUrl,
    }).get(`/cost-centers`)
  );
}
