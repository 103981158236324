import getCbtClient, { cbtResponse } from "../cbtClient";

export const maxTravellersCount = 100;

export async function listTravellers({
  cbtToken,
  customerUsername,
  cbtApiUrl,
  query = "",
  limit = maxTravellersCount,
}) {
  if (!cbtToken) return;
  let params = query ? `query=${encodeURIComponent(query)}&` : "";
  params += limit ? `limit=${limit}` : "";

  return cbtResponse(
    travellersMiddleware(
      getCbtClient({ cbtToken, customerUsername, cbtApiUrl }).get(
        `/travellers?${params}`
      ),
      limit
    )
  );
}

async function travellersMiddleware(travellersPromise, limit) {
  const respTransformer = (travellersResp) => {
    if (travellersResp?.data?.data?.length >= limit) {
      return { ...travellersResp, data: { data: [] } };
    }

    return travellersResp;
  };
  return travellersPromise.then(respTransformer);
}
