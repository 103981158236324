import React, { Dispatch, SetStateAction } from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect, useSelector } from "react-redux";
import {
  Field,
  getFormNames,
  getFormSyncErrors,
  getFormValues,
  isValid,
  reduxForm,
} from "redux-form";

import { AppState } from "shared/data/reducers";
import { salutationTranslation } from "shared/lib/constants";
import { safeFormatMessage } from "shared/lib/functions";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import PhoneInput from "@components/Forms/Inputs/CustomPhoneInput";
import FormattedMessage from "@components/UI/FormattedMessage";

import CustomInputField from "../Forms/Inputs/CustomInputField";
import CustomSelectInput from "../Forms/Inputs/CustomSelectInput";
import {
  checkRequired,
  isEmail,
  isPhoneNumber,
  matchingPassword,
  passwordLength,
  requiredSelect,
} from "../Forms/Rules";

const {
  username,
  usernamePlaceholder,
  password,
  passwordPlaceholder,
  passwordAgain,
  passwordAgainPlaceholder,
  firstname,
  firstnamePlaceholder,
  surname,
  surnamePlaceholder,
  telephone,
  telephonePlaceholder,
  email,
  emailPlaceholder,
  salutation,
  company,
  ic,
  dic,
  street,
  city,
  postalCode,
  country,
} = defineMessages({
  username: formattedMessageParams("Form.username"),
  usernamePlaceholder: formattedMessageParams(
    "RegisterForm.usernamePlaceholder"
  ),
  password: formattedMessageParams("Form.password"),
  passwordPlaceholder: formattedMessageParams(
    "RegisterForm.passwordPlaceholder"
  ),
  passwordAgain: formattedMessageParams("Form.repeatPassword"),
  passwordAgainPlaceholder: formattedMessageParams(
    "RegisterForm.passwordAgainPlaceholder"
  ),
  firstname: formattedMessageParams("Form.firstName"),
  firstnamePlaceholder: formattedMessageParams(
    "RegisterForm.firstnamePlaceholder"
  ),
  surname: formattedMessageParams("Form.surname"),
  surnamePlaceholder: formattedMessageParams("RegisterForm.surnamePlaceholder"),
  telephone: formattedMessageParams("Form.telephone"),
  telephonePlaceholder: formattedMessageParams(
    "RegisterForm.telephonePlaceholder"
  ),
  email: formattedMessageParams("Form.email"),

  emailPlaceholder: formattedMessageParams("RegisterForm.emailPlaceholder"),
  salutation: formattedMessageParams("RegisterForm.salutation"),
  company: formattedMessageParams("Form.company"),
  ic: formattedMessageParams("Form.billingBusinessId"),
  dic: formattedMessageParams("Form.billingTaxId"),
  street: formattedMessageParams("Form.street"),
  city: formattedMessageParams("Form.city"),
  postalCode: formattedMessageParams("Form.billingPostalCode"),
  country: formattedMessageParams("Form.country"),
});

interface Props {
  intl: IntlShape;
  billingForm: boolean;
  setBillingForm: Dispatch<SetStateAction<boolean>>;
  passengerPrefixes: any[];
}

const Form: React.FC<Props> = ({
  intl,
  passengerPrefixes,
  billingForm,
  setBillingForm,
}: Props) => {
  const form = useSelector(
    (store: AppState) =>
      getFormNames()(store).map((formName) => ({
        formName,
        isValid: isValid(formName)(store),
        values: getFormValues(formName)(store),
        errors: getFormSyncErrors(formName)(store),
      }))[0].values
  );

  const countriesArr = [];
  const { countries } = useSelector(({ storage }: AppState) => {
    return storage;
  });
  Object.entries(countries).forEach(([value, label]) => {
    countriesArr.push({ value, label });
  });

  const billingData = {
    company: form?.company || null,
    ic: form?.ic || null,
    dic: form?.dic || null,
    postalCode: form?.postalCode || null,
    city: form?.city || null,
    street: form?.street || null,
  };

  const isEmpty = Object.values(billingData).every((x) => x === null);
  const isAllFilled = Object.values(billingData).every((x) => x !== null);

  return (
    <form id="register-form" method="post">
      <div>
        <input
          type="hidden"
          name="SecurityContent"
          defaultValue={generateRandomString()}
        />

        <Field
          required
          messageId="result.username"
          defaultMessage={intl.formatMessage(username)}
          placeholder={intl.formatMessage(usernamePlaceholder)}
          name="username"
          id="registerForm-username"
          type="text"
          component={CustomInputField}
          validate={checkRequired}
          autoFocus
          noMargin
        />
        <Field
          required
          messageId="result.password"
          defaultMessage={intl.formatMessage(password)}
          placeholder={intl.formatMessage(passwordPlaceholder)}
          name="password"
          id="registerForm-password"
          type="password"
          component={CustomInputField}
          validate={[checkRequired, passwordLength]}
          noMargin
        />
        <Field
          required
          messageId="result.password"
          defaultMessage={intl.formatMessage(passwordAgain)}
          placeholder={intl.formatMessage(passwordAgainPlaceholder)}
          name="passwordCheck"
          id="registerForm-passwordCheck"
          type="password"
          component={CustomInputField}
          validate={[checkRequired, matchingPassword]}
          noMargin
        />
        <br />
        <h3 className="universal-h3">
          <FormattedMessage id="RegisterForm.requiredInformation" />
        </h3>
        <Field
          required
          messageId="Form.firstName"
          defaultMessage={intl.formatMessage(firstname)}
          placeholder={intl.formatMessage(firstnamePlaceholder)}
          name="firstName"
          id="registerForm-firstName"
          type="text"
          component={CustomInputField}
          validate={checkRequired}
          noMargin
        />
        <Field
          required
          messageId="Form.surname"
          defaultMessage={intl.formatMessage(surname)}
          placeholder={intl.formatMessage(surnamePlaceholder)}
          name="surname"
          id="registerForm-surname"
          type="text"
          component={CustomInputField}
          validate={checkRequired}
          noMargin
        />

        <Field
          required
          messageId="Form.telephone"
          defaultMessage={intl.formatMessage(telephone)}
          placeholder={intl.formatMessage(telephonePlaceholder)}
          name="telephone"
          id="registerForm-telephone"
          type="text"
          component={PhoneInput}
          validate={[checkRequired, isPhoneNumber]}
          noMargin
        />

        <Field
          required
          messageId="result.email"
          defaultMessage={intl.formatMessage(email)}
          placeholder={intl.formatMessage(emailPlaceholder)}
          name="email"
          id="registerForm-email"
          type="email"
          component={CustomInputField}
          validate={[checkRequired, isEmail]}
          noMargin
        />

        <Field
          required
          readOnly
          name="title"
          id="registerForm-title"
          parentElementId="registerForm-title-wrapper"
          component={CustomSelectInput}
          defaultMessage={intl.formatMessage(salutation)}
          placeholder={intl.formatMessage(salutation)}
          options={passengerPrefixes.map((e) => ({
            value: e.value,
            label: safeFormatMessage({
              intl,
              id: salutationTranslation[e.value],
              fallback: e.value,
            }),
          }))}
          validate={requiredSelect}
          noMargin
        />
        <br />

        <div role="button" onClick={() => setBillingForm(!billingForm)}>
          <h3
            className={`h3 ${billingForm ? "pointer noselect" : ""}`}
            id={billingForm ? "form-box-collapsible-heading" : ""}
            style={
              billingForm
                ? { justifyContent: "normal", display: "inline-block" }
                : { display: "inline-block" }
            }
          >
            <FormattedMessage id="ResultForm.billingInformation" />
          </h3>

          <Img
            id="registration-billing-form-information-dropdown"
            src="/static/images/ico-arrow-down.svg"
            style={{
              marginLeft: "15px",
              transition: "all .4s ease",
              transform: billingForm ? "rotate(180deg)" : null,
              marginRight: "24px",
            }}
          />
        </div>
        <div style={billingForm ? {} : { display: "none" }}>
          <Field
            required
            validate={isEmpty || isAllFilled ? null : checkRequired}
            messageId="Form.company"
            name="company"
            defaultMessage={intl.formatMessage(company)}
            placeholder={intl.formatMessage(company)}
            id="registerForm-company"
            type="text"
            component={CustomInputField}
            noMargin
          />
          <Field
            messageId="Form.billingBusinessId"
            name="ic"
            defaultMessage={intl.formatMessage(ic)}
            placeholder={intl.formatMessage(ic)}
            id="registerForm-ic"
            type="text"
            component={CustomInputField}
            noMargin
          />
          <Field
            messageId="Form.billingTaxId"
            name="dic"
            defaultMessage={intl.formatMessage(dic)}
            placeholder={intl.formatMessage(dic)}
            id="registerForm-dic"
            type="text"
            component={CustomInputField}
            noMargin
          />
          <Field
            required
            validate={isEmpty || isAllFilled ? null : checkRequired}
            messageId="Form.street"
            name="street"
            defaultMessage={intl.formatMessage(street)}
            placeholder={intl.formatMessage(street)}
            id="registerForm-street"
            type="text"
            component={CustomInputField}
            noMargin
          />
          <Field
            required
            validate={isEmpty || isAllFilled ? null : checkRequired}
            messageId="Form.city"
            name="city"
            defaultMessage={intl.formatMessage(city)}
            placeholder={intl.formatMessage(city)}
            id="registerForm-city"
            type="text"
            component={CustomInputField}
            noMargin
          />
          <Field
            required
            validate={isEmpty || isAllFilled ? null : checkRequired}
            messageId="Form.billingPostalCode"
            name="postalCode"
            defaultMessage={intl.formatMessage(postalCode)}
            placeholder={intl.formatMessage(postalCode)}
            id="registerForm-postalCode"
            type="text"
            component={CustomInputField}
            noMargin
          />
          <Field
            required
            readOnly
            name="country"
            id="registerForm-country"
            component={CustomSelectInput}
            defaultMessage={intl.formatMessage(country)}
            placeholder={intl.formatMessage(country)}
            options={countriesArr}
            noMargin
          />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  passengerPrefixes: state.storage.passengerPrefix,
  fileServerData: state.requestorConfig.fileServerData,
  textStorage: state.requestorConfig.textStorage,
});

export function generateRandomString() {
  const result = [];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  const length = Math.random() * 15 + 10;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

export default reduxForm({
  form: "registerForm",
})(connect(mapStateToProps)(Form));
