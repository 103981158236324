import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

function getGolRequestCancelReservationQuery(reservationId: string) {
  return {
    GolApi: {
      RequestDetail: {
        CancelReservationsRequest_1: {
          CancelReservations: {
            CancelReservation: [
              {
                Reservation: {
                  ReservationId: reservationId,
                },
              },
            ],
          },
        },
      },
    },
  };
}

export default async (reservationId: string) => {
  const golRequest = getGolRequestCancelReservationQuery(reservationId);
  const cancelReservationResponse = await golApiCall(golRequest);
  return createResponse(cancelReservationResponse);
};
