import * as actionTypes from "./actionTypes";

export const setBookingFormParams = (sections, fields) => {
  return {
    type: actionTypes.SET_BOOKING_FORM_PARAMS,
    data: {
      sections,
      fields,
    },
  };
};

export const setBookingFormFromAcquisitionParams = ({
  acquisitionParamsBookingForm,
  sections,
  fields,
  codeBook,
  ticketingCombinations,
  cbtGuestsInDynamicFormShowingSearchForm,
  paymentFormMethods,
  paymentFormMethodForms,
  alternativeCurrencyPaymentFormMethods,
}) => {
  return {
    type: actionTypes.SET_BOOKING_FORM_FROM_ACQUISITION_PARAMS,
    data: {
      acquisitionParamsBookingForm,
      sections,
      fields,
      codeBook,
      ticketingCombinations,
      cbtGuestsInDynamicFormShowingSearchForm,
      paymentFormMethods,
      paymentFormMethodForms,
      alternativeCurrencyPaymentFormMethods,
    },
  };
};

export const setBookingFormMarketplaceFromAcquisitionParams = ({
  acquisitionParamsBookingForm,
  getActualMarketplaceServices,
}) => {
  return {
    type: actionTypes.SET_BOOKING_FORM_MARKETPLACE_FROM_ACQUISITION_PARAMS,
    data: {
      acquisitionParamsBookingForm,
      getActualMarketplaceServices,
    },
  };
};

export const setBookingFormValue = (name, value) => {
  return {
    type: actionTypes.SET_BOOKING_FORM_VALUE,
    data: { name, value },
  };
};

export const setBookingFormFieldValue = (name, value) => {
  return {
    type: actionTypes.SET_BOOKING_FORM_FIELD_VALUE,
    data: { name, value },
  };
};

export const setIsFetchingServices = (value) => {
  return {
    type: actionTypes.SET_BOOKING_FETCHING_SERVICES,
    data: { value },
  };
};

export const setBookingFormFieldValueOnly = (name, value) => {
  return {
    type: actionTypes.SET_BOOKING_FORM_FIELD_VALUE_ONLY,
    data: { name, value },
  };
};

export const setBookingFormElementRefs = (value) => {
  return {
    type: actionTypes.SET_BOOKING_FORM_ELEMENTS_REFS,
    value,
  };
};

export const removeFormValue = (name) => {
  return {
    type: actionTypes.REMOVE_FORM_VALUE,
    data: { name },
  };
};

export const flipState = (name) => ({
  type: actionTypes.FORM_FLIP_FORMBOX_STATE,
  data: { name },
});

export const initFormState = () => ({
  type: actionTypes.INIT_FORM_STATE,
});
