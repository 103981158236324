import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";
import golApi from "shared/gol-api";
import { capitalize } from "shared/lib/functions";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import pageWithIntl from "@components/PageWithIntl";
import FormattedMessage from "@components/UI/FormattedMessage";
import useModalAlert from "@components/UI/useModalAlert";

const { loginSsoError } = defineMessages({
  loginSsoError: formattedMessageParams("Login.ssoError"),
});

const SsoButton: FC<{
  provider: { Id: string; Type: string };
}> = ({ provider }) => {
  const intl = useIntl();
  const { ssoIdentityProvider } = useSelector((state: AppState) => ({
    ssoIdentityProvider: state.requestorConfig.ssoIdentityProvider,
  }));
  const [ModalAlert, showError] = useModalAlert({});

  const providerTypeMapping = {
    MS_AZURE: { name: "microsoft", hasImage: true },
    UNIGLOBE: { name: "uniglobe", hasImage: false },
  };
  const providerSimpleName = providerTypeMapping[provider.Type].name;
  const showImageForProvider = providerTypeMapping[provider.Type].hasImage;
  const isSsoEnabled = ssoIdentityProvider && ssoIdentityProvider.length > 0;
  const onClick = async () => {
    if (!isSsoEnabled) {
      return;
    }
    const response = await golApi.ssoLink(provider.Id);
    if (!response?.success) {
      showError(intl.formatMessage(loginSsoError));
      return;
    }
    window.location.href = response.data.SsoLink.$t;
  };

  return (
    <>
      <div className="input-wrapper">
        <span className="input-label" style={{ width: 160 }} />
        <button
          type="submit"
          id="sso-button"
          className="sso-button"
          onClick={onClick}
        >
          {showImageForProvider && (
            <Img
              src={`/static/images/ico-${providerSimpleName}.svg`}
              alt={`${providerSimpleName}`}
              style={{ verticalAlign: "middle" }}
            />
          )}
          &nbsp;&nbsp;
          <span>
            <FormattedMessage id="General.loginWith" />
            {` ${capitalize(providerSimpleName)}`}
          </span>
        </button>
      </div>
      <ModalAlert />
    </>
  );
};

export default pageWithIntl(SsoButton);
