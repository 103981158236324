import { getPassiveSessionId } from "../data/customerTokens";
import { getApiHeader } from "../gol-api/golApiCall";
import { IS_MOBILE } from "../services/persistentStorageService";
import {
  genderFromNamePrefix,
  getRequestor,
  getSettings,
  isEmptyObject,
} from "./functions";

const sharedConfig = require("../config/sharedConfig");

export const priceActualizationRequest = async ({
  refs,
  promoCode,
  alternativeCurrency,
}) => {
  return {
    GolApi: {
      ...(await getApiHeader(
        alternativeCurrency ? { alternativeCurrency } : {}
      )),
      RequestDetail: {
        PriceActualizationRequest_2: {
          HistoricalReferences: {
            Reference: refs.map((oRef) => ({ $t: oRef })),
          },
          ...(promoCode ? { PromoCode: { $t: promoCode } } : {}),
        },
      },
    },
  };
};

export const getAirportsRequestObject = async () => {
  const request = {
    GolApi: {
      ...(await getApiHeader()),
      RequestDetail: {
        ExportCityRequest_1: {
          CityType: {
            Type: "flight",
          },
        },
      },
    },
  };

  return request;
};

export async function getDetailRequestorRequestObject(options?: {
  forcedCustomerToken?: string;
  forcedRequestorPublicKey?: string;
  forcedClientId?: string;
}) {
  const request = {
    GolApi: {
      ...(await getApiHeader(options)),
      RequestDetail: {
        DetailRequestorRequest_1: {},
      },
    },
  };

  return request;
}

export const getFareConditionsRequestObject = async () => ({
  GolApi: {
    ...(await getApiHeader()),
    RequestDetail: {
      SearchTextRequest_1: {
        SearchText: {
          Type: "termsandconditions",
        },
      },
    },
  },
});

export const getCustomTextRequestObject = async (textName) => ({
  GolApi: {
    ...(await getApiHeader()),
    RequestDetail: {
      SearchTextRequest_1: {
        SearchText: {
          Type: textName,
        },
      },
    },
  },
});

export const getLoyaltyProgramsRequestObject = async () => ({
  GolApi: {
    ...(await getApiHeader()),
    RequestDetail: {
      ExportLoyaltyProgramRequest_1: {},
    },
  },
});

export const getCreateCustomerRequestObject = async ({
  Username,
  Password,
  Gender,
  NamePrefix,
  PhoneNumber,
  Email,
  GivenName,
  Surname,
  Newsletter,
  SpecialOffers,
  company,
  ic,
  dic,
  street,
  city,
  postalCode,
  country,
}) => {
  let billingInfoRegistration = {};
  if (company) {
    billingInfoRegistration = {
      BillingInfo: {
        Company: {
          Ic: ic,
          Dic: dic,
          $t: company,
        },
        BillingName: company ? { $t: company } : {},
        Street: street ? { $t: street } : {},
        CityName: {
          Country: country,
          Zipcode: postalCode,
          $t: city,
        },
      },
    };
  }

  return {
    GolApi: {
      ...(await getApiHeader()),
      RequestDetail: {
        CreateCustomerRequest_1: {
          CreateCustomer: {
            Username,
            Password,
            Person: {
              Gender,
              BirthDate: "",
              NamePrefix,
              GivenName: {
                $t: GivenName,
              },
              Surname: {
                $t: Surname,
              },
              Telephone: {
                PhoneNumber,
              },
              Email: {
                $t: Email,
              },
            },
            ...billingInfoRegistration,
          },
          CustomerSettings: {
            Newsletter: {
              Active: (Newsletter || "false").toString(),
            },
            SpecialOffers: {
              Active: (SpecialOffers || "false").toString(),
            },
          },
        },
      },
    },
  };
};

export const getTransportCompaniesRequestObject = async ({
  customerToken,
  requestorPublicKey,
  clientId,
}) => {
  const request = {
    GolApi: {
      ...(await getApiHeader({
        forcedCustomerToken: customerToken,
        forcedRequestorPublicKey: requestorPublicKey,
        forcedClientId: clientId,
      })),
      RequestDetail: {
        ExportTransportCompanyRequest_1: {},
      },
    },
  };

  return request;
};

export const getCreateCustomerTokenRequestObject = async ({
  Username,
  Password,
  Namespace,
  Token,
  DeviceId,
  DeviceName,
  IdentityProviderId,
  IdentityProviderData,
}) => ({
  GolApi: {
    ...(await getLoginApiHeader({ Username, Password, Namespace, Token })),
    RequestDetail: {
      CreateCustomerTokenRequest_1: {
        Device: {
          Id: DeviceId,
          $t: DeviceName,
        },
        ...(IdentityProviderId && IdentityProviderData
          ? {
              SsoLogin: {
                IdentityProvider: { Id: IdentityProviderId },
                IdentityProviderData: { $t: IdentityProviderData },
              },
            }
          : {}),
      },
    },
  },
});

export const getCustomerDetailRequestObject = async (user) => {
  return {
    GolApi: {
      ...(await getApiHeader({ forcedCustomerToken: user })),
      RequestDetail: {
        DetailCustomerRequest_1: {},
      },
    },
  };
};

export const getCancelCustomerRequestObject = async () => {
  return {
    GolApi: {
      ...(await getApiHeader()),
      RequestDetail: {
        CancelCustomerRequest_1: {},
      },
    },
  };
};

export const getForgottenPasswordRequestObject = async ({ Username }) => {
  const CONFIG_URL = IS_MOBILE
    ? sharedConfig.forgottenPasswordUrl
    : window.location.origin;

  return {
    GolApi: {
      ...(await getApiHeader()),
      RequestDetail: {
        IssueCustomerAuthorizationRequest_1: {
          IssueCustomer: {
            Username,
          },
          InitialUrl: {
            $t: `${CONFIG_URL}/create-new-password?token={loginToken}`,
          },
        },
      },
    },
  };
};

export const getUpdateCustomerRequestObject = async ({
  person,
  passport,
  loyaltyPrograms,
}) => {
  const namePrefixValue = person.namePrefix?.value;
  const golRequest = {
    GolApi: {
      ...(await getApiHeader()),
      RequestDetail: {
        ModifyCustomerRequest_1: {
          ModifyCustomer: {
            Person: {
              Gender: genderFromNamePrefix(namePrefixValue),
              BirthDate: person.birthDate || "",
              NamePrefix: namePrefixValue || person.namePrefix,
              GivenName: {
                $t: person.givenName,
              },
              Surname: {
                $t: person.surname,
              },
              Telephone: {
                PhoneNumber: person.telephone,
              },
              Email: {
                $t: person.email,
              },
            },
          },
          CustomerSettings: {
            Newsletter: {
              Active: (person.newsletter || "false").toString(),
            },
            SpecialOffers: {
              Active: (person.specialOffers || "false").toString(),
            },
          },
        },
      },
    },
  };
  /* Loyalties object must be before Passport, because If both are included Passport needs to be on first position */
  if (loyaltyPrograms && loyaltyPrograms.length !== 0) {
    golRequest.GolApi.RequestDetail.ModifyCustomerRequest_1.CustomerSettings = {
      Loyalties: {
        LoyaltyProgram:
          loyaltyPrograms.length === 1
            ? {
                Id: loyaltyPrograms[0].Id,
                MembershipId: loyaltyPrograms[0].MembershipId,
              }
            : loyaltyPrograms.map((program) => ({
                Id: program.Id,
                MembershipId: program.MembershipId,
              })),
      },
      ...golRequest.GolApi.RequestDetail.ModifyCustomerRequest_1
        .CustomerSettings,
    };
  }

  if (!isEmptyObject(passport)) {
    golRequest.GolApi.RequestDetail.ModifyCustomerRequest_1.CustomerSettings = {
      Passport: {
        ValidityDate: passport.ValidityDate,
        Number: passport.passportNumber,
        Citizenship: passport.Citizenship,
        Issued: passport.Issued,
      },
      ...golRequest.GolApi.RequestDetail.ModifyCustomerRequest_1
        .CustomerSettings,
    };
  }
  return golRequest;
};

export const getCustomerUpdatePasswordRequestObject = async (payload) => {
  const { password } = payload;
  const token = payload.credentials.Token;

  return {
    GolApi: {
      ...(await getApiHeader({ forcedCustomerToken: token })),
      RequestDetail: {
        ModifyCustomerRequest_1: {
          ModifyPassword: {
            Password: password,
          },
        },
      },
    },
  };
};

export const reservationRequestObject = async (
  reservationData,
  clientToken
) => {
  const travellers = reservationData.passengers.map((traveller) => {
    const newTraveller = {
      PassengerType: traveller.passengerType || "ADT",
      Gender: genderFromNamePrefix(traveller.title),
      NamePrefix: traveller.title,
      GivenName: { $t: traveller.firstName },
      Surname: { $t: traveller.surname },
    };

    if (
      traveller["year-birthDay"] &&
      traveller["month-birthDay"] &&
      traveller["day-birthDay"]
    ) {
      // web
      newTraveller.BirthDate = {
        Date: `${traveller["year-birthDay"]}-${traveller["month-birthDay"]}-${traveller["day-birthDay"]}`,
      };
    } else if (traveller.birthDate) {
      // mobile new
      newTraveller.BirthDate = {
        Date: traveller.birthDate,
      };
    }

    // web passports
    if (
      traveller.passportNumber &&
      traveller.citizenship &&
      traveller["year-expirationDate"] &&
      traveller["month-expirationDate"] &&
      traveller["day-expirationDate"]
    ) {
      newTraveller.Passport = {
        Number: traveller.passportNumber,
        ValidityDate: `${traveller["year-expirationDate"]}-${traveller["month-expirationDate"]}-${traveller["day-expirationDate"]}`, // this.valid_document.value,
        Citizenship: traveller.citizenship,
        Issued: "CZ",
      };
    } else if (
      traveller.passportNumber &&
      traveller.citizenship &&
      traveller.validUntil
    ) {
      // mobile passports
      newTraveller.Passport = {
        Number: traveller.passportNumber,
        ValidityDate: traveller.validUntil, // this.valid_document.value,
        Citizenship: traveller.citizenship,
        Issued: traveller.issuedCountry || "CZ",
      };
    }

    if (
      traveller.loyaltyProgram !== undefined &&
      traveller.loyaltyProgram !== 0
    ) {
      newTraveller.LoyaltyProgram = {
        Id: traveller.loyaltyProgram,
        MembershipId: traveller.loyaltyProgramClientNumber,
      };
    }
    return newTraveller;
  });
  const customer = {
    ContactInfo: {
      ContactName: {
        $t: reservationData.contact.contactName,
      },
      Telephone: {
        PhoneNumber: reservationData.contact.phoneNumber,
      },
      Email: { $t: reservationData.contact.email },
    },
  };

  let billingInfo = {};
  if (reservationData.billingActive) {
    billingInfo = {
      BillingInfo: {
        Company: {
          Ic: reservationData.billing.ic,
          Dic: reservationData.billing.dic,
        },
        BillingName: reservationData.billing.company
          ? { $t: reservationData.billing.company }
          : {},
        Street: reservationData.billing.street
          ? { $t: reservationData.billing.street }
          : {},
        CityName: {
          Country: reservationData.billing.country,
          Zipcode: reservationData.billing.zip,
        },
      },
    };
  }
  let shippingInfo = {};
  if (reservationData.shipping !== undefined) {
    shippingInfo = {
      Company: {
        IC: reservationData.billing.ic,
        DIC: reservationData.billing.dic,
      },
      ShippingName: {
        $t: reservationData.shipping.shippingName,
      },
      Street: { $t: reservationData.shipping.street },
      CityName: {
        Country: reservationData.shipping.country,
        Zipcode: reservationData.shipping.zip,
        $t: reservationData.shipping.city,
      },
    };
  }

  const ticketPlaceId = reservationData.ticketingCombinations.find(
    (el) =>
      el.PaymentformOption.Id === reservationData.paymentTypeId &&
      el.TicketplaceOption.Id
  );

  const golRequest = {
    GolApi: {
      ...(await getApiHeader({ forcedCustomerToken: clientToken })),
      RequestDetail: {
        BookReservationsRequest_3: {
          TicketingCombination: {
            TicketplaceOption: {
              Id: ticketPlaceId.TicketplaceOption.Id,
              Rate: "0",
              RatedFor: "abs",
              Price: "0",
            },
            PaymentformOption: {
              Id: `${reservationData.paymentTypeId}`,
              Rate: "0",
              RatedFor: "abs",
              Price: "0",
            },
          },
          BookReservations: {
            BookReservation: {
              ExternalReservationId: "123456",
              PricedReference: {
                Price: reservationData.price,
                Reference: reservationData.refs,
              },
              Travelers: {
                Traveler: travellers,
              },
              Customer: {
                ...customer,
                ...billingInfo,
                ...shippingInfo,
              },
              Comment: { $t: reservationData.comment },
            },
          },
        },
      },
    },
  };

  return golRequest;
};

export const getCountriesRequestObject = async ({
  includeStates = true,
  customerToken,
  requestorPublicKey,
  clientId,
}) => {
  const request = {
    GolApi: {
      ...(await getApiHeader({
        forcedCustomerToken: customerToken,
        forcedRequestorPublicKey: requestorPublicKey,
        forcedClientId: clientId,
      })),
      RequestDetail: {
        ExportCountryRequest_1: [{}],
      },
    },
  };

  if (includeStates) {
    request.GolApi.RequestDetail.ExportCountryRequest_1 = [
      {
        IncludeStates: {},
      },
    ];
  }

  return request;
};

export const acqusitionParamsRequestObject = async ({
  refs,
  fullPrice = 0,
  defaultCountry,
  promoCode = false,
  alternativeCurrency,
}) => {
  const promoCodeObj = promoCode
    ? {
        PromoCode: { $t: promoCode },
      }
    : {};

  return {
    GolApi: {
      ...(await getApiHeader({
        defaultCountry,
        ...(alternativeCurrency ? { alternativeCurrency } : {}),
      })),
      RequestDetail: {
        AcquisitionParamsRequest_3: {
          PricedReferences: {
            PricedReference: {
              Price: fullPrice,
              Reference: getRefsForFlightsAndHotels(refs),
            },
          },
          ...promoCodeObj,
        },
      },
    },
  };
};

function getRefsForFlightsAndHotels(refs) {
  // flights
  if (!Array.isArray(refs) || !refs[0].key) {
    if (refs.map === undefined) {
      return [
        {
          $t: refs,
        },
      ];
    }
    return refs.map((oRef) => ({
      $t: oRef,
    }));
  }

  // hotels
  const arrayRefs = [];
  refs.forEach((oneRef) => {
    Array.from({ length: oneRef.number }).forEach(() => {
      arrayRefs.push(oneRef.key);
    });
  });
  return arrayRefs.map((oRef) => ({
    $t: oRef,
  }));
}

export const getBaggageConditionsObject = async (refs, alternativeCurrency) => {
  return {
    GolApi: {
      ...(await getApiHeader(
        alternativeCurrency ? { alternativeCurrency } : {}
      )),
      RequestDetail: {
        BaggageDetailsRequest_1: {
          SearchedReferences: {
            Reference: refs.map((oRef) => ({ $t: oRef })),
          },
        },
      },
    },
  };
};

export const getGetParamConditionsObject = async (refs) => {
  return {
    GolApi: {
      ...(await getApiHeader()),
      RequestDetail: {
        ParamConditionsRequest_1: {
          SearchedReferences: {
            Reference: refs.map((oRef) => ({ $t: oRef })),
          },
        },
      },
    },
  };
};

export const getSearchDestinationRequestObject = async (pattern: string) => {
  return {
    GolApi: {
      ...(await getApiHeader()),
      Settings: await getSettings(),
      RequestDetail: {
        SearchDestinationsRequest_1: {
          SearchPattern: { $t: pattern },
        },
      },
    },
  };
};

async function getLoginApiHeader(user: any) {
  let Authorization: any = {
    Requestor: await getRequestor(),
  };
  const { Username, Password, Namespace } = user;

  Authorization = {
    ...Authorization,
    ...(Username && Password
      ? { User: { Username, Password, Namespace } }
      : {}),
  };

  return {
    PassiveSessionId: await getPassiveSessionId(),
    Authorization: {
      ...Authorization,
    },
    Settings: await getSettings(),
  };
}
