import golApiCall from "./golApiCall";

function getGolRequestGenerationOfVariableSymbolQuery(
  builPriceReservationParams,
  PaymentformOption,
  TicketplaceOption
) {
  const golRequest = {
    GolApi: {
      RequestDetail: {
        PaymentVariableSymbolRequest_1: {
          PaidReservations: {
            PriceReservation: builPriceReservationParams,
          },
          TicketingCombination: {
            TicketplaceOption,
            PaymentformOption,
          },
        },
      },
    },
  };
  return golRequest;
}

export default async (
  builPriceReservationParams,
  PaymentformOption,
  TicketplaceOption
) => {
  const golRequest = getGolRequestGenerationOfVariableSymbolQuery(
    builPriceReservationParams,
    PaymentformOption,
    TicketplaceOption
  );
  const paymentParamsResponse = await golApiCall(golRequest);
  return paymentParamsResponse;
};
